import { notification } from "antd"
import { QueryClient } from "react-query"
import i18n from "../../.i18n"
import * as Sentry from "@sentry/react"
import { GraphQLError } from "graphql"

// WORKAROUND; React query doesn't handle graphql errors well. see: https://github.com/TanStack/query/discussions/3365
export const parseGraphqlErrorToReactQuery = (
    error: Error
): GraphQLError | null => {
    try {
        const parsedError = JSON.parse(
            error.message
                .split("\n")
                .filter((_, idx) => idx > 0)
                .join("")
        )

        return parsedError as GraphQLError // Assuming the parsedError has the correct structure
    } catch {
        return null
    }
}

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            refetchOnWindowFocus: false,
        },
        mutations: {
            onError: (error) => {
                const glError = parseGraphqlErrorToReactQuery(error as Error)

                if (glError) {
                    if (
                        glError.extensions?.code === "BAD_REQUEST" ||
                        glError.extensions?.code === "NOT_ALLOWED"
                    ) {
                        // show error message
                        notification.error({
                            message: "Error!",
                            description: glError.message,
                        })
                    } else if (glError.extensions?.code === "NOT_AUTHORIZED") {
                        // show a static message for not authorized errors
                        notification.error({
                            message: i18n.t("errors.403.message", {
                                ns: "translation",
                            }),
                            description: i18n.t("errors.403.description", {
                                ns: "translation",
                            }),
                        })
                    } else {
                        // show a static message for unhandled errors
                        notification.error({
                            message: i18n.t("errors.500.message", {
                                ns: "translation",
                            }),
                            description: i18n.t("errors.500.description", {
                                ns: "translation",
                            }),
                        })
                        Sentry.captureException(error)
                    }
                }
            },
        },
    },
})
