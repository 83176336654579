import React, { useContext, useEffect, useRef, useState } from "react"
import { Checkbox, Form, InputNumber } from "antd"
import { OriginalDesignEditableContext } from "../VaesEditableTable"
import classNames from "classnames"
import OriginalDesignValueColumn from "./OriginalDesignValueColumn"
import { useUpdateOriginalData } from "../../../api/report"
import { queryClient } from "../../../api/client/reactQuery"
import { useParams } from "react-router-dom"
import { AnyObject } from "antd/es/_util/type"

interface Item {
    key: string
    id: number
    priority: { priority: number }
    project: { id: number }
    projectPart: { id: number }
    subject: { subject: { id: number; name: string } }
    tags: { id?: number; name: string }
    unit: { unit: { key: string; value: string } }
    value: number
    value2: number | null
}

type EditableCellProps = {
    editing: boolean
    dataIndex: keyof Item
    title: string
    record: Item
    index: number
    children: React.ReactNode
    editable: boolean
    onChange?: (record: Item) => void
}

const OriginalDesignEditableCell: React.FC<EditableCellProps> = (props) => {
    const { projectId } = useParams<{ projectId: string }>()
    const [editing, setEditing] = useState(false)
    const [isRange, setIsRange] = useState(
        props.record && props.record.value2 != null ? true : false
    )
    const update = useUpdateOriginalData()
    const inputValueRef = useRef<HTMLInputElement>(null)
    const inputValue2Ref = useRef<HTMLInputElement>(null)
    const form = useContext(OriginalDesignEditableContext)!

    useEffect(() => {
        if (editing) {
            inputValueRef.current!.focus()
        }
    }, [editing])

    /** to apply the edit mode only on files with status new*/
    const toggleEdit = () => {
        setEditing(!editing)
    }

    const handleEnter = () => {
        handleChange()
    }

    const handleChange = async () => {
        const value = await form.getFieldValue("value")
        const value2 = isRange ? await form.getFieldValue("value2") : null

        if (
            value &&
            (props.record.value != value || props.record.value2 != value2)
        ) {
            props.record.value = value
            props.record.value2 = value2
            update.mutate(
                {
                    id: props.record.id,
                    value: props.record.value,
                    value2: props.record.value2,
                },
                {
                    onSuccess: () => {
                        // refetch all subjects
                        queryClient.invalidateQueries([
                            "report-page",
                            props.record.projectPart,
                            "original-data",
                        ])
                        queryClient.invalidateQueries([
                            "project_subjectList",
                            Number(projectId),
                        ])
                        toggleEdit()
                    },
                }
            )
        } else {
            toggleEdit()
        }
    }

    let childNode = props.children

    if (props.editable) {
        childNode = editing ? (
            <div className="w-full h-full flex items-center !justify-start ml-1">
                <Form.Item
                    name="value"
                    style={{ margin: 0 }}
                    className=""
                    initialValue={props.record.value}
                >
                    <InputNumber
                        ref={inputValueRef}
                        type={"number"}
                        className={classNames({
                            " w-28": isRange,
                            " w-58": !isRange,
                        })}
                        min={0}
                        controls={false}
                        onPressEnter={handleEnter}
                    />
                </Form.Item>
                <Form.Item
                    name="value2"
                    style={{ margin: 0 }}
                    className=""
                    initialValue={props.record.value2}
                >
                    <InputNumber
                        ref={inputValue2Ref}
                        type={"number"}
                        className={classNames({
                            " w-28 ml-1": isRange,
                            " opacity-0 w-0 ml-0": !isRange,
                        })}
                        min={0}
                        controls={false}
                        onPressEnter={handleEnter}
                    />
                </Form.Item>
                <Form.Item
                    name="range"
                    style={{ margin: 0 }}
                    initialValue={isRange}
                >
                    <Checkbox
                        checked={isRange}
                        onChange={() => setIsRange(!isRange)}
                        className="!p-0 m-0 ml-2"
                    >
                        Make range
                    </Checkbox>
                </Form.Item>
            </div>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={toggleEdit}
            >
                <OriginalDesignValueColumn
                    value={props.record.value}
                    value2={props.record.value2}
                />
            </div>
        )
    }

    return <td {...(props as AnyObject)}>{childNode}</td>
}

export default OriginalDesignEditableCell
