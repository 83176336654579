import { glClient } from "./client/graphqlClient"
import { useQuery, useMutation } from "react-query"
import {
    IterationInput,
    ProjectPartInput,
} from "@vaes-dashboard-2/graphql/genql"
import { queryClient } from "./client/reactQuery"
import { ID } from "@vaes-dashboard-2/graphql/types"

export const getReportPageQueryDetails = (
    pageId: number,
    latestVersion: boolean
) => ({
    queryKey: ["report-page", pageId, latestVersion],
    queryFn: () => {
        return glClient.query({
            getReportPage: [
                { id: pageId, latestVersion },
                {
                    id: true,
                    parentId: true,
                    title: true,
                    isPublished: true,
                    createdAt: true,
                    createdBy: { name: true },
                    updatedAt: true,
                    updatedBy: { name: true },
                    projectPartId: true,
                    iterations: {
                        id: true,
                        name: true,
                        imageUrl: {
                            s3key: true,
                            url: true,
                        },
                        gifImgUrl: {
                            s3key: true,
                            url: true,
                        },
                        originalDesignImageUrl: {
                            s3key: true,
                            url: true,
                        },
                        advantages: true,
                        description: true,
                        details: {
                            subject: {
                                name: true,
                                id: true,
                                typeKey: { key: true, value: true },
                            },
                            value: true,
                            value2: true, //secondary column added for range cases to hold the second value
                        },
                        order: true,
                    },
                },
            ],
        })
    },
})

export function loadReportPage(pageId: number, latestVersion = true) {
    if (!Number.isInteger(pageId)) {
        return null
    }

    const query = getReportPageQueryDetails(pageId, latestVersion)
    type QueryType = Awaited<ReturnType<typeof query.queryFn>>

    return (
        queryClient.getQueryData<QueryType>(query.queryKey) ??
        queryClient.fetchQuery(query)
    )
}

export function useReportPage(pageId?: number, versionId?: number) {
    const latestVersion = !versionId // if we don't have a specific version id, we fetch the latest version
    const enabled = !!pageId || !!versionId // disable fetching if no version id or page id received
    const exactPageToFetch = Number(versionId) || pageId || -1 // if we have a version id, we fetch the page by version id, otherwise we fetch by page id

    const details = getReportPageQueryDetails(exactPageToFetch, latestVersion)

    return useQuery(details.queryKey, details.queryFn, {
        staleTime: Infinity,
        enabled,
    })
}
/**
 * Get partId of a report page
 * @NOTE this hook uses the same query as useReportPage, so mostly it will be cached
 * @TODO we should optimize this by ensuring the data is cached, otherwise, only fetch partId
 */
export function useGetProjectPartByPageId(pageId: number) {
    const details = getReportPageQueryDetails(pageId, true)

    return useQuery(details.queryKey, details.queryFn, {
        staleTime: Infinity,
        select: (data) => data.getReportPage?.projectPartId,
    })
}

/**
 * @Description This hook is used to check if a report page is published or not
 * @Note this hook use the same query as useReportPage, so mostly it will be cached
 */
export function useIsReportPagePublished(pageId: number, versionId?: number) {
    const latestVersion = !versionId
    const enabled = !!pageId || !!versionId

    const details = getReportPageQueryDetails(
        versionId || pageId,
        latestVersion
    )

    return useQuery(details.queryKey, details.queryFn, {
        staleTime: Infinity,
        enabled,
        select: (data) => !!data.getReportPage?.isPublished,
    })
}

export function useCreateReportPageVersion(pageId: number) {
    // get the latest version of the page
    const { data } = useReportPage(pageId)
    const page = data?.getReportPage

    const createReport = useCreateReportPage()

    return {
        isLoading: createReport.isLoading,
        mutateAsync: () =>
            createReport.mutateAsync({
                parentId: page?.parentId || page?.id,
                originalParentId: page?.id,
                partId: page?.projectPartId as number,
                title: page?.title as string,
                iterations: page?.iterations?.map((i) => ({
                    name: i.name,
                    imgKey: i.imageUrl?.s3key,
                    gifImgKey: i.gifImgUrl?.s3key,
                    originalDesignImgKey: i.originalDesignImageUrl?.s3key,
                    advantages: i.advantages,
                    description: i.description,
                    details: i.details?.map((d) => ({
                        subjectId: d.subject?.id,
                        value: d.value,
                        value2: d.value2,
                    })),
                })),
            }),
    }
}

export function useReportPageVersions(pageId: number, enabled = true) {
    return useQuery(
        ["report-page", "versions", "list", pageId],
        () => {
            return glClient.query({
                getReportPage: [
                    { id: pageId },
                    {
                        versions: {
                            id: true,
                            title: true,
                            isPublished: true,
                            createdAt: true,
                        },
                    },
                ],
            })
        },
        {
            staleTime: Infinity,
            enabled,
        }
    )
}
/**
 * Fetch iteration by pageId and iteration id
 */
export const useIteration = (
    pageId: number,
    iterationId: number,
    enabled = true
) => {
    const details = getReportPageQueryDetails(pageId, true)

    return useQuery(details.queryKey, details.queryFn, {
        staleTime: Infinity,
        select: (data) => {
            return data?.getReportPage?.iterations?.find(
                (i) => i.id === iterationId
            )
        },
        enabled,
    })
}

/**
 * List all pages in a report
 */
export const useReportPages = (partId: number, enabled = true) => {
    return useQuery(
        ["part", partId, "page", "all"],
        () =>
            glClient.query({
                getProjectPart: [
                    { id: partId },
                    {
                        pages: {
                            id: true,
                            title: true,
                            description: true,
                            parentId: true,
                            iterations: { id: true, name: true },
                        },
                    },
                ],
            }),
        { enabled }
    )
}

const allProjectReportPagesQueryDetails = (projectId: ID) => ({
    queryKey: ["project", projectId, "report", "page", "all"],
    queryFn: () => {
        return glClient.query({
            getProject: [
                { projectId: Number(projectId) },
                {
                    parts: {
                        name: true,
                        id: true,
                        pages: {
                            id: true,
                            title: true,
                            thumbnail: true,
                            isPublished: true,
                        },
                    },
                },
            ],
        })
    },
})

export const loadAllProjectsReportPages = async (projectId: number) => {
    const query = allProjectReportPagesQueryDetails(projectId)
    type QueryType = Awaited<ReturnType<typeof query.queryFn>>

    return (
        queryClient.getQueryData<QueryType>(query.queryKey) ??
        (await queryClient.fetchQuery(query))
    )
}

/**
 * For the MVP, we will list all pages related to a project.
 */
export const useAllProjectReportPages = (projectId: ID) => {
    const query = allProjectReportPagesQueryDetails(projectId)
    return useQuery(query.queryKey, query.queryFn, {
        select: (data) => data.getProject,
    })
}

export function useCreateReportPage() {
    return useMutation({
        mutationFn: (payload: {
            partId: number
            title: string
            description?: string
            isPublished?: boolean
            iterations?: IterationInput[]
            parentId?: number
            originalParentId?: number
        }) => {
            return glClient.mutation({
                addPage: [payload, { id: true }],
            })
        },
    })
}

/**
 * @Description This query could update page info as well as all iterations in a bulk way (supports add, update, delete)
 */
export function useReportPageBulkUpdate() {
    return useMutation({
        mutationFn: (payload: {
            id: number
            title: string
            iterations: IterationInput[]
        }) => {
            return glClient.mutation({
                updatePageBulk: [payload],
            })
        },
    })
}

export const useListAllProjectParts = (projectId: number) => {
    return useQuery(
        ["project", projectId, "parts", "list", "all"],
        () =>
            glClient.query({
                listAllProjectParts: [
                    { projectId },
                    {
                        name: true,
                        id: true,
                        isArchived: true,
                    },
                ],
            }),
        {
            select: (data) => data.listAllProjectParts,
        }
    )
}

/**
 * Fetch meta info only for a report page
 */
export const useReportPageInfo = (pageId: number, enabled = true) => {
    return useQuery(
        ["reportPageInfo", pageId],
        () => {
            return glClient.query({
                getReportPage: [
                    { id: pageId },
                    {
                        id: true,
                        title: true,
                        description: true,
                        isPublished: true,
                    },
                ],
            })
        },
        { enabled }
    )
}

/**
 * fetch all reports subjects for admin use
 */
export const useAllSubjects = (tags?: number[]) => {
    return useQuery(
        // set filters in the query key, if no filters, use "all"
        ["report", "subjects", tags?.length ? tags : "all"],
        () =>
            glClient.query({
                listAllSubjects: [
                    {
                        tags: tags?.length ? tags : undefined,
                    },
                    {
                        id: true,
                        name: true,
                        typeKey: {
                            value: true,
                            key: true,
                        },
                        tags: {
                            id: true,
                            name: true,
                        },
                        priority: true,
                    },
                ],
            }),
        {
            select(data) {
                return data.listAllSubjects?.map((s) => ({
                    name: s.name,
                    id: s.id,
                    type: s.typeKey?.value,
                    typekey: s.typeKey?.key,
                    tags: s.tags,
                    priority: s.priority,
                }))
            },
        }
    )
}

/**
 * update subject mutation hook
 */
export const useUpdateSubject = () => {
    return useMutation({
        mutationFn: (payload: {
            id: number
            name: string
            typeKey: string
            tags?: number[]
            priority: number
        }) => {
            return glClient.mutation({
                updateSubject: [payload, { id: true }],
            })
        },
    })
}

/**
 * update subject mutation hook
 */
export const useSaveProjectUnderstandingForm = () => {
    return useMutation({
        mutationFn: (payload: {
            projectId: number
            parts: ProjectPartInput[]
            breakdownImgKey?: string
        }) => {
            return glClient.mutation({
                saveProjectUnderstandingForm: [
                    {
                        parts: payload.parts,
                        breakdownImgKey: payload.breakdownImgKey,
                        projectId: payload.projectId,
                    },
                ],
            })
        },
    })
}

/**
 * create subject mutation hook
 */
export const useCreateSubject = () => {
    const mutationFn = (payload: {
        name: string
        typeKey: string
        tags?: number[]
        priority: number
    }) => {
        return glClient.mutation({
            createSubject: [payload, { id: true }],
        })
    }
    return useMutation<
        Awaited<ReturnType<typeof mutationFn>>,
        Error,
        Parameters<typeof mutationFn>[0]
    >({
        mutationFn,
    })
}

/**
 * Get project report, we assume that the project will have only one report
 */
export const useProjectPart = (projectId: number, enabled = true) => {
    return useQuery(
        ["project", projectId, "part", "default"],
        () =>
            glClient.query({
                getProject: [
                    { projectId: projectId },
                    {
                        parts: {
                            id: true,
                            name: true,
                            isArchived: true,
                            detailedReportStatus: {
                                key: true,
                                value: true,
                            },
                        },
                    },
                ],
            }),
        { enabled: enabled, select: (data) => data.getProject?.parts?.[0] }
    )
}

export const usePublishReportPageImmediately = () => {
    return useMutation({
        mutationFn: (payload: { id: number }) => {
            return glClient.mutation({
                publishReportPageImmediately: [
                    payload,
                    { id: true, isPublished: true },
                ],
            })
        },
    })
}

export const showPercentage = (
    original: [number | undefined, number] | string | undefined,
    ve: [number | undefined, number] | string | undefined
) => {
    if (
        original != "Not Applicable" &&
        ve != "Not Applicable" &&
        original?.[1] == 0 &&
        ve?.[1] == 0
    ) {
        return true
    } else {
        return false
    }
}

/**to get the original design data from the original desin table */
export const useProjectPartOriginalData = (
    projectPartId?: number,
    tags?: number[] | null
) => {
    return useQuery(
        [
            "report-page",
            projectPartId,
            "original-data",
            tags?.length ? tags : "all",
        ],
        () => {
            if (!projectPartId) throw new Error("projectPartId is missing")

            return glClient.query({
                listProjectPartOriginalData: [
                    { projectPartId, tags: tags?.length ? tags : undefined },
                    {
                        id: true,
                        project: { id: true },
                        projectPart: { id: true },
                        subject: {
                            priority: true,
                            unit: { key: true, value: true, type: true },
                            subject: { id: true, name: true },
                        },
                        tags: { id: true, name: true },
                        value: true,
                        value2: true,
                    },
                ],
            })
        },
        {
            select: (data) =>
                // Just get the subject id and name to the top level
                data.listProjectPartOriginalData?.map((s) => ({
                    ...s,
                    subject: {
                        priority: s.subject.priority,
                        unit: {
                            key: s.subject.unit.key,
                            value: s.subject.unit.value,
                            type: s.subject.unit.type,
                        },
                        id: s.subject.subject.id,
                        name: s.subject.subject.name,
                    },
                })),
            enabled: !!projectPartId,
        }
    )
}

/** add list of subjects on a project */
export function useAddOriginalData() {
    return useMutation({
        mutationFn: (payload: {
            projectPartId: number
            projectId: number
            subjectId: number
            value: number
            value2: number | null
        }) => {
            return glClient.mutation({
                addProjectOriginalData: [
                    payload,
                    {
                        project: { id: true },
                    },
                ],
            })
        },
    })
}

/**update the project original data value  */
export function useUpdateOriginalData() {
    return useMutation({
        mutationFn: (payload: {
            id: number
            value: number
            value2: number | null
        }) => {
            return glClient.mutation({
                updateProjectOriginalData: [
                    payload,
                    {
                        project: { id: true },
                    },
                ],
            })
        },
    })
}

/** remove a project original data value */
export function useremoveOriginalData() {
    return useMutation({
        mutationFn: (payload: { id: number }) => {
            return glClient.mutation({
                deleteProjectOriginalData: [
                    payload,
                    {
                        id: true,
                    },
                ],
            })
        },
    })
}

/**to get the original design data from the published table */
export const useProjectPartPublishedOriginalData = (
    publishType: string,
    projectPartId?: number,
    reportPageId?: number,
    projectId?: number
) => {
    return useQuery(
        ["report-page", projectPartId, reportPageId, "published_original-data"],
        () => {
            if (!projectPartId || !reportPageId || !projectId)
                throw new Error("One of the ids is missing")

            return glClient.query({
                listProjectPartsPublish: [
                    { projectPartId, reportPageId, projectId, publishType },
                    {
                        id: true,
                        subjectId: true,
                        value: true,
                        value2: true,
                    },
                ],
            })
        },

        {
            select: (data) =>
                data.listProjectPartsPublish.map((s) => ({
                    ...s,
                    subject: { id: s.subjectId },
                    subjectId: undefined,
                })),
            enabled: !!projectPartId && !!reportPageId && !!projectId,
        }
    )
}

/**to check if there are missing images or details */
export const isReportPageValidForPublish = (pageId: number) => {
    return useQuery(["report-page", "Subsection", "publishCheck", pageId], () =>
        glClient.query({
            isReportPageValidForPublish: [{ id: pageId }],
        })
    )
}

export function useRemoveProjectPage() {
    return useMutation({
        mutationFn: (payload: { id: number }) => {
            return glClient.mutation({
                removePage: [payload, { id: true }],
            })
        },
    })
}
