import { Col, Row, Select } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useAllSubjectTags } from "../../api/subjectTags"

const SubjectsTagFilter = (props: {
    label?: string
    useSearchParams?: boolean
}) => {
    const [searchParams, setSearchParams] = useSearchParams()

    /** to get the searched status from URL */

    const querySearchTag = useMemo(() => {
        const tags = searchParams.get("tags")

        if (!props.useSearchParams || !tags) return []

        return tags.split(",") // split by comma
    }, [props.useSearchParams, searchParams])

    const tagsQuery = useAllSubjectTags()
    const [selectedItems, setSelectedItems] = useState<string[]>(querySearchTag)

    /**Define the select DD value and label */
    const options = useMemo(() => {
        if (!tagsQuery.isSuccess) {
            return []
        }

        return tagsQuery.data.map((item) => ({
            value: item.id + "",
            label: item.name,
        }))
    }, [tagsQuery.isSuccess, tagsQuery.data])

    // Filter `option.label` match the user type `input`
    const filterOption = (
        input: string,
        option?: { label: string; value: string }
    ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

    /**
     * update search params on select from DD
     */
    useEffect(() => {
        if (!props.useSearchParams) return

        // to add the tag filter to the url
        if (selectedItems) {
            setSearchParams((prev) => {
                return {
                    ...Object.fromEntries(prev),
                    tags: selectedItems.join(",") || "",
                }
            })
        }
    }, [props.useSearchParams, selectedItems, setSearchParams])

    return (
        <Row className="mb-2">
            <Col span={24} className="flex justify-start items-center">
                <span style={{ marginRight: 8 }}>
                    {props.label || "Filter view by:"}
                </span>
                <Select
                    mode="multiple"
                    placeholder="+ Add filter"
                    onChange={(selected) =>
                        setSelectedItems(selected.map((s) => String(s)))
                    }
                    style={{ width: "50%" }}
                    filterOption={filterOption}
                    options={options}
                    value={selectedItems}
                    optionLabelProp="label"
                />
            </Col>
        </Row>
    )
}
export default SubjectsTagFilter
