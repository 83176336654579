import { VaesUserRoles } from "@vaes-dashboard-2/graphql/userRoles"
import { useMutation, useQuery } from "react-query"
import { glClient } from "./client/graphqlClient"
import { queryClient } from "./client/reactQuery"
import { authedAxios } from "./client/axios"
import { getPreviewToken, setPreviewToken } from "../lib/helpers/tokens-helpers"
import { REST_API_URL } from "./client/config"
import { useGetProjectInfo } from "./project"

const getCurrentUserQueryDetails = () => ({
    queryKey: ["user", "current"],
    queryFn: async () => {
        return glClient.query({
            currentUser: {
                userId: true,
                email: true,
                name: true,
                imageUrl: true,
                title: true,
                isActivated: true,
                roleName: true,
                isNew: true,
                company: {
                    id: true,
                    name: true,
                    currency_key: {
                        key: true,
                        type: true,
                        value: true,
                    },
                    logo: true,
                },
            },
        })
    },
})

export function useCurrentUser() {
    const query = getCurrentUserQueryDetails()

    return useQuery(query.queryKey, query.queryFn)
}

export function userCurrentUserId() {
    const query = getCurrentUserQueryDetails()

    return useQuery(query.queryKey, query.queryFn, {
        select: (data) => data?.currentUser?.userId,
    })
}

export const getUserSession = async () => {
    const query = getCurrentUserQueryDetails()
    type QueryType = Awaited<ReturnType<typeof query.queryFn>>

    return (
        (await queryClient.getQueryData<QueryType>(query.queryKey, {
            stale: false, // refetch if the query was staled
        })) ?? queryClient.fetchQuery(query)
    )
}

export function useVaesUsers(enabled = true) {
    const details = {
        queryKey: ["users", "list", "vaes"],
        queryFn: () => {
            return glClient.query({
                vaesUsers: {
                    userId: true,
                    name: true,
                    email: true,
                    roleName: true,
                    imageUrl: true,
                    isDeleted: true,
                },
            })
        },
    }

    return useQuery(details.queryKey, details.queryFn, {
        staleTime: Infinity,
        enabled,
        select: (data) => data.vaesUsers,
    })
}

export function useIsVaesUser() {
    const query = getCurrentUserQueryDetails()

    return useQuery(query.queryKey, query.queryFn, {
        select: (data) => {
            const role = data?.currentUser?.roleName as VaesUserRoles

            return role === "VaesEngineer" || role === "VaesEngineeringManager"
        },
    })
}

export function useIsClient() {
    const query = getCurrentUserQueryDetails()

    return useQuery(query.queryKey, query.queryFn, {
        select: (data) => {
            const role = data?.currentUser?.roleName as VaesUserRoles

            return role === "Client"
        },
    })
}

export function useIsVaesEngineer() {
    const query = getCurrentUserQueryDetails()

    return useQuery(query.queryKey, query.queryFn, {
        select: (data) => {
            const role = data?.currentUser?.roleName as VaesUserRoles

            return role === "VaesEngineer"
        },
    })
}

export function useIsProjectManager(projectId: number) {
    const query = getCurrentUserQueryDetails()
    const project = useGetProjectInfo(projectId)

    return useQuery(query.queryKey, query.queryFn, {
        select: (data) => {
            const role = data?.currentUser?.roleName as VaesUserRoles

            return (
                role === "VaesEngineer" &&
                (data?.currentUser?.userId ===
                    project.data?.getProject?.projectManager1Id ||
                    data?.currentUser?.userId ===
                        project.data?.getProject?.projectManager2Id)
            )
        },
    })
}

export function useIsVaesEngineeringManager() {
    const query = getCurrentUserQueryDetails()

    return useQuery(query.queryKey, query.queryFn, {
        select: (data) => {
            const role = data?.currentUser?.roleName as VaesUserRoles

            return role === "VaesEngineeringManager"
        },
    })
}

export function useIsClientAdmin() {
    const query = getCurrentUserQueryDetails()

    return useQuery(query.queryKey, query.queryFn, {
        select: (data) => {
            const role = data?.currentUser?.roleName as VaesUserRoles

            return role === "ClientAdmin" || role === "ClientOwner"
        },
    })
}

export function useActivateUser() {
    return useMutation({
        mutationFn: (payload: {
            user_id: number
            name: string
            title: string
        }) => {
            return glClient.mutation({
                activateUser: [
                    payload,
                    {
                        userId: true,
                        name: true,
                    },
                ],
            })
        },
    })
}

export function useUpdateUser() {
    return useMutation({
        mutationFn: (payload: {
            name: string
            title: string
            img_key?: string
        }) => {
            return glClient.mutation({
                updateUser: [
                    payload,
                    {
                        userId: true,
                    },
                ],
            })
        },
    })
}

export function useChangeUserRole() {
    return useMutation({
        mutationFn: (payload: {
            userId: number
            companyId: number
            roleName: string
        }) => {
            return glClient.mutation({
                changeUserRoleName: [
                    payload,
                    {
                        userId: true,
                    },
                ],
            })
        },
    })
}

export function useUpdateUserRole() {
    return useMutation({
        mutationFn: (payload: { role_name: string; userId: number }) => {
            return glClient.mutation({
                updateUser: [
                    payload,
                    {
                        userId: true,
                    },
                ],
            })
        },
    })
}

export function useRemoveUser() {
    return useMutation({
        mutationFn: (payload: { userId: number }) => {
            return glClient.mutation({
                deleteUser: [
                    payload,
                    {
                        userId: true,
                    },
                ],
            })
        },
    })
}

export function inviteUsersToTheCompany() {
    return useMutation({
        mutationFn: (payload: {
            emails: string[]
            roleName: VaesUserRoles
            message?: string
            companyId?: number
        }) => {
            return glClient.mutation({
                inviteUsersToMyCompany: [
                    payload,
                    {
                        userId: true,
                    },
                ],
            })
        },
    })
}

export function useMarkUserAsNotNew() {
    return useMutation({
        mutationFn: () => {
            return glClient.mutation({
                markAsNotNew: true,
            })
        },
    })
}

export const useDefaultMessages = (userName: string, companyName: string) => {
    return `${userName} has invited you to join the ${companyName} Dashboard.`
}

export function usePreviewAsUser() {
    return useMutation({
        mutationFn: (targetUserId: number) => {
            return authedAxios({
                baseURL: REST_API_URL,
                method: "GET",
                url: "preview-as-user",
                params: { targetUserId },
            }).then((res) => {
                // update access token
                setPreviewToken(res.data.accessToken)
                // invalidate all
                queryClient.invalidateQueries()
            })
        },
    })
}

/**
 *
 * @returns  true if the user is previewing as a user, we check the preview token
 */
export function useIsPreviewing() {
    return useQuery("previewing", () => Promise.resolve(!!getPreviewToken()))
}

/**
 *
 * @returns  true if the user is previewing as a user, we check the preview token
 */
export function isPreviewing() {
    return !!getPreviewToken()
}
