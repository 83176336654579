import React, { useMemo, useState, useCallback } from "react"
import { Form, Select, InputNumber, Tag, Spin } from "antd"
import { useForm } from "antd/es/form/Form"
import { useLookupOfType } from "../../../../api/lookups"
import VaesButton from "../../../../components/VaesButton/VaesButton"
import { queryClient } from "../../../../api/client/reactQuery"
import {
    useGetAllProjectLabels,
    useUpdateProjectLabels,
} from "../../../../api/projectLabel"

type FormValuesType = {
    priority: number
    type: string
    name: string
}

export const EditProjectSubject = (props: {
    projectId: number
    subjectId: number
    onSuccess: () => void
}) => {
    // state
    const [isFormDirty, setIsFormDirty] = useState(false)
    // fetch all subjects

    const projectLabelsQuery = useGetAllProjectLabels(props.projectId)
    // fetch all types
    const types = useLookupOfType(["subject_type"])

    // update multiple
    const update = useUpdateProjectLabels()

    const [form] = useForm()

    const onValuesChange = useCallback((values: FormValuesType) => {
        if (values) {
            setIsFormDirty(true)
        }
    }, [])

    /**
     * parse unites
     */
    const unitsList = useMemo(() => {
        if (props.subjectId) {
            return types.data?.getLookupsOfType?.map((t) => ({
                label: t.value,
                value: t.key,
            }))
        }
    }, [types.data])

    /**to load the data for the selected subject to be shown in the form */
    const subjectData = useMemo(() => {
        if (projectLabelsQuery.isSuccess) {
            return projectLabelsQuery.data?.find(
                (item) =>
                    item?.id == props.subjectId &&
                    item?.project.id == props.projectId
            )
        }
    }, [
        projectLabelsQuery.data,
        projectLabelsQuery.isSuccess,
        props.projectId,
        props.subjectId,
    ])

    /** if the values are changed on edit button click update the subject priority and unit */
    const onFinish = (values: FormValuesType) => {
        if (!subjectData) return

        update.mutate(
            {
                projectId: props.projectId,
                subjectId: props.subjectId,
                priority: values.priority,
                unit: values.type,
            },
            {
                onSuccess: () => {
                    // refetch all subjects
                    queryClient.invalidateQueries([
                        "project_subjectList",
                        props.projectId,
                    ])
                    props.onSuccess()
                },
            }
        )
    }

    return (
        <Spin spinning={projectLabelsQuery.isLoading}>
            <Form
                form={form}
                name="normal_login"
                className="login-form"
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                layout="vertical"
                validateTrigger="onBlur"
            >
                <Form.Item label="Subject name" name="name">
                    <p className=" text-gray-400 m-0">{subjectData?.name}</p>
                </Form.Item>
                <Form.Item label="Assign tags" name="tags">
                    {subjectData?.tags?.map((t) => (
                        <Tag key={t.id} className="mt-1">
                            {t.name}
                        </Tag>
                    ))}
                </Form.Item>
                <Form.Item
                    label="Priority"
                    name="priority"
                    initialValue={subjectData?.priority}
                    required
                >
                    <InputNumber
                        type={"number"}
                        min={0}
                        controls={false}
                        className="w-full"
                        defaultValue={subjectData?.priority}
                    />
                </Form.Item>
                <Form.Item label="unit" name="type" required>
                    <Select
                        options={unitsList}
                        loading={types.isLoading}
                        defaultValue={subjectData?.unit?.value}
                    />
                </Form.Item>

                <Form.Item>
                    <VaesButton
                        type="primary"
                        htmlType="submit"
                        loading={update.isLoading}
                        disabled={!isFormDirty || update.isLoading}
                        block
                        mpBtnName="Edit subject"
                    >
                        Edit subject
                    </VaesButton>
                </Form.Item>
            </Form>
        </Spin>
    )
}
