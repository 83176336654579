import { useMutation, useQuery } from "react-query"
import { glClient } from "./client/graphqlClient"
import { ID } from "@vaes-dashboard-2/graphql/types"

export const useGetProjectMissingFiles = (projectId: ID, status: string[]) => {
    return useQuery(["missingfiles", "listByProject", projectId, status], () =>
        glClient.query({
            listMissingFilesByStatus: [
                { projectId: Number(projectId), statusKey: status },
                {
                    id: true,
                    name: true,
                    status_key: {
                        key: true,
                        type: true,
                        value: true,
                    },
                },
            ],
        })
    )
}

/** update the missing files status to sent after sending files */
export function usePostMissingFiles() {
    return useMutation({
        mutationFn: (payload: { project_id: number }) => {
            return glClient.mutation({
                postMissingFiles: [
                    payload,
                    {
                        project: { id: true },
                    },
                ],
            })
        },
    })
}

export function useCreateMissingFile() {
    return useMutation({
        mutationFn: (payload: { projectId: number; name: string }) => {
            return glClient.mutation({
                addMissingFiles: [
                    payload,
                    {
                        project: { id: true },
                        name: true,
                    },
                ],
            })
        },
    })
}

export function useModifyMissingFile() {
    return useMutation({
        mutationFn: (payload: {
            projectId: number
            id: number
            name: string
        }) => {
            return glClient.mutation({
                updateMissingFiles: [
                    payload,
                    {
                        id: true,
                    },
                ],
            })
        },
    })
}

export function useReceiveMissingFile() {
    return useMutation({
        mutationFn: (payload: { id: number }) => {
            return glClient.mutation({
                missingFileReceived: [
                    payload,
                    {
                        id: true,
                    },
                ],
            })
        },
    })
}

export function useSubmitMissingFile() {
    return useMutation({
        mutationFn: (payload: { projectId: number }) => {
            return glClient.mutation({
                missingFileSubmitted: [
                    payload,
                    {
                        project: { id: true },
                    },
                ],
            })
        },
    })
}

export function useremoveMissingFile() {
    return useMutation({
        mutationFn: (payload: { id: number }) => {
            return glClient.mutation({
                deleteMissingFiles: [
                    payload,
                    {
                        id: true,
                    },
                ],
            })
        },
    })
}

export const useGetAllProjectMissingFiles = (
    projectId: number,
    status?: string[]
) => {
    return useQuery(
        [
            "missingfiles",
            "listByProject",
            projectId,
            status?.length ? status : "all",
        ],
        () =>
            glClient.query({
                listMissingFiles: [
                    {
                        projectId: Number(projectId),
                        status: status?.length ? status : undefined,
                    },
                    {
                        id: true,
                        name: true,
                        status_key: {
                            key: true,
                            type: true,
                            value: true,
                        },
                        createdBy: { userId: true, name: true },
                        createdAt: true,
                        updatedAt: true,
                        updatedBy: { userId: true, name: true },
                    },
                ],
            })
    )
}
