import React from "react"
import { Outlet, useMatch, useParams } from "react-router-dom"
import { Layout as AntLayout, Divider } from "antd"
import useSrollTop from "../../../lib/hooks/useSrollTop"
import { AdminHeader, Breadcrumbs } from "./Partials/Header"
import { ActionBar } from "./Partials/ActionBar"
import { ProjectSteps } from "./Partials/ProjectSteps"
import style from "./AdminLayout.module.scss"
import Footer from "./Partials/Footer"
import ReportSider from "./Partials/ReportSider"
import classNames from "classnames"

export function AdminLayout() {
    useSrollTop()

    return (
        <AntLayout className=" min-h-screen">
            <div className="px-3 bg-[var(--color-purple)]">
                <AdminHeader />

                <div className="rounded-t-lg bg-[#FAFAFA]">
                    <Breadcrumbs />

                    {/* Wrapper make sure content has max width and centralized */}
                    <div className={style.contentWrapper}>
                        <AntLayout.Content className={style.content}>
                            <Outlet />
                        </AntLayout.Content>
                    </div>
                </div>

                <Footer />
            </div>
        </AntLayout>
    )
}

export const AdminProjectLayout = (props: { bg: "white" | "gray" }) => {
    useSrollTop()
    const { projectId } = useParams()
    const isProjectInfo = useMatch(`/admin/project/${projectId}/project-info`)

    return (
        <AntLayout>
            <div className="px-3 bg-[var(--color-purple)]">
                <AdminHeader />

                <div className="rounded-t-lg bg-[#FAFAFA]">
                    <Breadcrumbs />

                    <ActionBar />
                    {!isProjectInfo && (
                        <>
                            <ProjectSteps />
                            <Divider className="my-0" />
                        </>
                    )}

                    {/* Wrapper make sure content has max width and centralized */}
                    <div
                        className={classNames(style.contentWrapper, {
                            "bg-white": props.bg === "white",
                        })}
                    >
                        <AntLayout.Content className={style.content}>
                            <Outlet />
                        </AntLayout.Content>
                    </div>
                </div>
                <Footer />
            </div>
        </AntLayout>
    )
}

// Same as project layout but with a sider for report pages, original design data and project understanding
export const AdminReportLayout = () => {
    useSrollTop()

    return (
        <AntLayout>
            <div className="px-3 bg-[var(--color-purple)]">
                <AdminHeader />

                <div className="rounded-t-lg bg-[#FAFAFA]">
                    <Breadcrumbs />

                    <ActionBar />

                    <ProjectSteps />
                    <Divider className="my-0" />

                    <AntLayout>
                        <ReportSider />
                        <Divider type="vertical" className="mx-0 h-auto" />

                        {/* Wrapper make sure content has max width and centralized */}
                        <div className={style.contentWrapper}>
                            <AntLayout.Content
                                className={classNames(
                                    style.content,
                                    "min-h-[calc(100vh-320px)]"
                                )}
                            >
                                <Outlet />
                            </AntLayout.Content>
                        </div>
                    </AntLayout>
                </div>
                <Footer />
            </div>
        </AntLayout>
    )
}
