import { glClient } from "./client/graphqlClient"
import { useQuery } from "react-query"

export const useGetStaticPage = (pageId: string) => {
    return useQuery(["staticPage", pageId], () =>
        glClient.query({
            getStaticPage: [{ pageId }],
        })
    )
}
