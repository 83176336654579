import React, { useContext, useEffect } from "react"
import { redirect, useLocation } from "react-router-dom"

import UploadFiles from "../components/UploadFiles/UploadFiles"
import ProjectCardGrid from "../components/ProjectCardGrid/ProjectCardGrid"
import { FileUploadContext } from "../lib/uploadContext"
import { getUserSession, isPreviewing } from "../api/user"
import Tour from "../components/Tour"
import { isEngineerOrAdmin } from "@vaes-dashboard-2/graphql/userRoles"
import UploadLink from "../components/UploadLink/UploadLink"

/**
 * Make sure the user has all his data, otherwise redirect him to the
 * forms.
 * TODO: Move this logic to better place, it should be a parent for all auth routes,
 * but putting it in AuthRoute is not a good idea
 */
export const homeLoader = () => async () => {
    const data = await getUserSession()

    // user should activate his account first
    if (!data?.currentUser?.isActivated) {
        return redirect("/user/activate")
    }

    // user should belong to a company first
    if (data.currentUser.company == null) {
        return redirect("/user/register-company")
    }

    if (isEngineerOrAdmin(data.currentUser.roleName)) {
        return redirect("/admin")
    }

    // in case user is new, redirect him to onboarding screens
    // prevent this in preview mode
    if (data.currentUser.isNew && !isPreviewing()) {
        return redirect("/onboarding")
    }

    return null
}

function Home() {
    const uploadCX = useContext(FileUploadContext)

    // to check for onboarding state in the route
    const location = useLocation()

    /**
     * Clear the session when the user enters the home page
     * This is a good place to do it, since the user can get back to the home page by clicking the logo, back button, etc.
     */
    useEffect(() => {
        uploadCX?.clearSession()
    }, [])

    return (
        <div role="presentation">
            {location.state?.onboarding && <Tour tourName="home" />}
            <UploadFiles />
            <UploadLink />
            <ProjectCardGrid />
        </div>
    )
}

export default Home
