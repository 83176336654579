import React, { useCallback, useEffect, useMemo, useState } from "react"
import { CaretRightOutlined } from "@ant-design/icons"
import { useVaEReport } from "../../../../api/vaeDetailedReport"
import { useParams } from "react-router-dom"
import { DetailedPackage } from "./DetailedPackage"
import { Collapse } from "antd"

export const DetailedPackagesList = () => {
    // local state
    const [activePanels, setActivePanels] = useState(["1"])

    // react router
    const params = useParams<{ projectId: string }>()

    // api
    const vaeQuery = useVaEReport(Number(params.projectId))

    /** on change the active array is updated */
    const onChangeHandler = useCallback((id: string[] | string) => {
        if (typeof id === "string") id = [id]
        else setActivePanels(id)
    }, [])

    /** to get the initial array of open elements */
    useEffect(() => {
        const activePanalKeys = vaeQuery.data?.map((data) => data.id + "")

        setActivePanels(activePanalKeys || [])
    }, [vaeQuery.data])

    const packages = useMemo(() => {
        if (!vaeQuery.data) return null

        return vaeQuery.data.map((d) => (
            <DetailedPackage
                key={d.id}
                data={{
                    name: d.name,
                    details: d.details,
                    image: d.image,
                    gifImage: d.gifImage,
                    originalDesignImage: d.originalDesignImage,
                    isPublished: d.isPublished,
                    advantages: d.advantages,
                    description: d.description,
                    savings: d.savings,
                    summary: d.summary,
                    package: d.package,
                    id: d.id,
                    projectPartId: d.projectPartId,
                    versions: d.versions,
                    parentId: d.parentId,
                    originalParentId: d.originalParentId,
                    createdAt: d.createdAt,
                    createdBy: d.createdBy,
                    updatedAt: d.updatedAt,
                    updatedBy: d.updatedBy,
                }}
            />
        ))
    }, [vaeQuery.data])

    if (!packages) return null

    return (
        <Collapse
            bordered={false}
            activeKey={activePanels}
            expandIcon={({ isActive }) => (
                <CaretRightOutlined
                    style={{ fontSize: "20px" }}
                    rotate={isActive ? 90 : 0}
                />
            )}
            onChange={onChangeHandler}
            style={{ background: "white" }}
            collapsible="icon"
        >
            {packages}
        </Collapse>
    )
}
