import React from "react"
import { Modal } from "antd"
import VaesButton from "../../../../components/VaesButton/VaesButton"
import { InfoCircleFilled } from "@ant-design/icons"
import {
    checkMissingVaePackageTotalcost,
    isPackageValidForPublish,
    usePublishVaEPackage,
} from "../../../../api/vaeDetailedReport"
import { useParams } from "react-router-dom"
import { queryClient } from "../../../../api/client/reactQuery"

const { confirm, error } = Modal

export const SentToClient = (props: {
    projectPartId: number
    packageId: number
}) => {
    // react router
    const params = useParams<{ projectId: string }>()
    // api
    const canPublish = isPackageValidForPublish(props.packageId)
    const haveTotalcost = checkMissingVaePackageTotalcost(props.packageId)
    const publishPackage = usePublishVaEPackage()

    // /**publish report */
    const errerPopUp = () => {
        error({
            width: 510,
            title: (
                <h3 className="text-base roboto-medium m-0 ">
                    This package is incomplete.
                </h3>
            ),
            icon: <InfoCircleFilled />,

            content: (
                <p className="font-robotoRegular ">
                    You cannot send this package because it contains incomplete
                    sections. Please ensure that the package files, images and
                    data fields are inputted correctly into your package, and
                    try again.
                </p>
            ),
            okText: "Ok, got it",
            okType: "primary",
        })
    }
    const warningPopUp = () => {
        confirm({
            width: 550,
            title: (
                <h3 className="text-base roboto-medium mb-0 ">
                    {`Are you sure you want to send this package to the client
                    without "Total cost saving" in details table?`}
                </h3>
            ),

            content: (
                <p className="font-robotoRegular ">
                    Once the package is sent to client, the package can not be
                    updated or redacted. Are you sure you want to continue?
                </p>
            ),
            okText: "Yes, continue",
            okType: "primary",
            cancelText: "No, cancel",

            onOk() {
                handlePublish()
            },
        })
    }
    const confirmPopUp = () => {
        confirm({
            width: 510,
            title: (
                <h3 className="text-base roboto-medium ">
                    Are you sure you want to send this package to the client?
                </h3>
            ),
            icon: <></>,

            content: (
                <p className="font-robotoRegular ">
                    Once the package is sent to client, the package can not be
                    updated or redacted. Are you sure you want to continue?
                </p>
            ),
            okText: "Yes, continue",
            okType: "primary",
            cancelText: "No, cancel",

            onOk() {
                handlePublish()
            },
        })
    }

    const handlePublish = () => {
        if (props.packageId) {
            publishPackage.mutate(
                {
                    projectId: Number(params.projectId),
                    packageId: props.packageId,
                },
                {
                    onSuccess: () => {
                        // refetch all subjects
                        queryClient.invalidateQueries([
                            "vae-report",
                            Number(params.projectId),
                            "list",
                        ])

                        queryClient.invalidateQueries([
                            "report-page",
                            Number(props.projectPartId),
                        ])
                    },
                }
            )
        }
    }

    const showSendToClientConfirm = () => {
        if (!!canPublish.data?.isPackageValidForPublish) {
            errerPopUp()
        } else if (!!haveTotalcost.data?.checkMissingVaePackageTotalcost) {
            warningPopUp()
        } else {
            confirmPopUp()
        }
    }

    return (
        <VaesButton
            type="primary"
            mpBtnName="detailed-package-publish"
            onClick={showSendToClientConfirm}
        >
            Send to client
        </VaesButton>
    )
}
