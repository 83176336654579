import { useCallback } from "react"
import { removeTokens } from "../helpers/tokens-helpers"
import { removeLocalStorage } from "../helpers/localStorage-helpers"
import { VAES_USER_REMEMBER_ME } from "../constants"
import { queryClient } from "../../api/client/reactQuery"
import { useNavigate } from "react-router-dom"

export const useLogout = () => {
    const navigate = useNavigate()

    /**
     * Logout user
     */
    return useCallback(() => {
        removeTokens()
        removeLocalStorage(VAES_USER_REMEMBER_ME)
        queryClient.setQueryData(["user", "current"], { currentUser: null })
        navigate("/login")
    }, [navigate])
}
