import React from "react"
import { Outlet, redirect, LoaderFunction } from "react-router-dom"
import { getUserSession } from "../../api/user"
import { setTokens } from "../../lib/helpers/tokens-helpers"

const getToken = () => {
    const params = new URLSearchParams(window.location.search)

    return {
        access: params.get("token"),
        refresh: params.get("refresh"),
    }
}

/**
 * Check if the user is logged in, if that true, redirect him to the home page
 */
export const loader: LoaderFunction = async () => {
    // check if the token is set in the url
    // TODO: this logic should be moved to better route
    const token = getToken()
    if (token.access && token.refresh) {
        // save the refresh token only if the user checked the remember me checkbox
        setTokens(token.access, token.refresh)
    }

    // wait for 5 seconds to make sure cookies are set
    await new Promise((resolve) => setTimeout(resolve, 1000))

    const user = await getUserSession()

    if (user.currentUser) {
        const redirectURL = new URLSearchParams(window.location.search).get(
            "redirect"
        )

        return redirect(redirectURL || "/")
    }

    return user
}

function PublicRoute() {
    return <Outlet />
}

export default PublicRoute
