// custom hook to block navigation when there are unsaved changes
// NOTE: this hook is not generic at all, it should be refactored to be more generic
import { useEffect, useCallback } from "react"
import { Modal } from "antd"
import {
    unstable_useBlocker as useBlocker,
    unstable_Blocker as Blocker,
} from "react-router-dom"
import i18n from "../../.i18n"

const { confirm } = Modal

export const useNavigationBlocker = (
    isDirty: boolean,
    navigateToHandler?: () => void,
    resetOnSuccess?: () => void,
    isSuccess?: boolean,
    navigationDeps?: React.DependencyList
) => {
    const blocker = useBlocker(Boolean(isDirty)) as Blocker

    /**
     * show confirm modal when user try to leave the page while uploading files
     */
    const showConfirm = useCallback(() => {
        confirm({
            title: i18n.t("leavePageWarning"),
            content: i18n.t("leavePageWarningContent"),
            onOk: () => blocker.proceed?.(),
            onCancel: () => blocker.reset?.(),
        })
    }, [blocker])

    useEffect(() => {
        navigateToHandler?.()
    }, navigationDeps)

    useEffect(() => {
        resetOnSuccess?.()
    }, [isSuccess])

    /**
     *  handle blocker state change to show confirm modal when user try to leave the page while uploading files
     */
    useEffect(() => {
        if (blocker.state === "blocked" && !isSuccess) {
            showConfirm()
        }
    }, [blocker.state, showConfirm])
}
