import React, { useCallback, useMemo } from "react"
import { Typography, Table } from "antd"
import { EditableCell, Row } from "./Partials/EditableCell"
import VaesButton from "../VaesButton/VaesButton"

const columns = [
    {
        width: "30%",
        editable: true,
        type: "subject",
    },
    {
        title: () => (
            <Typography.Text className="text-lg">
                Original Design
            </Typography.Text>
        ),
        type: "originalValue",
        width: "23%",
        editable: true,
    },
    {
        title: (
            <Typography.Text className="text-lg">VaE Design</Typography.Text>
        ),
        type: "veaValue",
        width: "39%",
        editable: true,
    },
    {
        width: "5%",
        type: "action",
        editable: true,
    },
]

/**
 * Override default header cell by adding custom padding
 */
const HeaderCell: React.FC<{ children: React.ReactNode }> = ({
    children,
    ...props
}) => {
    return (
        <td {...props} className="!py-2 px-4">
            {children}
        </td>
    )
}

/**
 * Main component, saving table is a form field
 */
const SavingsTable = (props: {
    onChange?: (value: Row[]) => void
    value?: Row[]
    partId: number
}) => {
    /**
     * update existed rows and new row
     */
    const onRowChange = useCallback(
        (rowIdx: number, updatedRow: Row) => {
            const updatedRows = (
                !!props.value?.length ? props.value : [{ idx: 0 }]
            ).map((item) => {
                if (item.idx === rowIdx) {
                    return updatedRow
                }
                return item
            })

            // notify parent
            props.onChange?.(updatedRows)
        },
        [props.value, props.onChange]
    )

    /**
     * Handle adding new raw
     */
    const handleAddRow = useCallback(() => {
        const currentRows = props.value || []

        props.onChange?.([...currentRows, { idx: currentRows.length }])
    }, [props.value, props.onChange])

    /**
     * Remove existed row
     */
    const handleDeleteRow = useCallback(
        (rowIdx: number) => {
            const updatedRows = (props.value || []).filter(
                (item) => item.idx !== rowIdx
            )

            props.onChange?.(updatedRows)
        },
        [props.value, props.onChange]
    )

    /**
     * Parse table rows
     */
    const mergedColumns = useMemo(
        () =>
            columns.map((col) => {
                // action column
                if (col.type === "action") {
                    return {
                        ...col,
                        onCell: (record: Row) => ({
                            record,
                            type: col.type,
                            onRemoveRow: handleDeleteRow,
                            editable: true,
                            className: "text",
                        }),
                    }
                }

                return {
                    ...col,
                    onCell: (record: Row) => ({
                        record,
                        type: col.type,
                        editable: col.editable,
                        onRowChange,
                        partId: props.partId,
                        className: "text",
                    }),
                }
            }),
        [handleDeleteRow, onRowChange, props.partId]
    )

    /**
     * add new row to the top of the table
     */
    const dataSource = useMemo(() => {
        const currentProps = !!props.value?.length ? props.value : [{ idx: 0 }]

        return currentProps
    }, [props.value])

    return (
        <div>
            <VaesButton
                mpBtnName="add-report-subject"
                className="mb-3"
                onClick={handleAddRow}
            >
                Add subject
            </VaesButton>
            <Table
                components={{
                    header: {
                        cell: HeaderCell,
                    },
                    body: {
                        cell: EditableCell,
                    },
                }}
                dataSource={dataSource}
                columns={mergedColumns}
                rowClassName="editable-row"
                bordered
                pagination={false}
            />
        </div>
    )
}

export default SavingsTable
