// context/todoContext.tsx
import React, { ReactNode, useCallback, useReducer } from "react"
import { FileWithPath } from "react-dropzone"
import { useNavigate } from "react-router-dom"
import { logUpload, useCreateFilesSession } from "../api/fileUpload"
import { Spin } from "antd"
import { queryClient } from "../api/client/reactQuery"
import { useCurrentUser } from "../api/user"
import mixpanel from "mixpanel-browser"
import { getPageUrl } from "./helpers/general-helpers"

// The main data structure that we will save in the state
export type ProjectFile = {
    id: string // client id for each upload patch (card)
    data: FileWithPath[] // list of files
    dbFileIds: number[] // will be filled after file(s) is created in the db
    projectId?: number // the related project id if existed
    projectName?: string // the related project name if existed
}

export type FileStatus =
    | "SELECTED"
    | "UPLOADING"
    | "UPLOADED"
    | "ABORTED"
    | "ERROR"

type State = {
    files: ProjectFile[]
    fileStatus: { [key: string]: FileStatus } // map: projectId -> status
    uploadedCnt: { [key: string]: number } // map: projectId -> uploaded files count (progress)
    currentSessionId?: number
}

// reducer actions
export type Actions =
    | "ADD_PROJECT_FILES"
    | "ADD_SESSION_FILES"
    | "REMOVE_FILE"
    | "SET_FILE_STATUS"
    | "SET_SESSION_ID"
    | "CLEAR_SESSION"
    | "ASSIGN_FILES_TO_PROJECT"
    | "INC_UPLOADED_CNT"
    | "ADD_DB_FILE_ID"

// reducer action type
export type UploadFilesAction = {
    type: Actions
    payload: {
        files?: ProjectFile // for add project file
        id?: string // for remove
        status?: FileStatus // for set status
        sessionId?: number
        projectName?: string
        projectId?: number
        incValue?: number
        dbFileId?: number
    }
}

// Context value type (return type of useContext)
export type FileUploadContextType = {
    state: State
    addProjectFiles: (
        files: File[],
        projectId: number,
        projectName: string
    ) => void
    addFileToSession: (files: File[]) => void
    assignFilesToProject: (projectName: string, projectId: number) => void
    removeFile: (id: string) => void
    setFileStatus: (id: string, status: FileStatus) => void
    incUploadedCnt: (id: string, value: number) => void
    clearSession: () => void
    onFileCreated: (id: string, dbFileId: number) => void
}

const initialState: State = {
    files: [],
    fileStatus: {},
    uploadedCnt: {},
    currentSessionId: undefined,
}

const reducer = (state: State, action: UploadFilesAction): State => {
    switch (action.type) {
        case "ADD_PROJECT_FILES":
            if (!action.payload.files) {
                return state
            }
            // append files to the files array
            return {
                ...state,
                files: [...state.files, action.payload.files],
                fileStatus: {
                    ...state.fileStatus,
                    [action.payload.files.id]: "SELECTED",
                },
            }

        case "SET_FILE_STATUS":
            if (!action.payload.status || !action.payload.id) {
                return state
            }

            // log successful upload
            if (action.payload.status === "UPLOADED") {
                const projectFile = state.files.find(
                    (p) => p.id === action.payload.id
                )
                if (projectFile) {
                    logSuccessfulUpload(projectFile, state.currentSessionId)
                } else {
                    console.warn("File id not found", action.payload.id)
                }
            }
            return {
                ...state,
                fileStatus: {
                    ...state.fileStatus,
                    [action.payload.id]: action.payload.status,
                },
            }
        case "REMOVE_FILE":
            // remove by id
            return {
                ...state,
                files: state.files.filter(
                    (f) => f.id + "" !== action.payload.id + ""
                ),
            }

        case "SET_SESSION_ID":
            if (!action.payload.sessionId) {
                return state
            }

            return {
                ...state,
                currentSessionId: action.payload.sessionId,
            }

        case "ASSIGN_FILES_TO_PROJECT":
            return {
                ...state,
                files: state.files.map((f) => {
                    if (!f.projectId) {
                        return {
                            ...f,
                            projectId: action.payload.projectId,
                            projectName: action.payload.projectName,
                        }
                    }
                    return f
                }),
            }
        case "INC_UPLOADED_CNT":
            if (!action.payload.id || action.payload.incValue == null) {
                console.warn(
                    "INC_UPLOADED_CNT: invalid payload",
                    action.payload
                )

                return state
            }
            return {
                ...state,
                uploadedCnt: {
                    ...state.uploadedCnt,
                    [action.payload.id]:
                        (state.uploadedCnt[action.payload.id] || 0) +
                        action.payload.incValue,
                },
            }

        case "CLEAR_SESSION":
            return {
                ...state,
                currentSessionId: undefined,
            }
        case "ADD_DB_FILE_ID":
            return {
                ...state,
                files: state.files.map((f) => {
                    if (
                        f.id === action.payload.id &&
                        Number.isFinite(action.payload.dbFileId)
                    ) {
                        return {
                            ...f,
                            dbFileIds: [
                                ...(f.dbFileIds ?? []),
                                action.payload.dbFileId as number,
                            ],
                        }
                    }
                    return f
                }),
            }
        default:
            return state
    }
}

// Create context
export const FileUploadContext =
    React.createContext<FileUploadContextType | null>(null)

/**
 * Context Provider for file upload
 */
const UploadFilesProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const createFilesSession = useCreateFilesSession()
    const navigate = useNavigate()
    const { data: user } = useCurrentUser()
    const currentWebSite = getPageUrl()

    /**
     *
     */
    const removeFile = React.useCallback((id: string) => {
        dispatch({ type: "REMOVE_FILE", payload: { id } })
    }, [])

    /**
     * Select files to upload them to a project
     */
    const addProjectFiles = useCallback(
        (files: File[], projectId: number, projectName: string) => {
            const groupedFiles = groupFilesByRootName(files)

            Object.values(groupedFiles).forEach((files) => {
                dispatch({
                    type: "ADD_PROJECT_FILES",
                    payload: {
                        files: {
                            data: files,
                            projectId,
                            projectName,
                            id: crypto.randomUUID(),
                            dbFileIds: [],
                        },
                    },
                })
            })

            mixpanel.track("Upload Files", {
                Type: "On A Selected Project",
                Email: user?.currentUser?.email,
                Position: user?.currentUser?.title,
                Company: user?.currentUser?.company?.name,
                CurrentWebSite: currentWebSite,
                files: files.map((file) => ({
                    name: file.name,
                    size: file.size,
                })),
            })
        },
        [currentWebSite, user?.currentUser]
    )

    /**
     * Add files to the session, when you don't have the project id yet
     */
    const addFileToSession = useCallback(
        async (files: File[]) => {
            let sessionId = state.currentSessionId
            // Check if we had a session, create one if not
            if (!sessionId) {
                // create session
                const data = await createFilesSession.mutateAsync()
                dispatch({
                    type: "SET_SESSION_ID",
                    payload: { sessionId: data?.createFilesSession?.id },
                })
                sessionId = data?.createFilesSession?.id

                // redirect to assign files page
                navigate(`/assign-files/${sessionId}`)
            }

            const groupedFiles = groupFilesByRootName(files)

            Object.values(groupedFiles).forEach((files) => {
                dispatch({
                    type: "ADD_PROJECT_FILES",
                    payload: {
                        files: {
                            data: files,
                            id: crypto.randomUUID(),
                            dbFileIds: [],
                        },
                    },
                })
            })
            mixpanel.track("Upload Files", {
                Type: "Unspecified Project",
                Email: user?.currentUser?.email,
                Position: user?.currentUser?.title,
                Company: user?.currentUser?.company?.name,
                CurrentWebSite: currentWebSite,
            })
        },
        [
            state.currentSessionId,
            user?.currentUser?.email,
            user?.currentUser?.title,
            user?.currentUser?.company?.name,
            currentWebSite,
            createFilesSession,
            navigate,
        ]
    )

    /**
     * change file status
     */
    const setFileStatus = useCallback(
        (id: string, status: FileStatus) => {
            if (state.fileStatus[id] === status) {
                return
            }

            dispatch({
                type: "SET_FILE_STATUS",
                payload: {
                    id,
                    status,
                },
            })
        },
        [state.fileStatus]
    )

    /**
     *
     */
    const assignFilesToProject = useCallback(
        (projectName: string, projectId: number) => {
            dispatch({
                type: "ASSIGN_FILES_TO_PROJECT",
                payload: {
                    projectName,
                    projectId,
                },
            })
            mixpanel.track("Assign Files To Project", {
                Email: user?.currentUser?.email,
                Position: user?.currentUser?.title,
                Company: user?.currentUser?.company?.name,
                CurrentWebSite: currentWebSite,
            })
        },
        [
            currentWebSite,
            user?.currentUser?.company?.name,
            user?.currentUser?.email,
            user?.currentUser?.title,
        ]
    )

    /**
     *
     */
    const incUploadedCnt = useCallback((id: string, value: number) => {
        dispatch({
            type: "INC_UPLOADED_CNT",
            payload: {
                id,
                incValue: value ?? 1,
            },
        })
    }, [])

    /**
     * Remove current session id. This should happen when the session added for the first time
     */
    const clearSession = useCallback(() => {
        dispatch({ type: "CLEAR_SESSION", payload: {} })
    }, [])

    const onFileCreated = useCallback((id: string, dbFileId: number) => {
        dispatch({
            type: "ADD_DB_FILE_ID",
            payload: {
                id,
                dbFileId,
            },
        })
    }, [])

    const contextValue = React.useMemo(
        () => ({
            state,
            removeFile,
            addProjectFiles,
            setFileStatus,
            addFileToSession,
            assignFilesToProject,
            incUploadedCnt,
            clearSession,
            onFileCreated,
        }),
        [state]
    )

    return (
        <FileUploadContext.Provider value={contextValue}>
            <Spin spinning={createFilesSession.isLoading}>{children}</Spin>
        </FileUploadContext.Provider>
    )
}

export default UploadFilesProvider

/**
 * Extract folder name if exist from the first file. if no folder uploaded then return file name
 */
export const getRootName = (file: FileWithPath) => {
    if (file.path && file.path !== file.name) return file.path?.split("/")[1]

    return file.name
}

const groupFilesByRootName = (files: FileWithPath[]) => {
    const groupedFiles = files.reduce((acc, file) => {
        const rootName = getRootName(file)
        if (rootName) {
            if (!acc[rootName]) {
                acc[rootName] = []
            }
            acc[rootName].push(file)
        }
        return acc
    }, {} as { [rootName: string]: FileWithPath[] })

    return groupedFiles
}

/**
 * Log upload process to the server
 */
const logSuccessfulUpload = (projectFile: ProjectFile, sessionId?: number) => {
    logUpload({
        name: getRootName(projectFile.data[0]),
        totalSize: projectFile.data.reduce((sum, file) => {
            return sum + file.size
        }, 0),
        filesCount: projectFile.data.length,
        isFile: projectFile.data[0].name === projectFile.data[0].path,
        projectFileIds: projectFile.dbFileIds,
        projectId: projectFile?.projectId,
        sessionId: sessionId,
    }).then(async () => {
        await queryClient.invalidateQueries("fileUploadLog")
    })
}
