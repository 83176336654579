import React, { useMemo } from "react"
import { Space, Tag, Typography } from "antd"
import { useSearchParams } from "react-router-dom"
import CheckableTag from "antd/es/tag/CheckableTag"
import { useAllSubjectTags } from "../../../../api/subjectTags"

const useSelectedTags = () => {
    const [searchParams] = useSearchParams()

    const selectedTags = useMemo(() => {
        const tagParam = searchParams.get("tags")
        if (!tagParam) {
            return []
        }
        return tagParam.split(",")
    }, [searchParams])

    return selectedTags
}

export const SelectedTags = () => {
    const tagsQuery = useAllSubjectTags()

    const selectedTags = useSelectedTags()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setSearchParams] = useSearchParams()

    const handleRemove = (tag: string) => {
        const nextSelectedTags = selectedTags.filter((t) => t !== tag)

        setSearchParams((prev) => ({
            ...Object.fromEntries(prev),
            tags: nextSelectedTags.join(","),
        }))
    }

    if (selectedTags.length === 0) {
        return null
    }

    if (selectedTags.length < 4) {
        const tags = selectedTags.map((tag) => (
            <Tag
                key={tag}
                color="blue"
                closable
                onClose={() => handleRemove(tag)}
            >
                {tagsQuery.data?.find((t) => t.id === Number(tag))?.name || tag}
            </Tag>
        ))

        return <Space size={1}>{tags}</Space>
    }

    return (
        <Typography.Text>{selectedTags.length} tags selected</Typography.Text>
    )
}

export const TagsFilter = () => {
    const [, setSearchParams] = useSearchParams()
    const selectedTags = useSelectedTags()

    const tagsQuery = useAllSubjectTags()

    const handleChange = (tag: string, checked: boolean) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag)

        setSearchParams((prev) => ({
            ...Object.fromEntries(prev),
            tags: nextSelectedTags.join(","),
        }))
    }

    const tagsList = useMemo(() => {
        return tagsQuery.data?.map((tag) => (
            <CheckableTag
                key={tag.id}
                checked={selectedTags.includes(String(tag.id))}
                onChange={(checked) => handleChange(String(tag.id), checked)}
            >
                {tag.name}
            </CheckableTag>
        ))
    }, [selectedTags, tagsQuery.data])

    return (
        <Space size={[0, 8]} wrap>
            {tagsList}
        </Space>
    )
}
