import React, { useCallback, useMemo, useState } from "react"
import { Collapse, Modal, Spin } from "antd"
import ReportBody, {
    DetailsType,
} from "../../../../components/ReportIterations/partial/ReportBody/ReportBody"
import { ExclamationCircleFilled } from "@ant-design/icons"
import { Audits } from "../../../../components/Audits/Audits"
import { User, VaePackageInput } from "@vaes-dashboard-2/graphql/genql"
import {
    useCreateVaEDetailedReport,
    useOnPackageDownload,
    useRemoveVaEPackage,
    useVaEPackage,
} from "../../../../api/vaeDetailedReport"
import { useNavigate, useParams } from "react-router-dom"
import { useGetProjectInfo } from "../../../../api/project"
import { downloadFile } from "../../../../lib/helpers/general-helpers"
import {
    useProjectPartOriginalData,
    useProjectPartPublishedOriginalData,
} from "../../../../api/report"
import { PackageVersion } from "../../../../components/SelectPackageVersion/SelectPackageVersion"
import { queryClient } from "../../../../api/client/reactQuery"

import { PackageHeader } from "./DetailedPackageHeader"
import { SentToClient } from "./SentToClient"
import { useTranslation } from "react-i18next"
import * as Sentry from "@sentry/react"
import {
    useIsProjectManager,
    useIsVaesEngineeringManager,
} from "../../../../api/user"

const Panel = Collapse.Panel
const { confirm } = Modal
/**
 * private function to show a modal that blocks download a demo projects packages
 */
const showDownloadDemoModal = () => {
    const { t: translate } = useTranslation("translation")
    const t = (key: string) => translate(key, { ns: "translation" })
    // download model to prevent download in demo projects
    Modal.warning({
        icon: <></>,
        title: t("project.downloadReport.downloadPopupTitle"),
        content: t("project.downloadReport.downloadPopupContent"),
    })

    return
}

type ImageType = { url: string; s3key: string }

export const DetailedPackage = (props: {
    data: {
        image?: ImageType
        gifImage?: ImageType
        originalDesignImage?: ImageType
        id: number
        description?: string
        advantages?: string
        details?: DetailsType[]
        name?: string
        savings?: number
        summary?: string
        isPublished: boolean
        package?: ImageType
        isDownloaded?: boolean
        projectPartId?: number
        versions: PackageVersion[]
        parentId?: number
        originalParentId?: number
        createdAt: string
        createdBy?: Partial<User>
        updatedAt?: string
        updatedBy?: Partial<User>
    }
}) => {
    // local state

    const [selectedVersionId, setSelectedVersionId] = useState<number>()

    // react router
    const params = useParams<{ projectId: string }>()
    const navigate = useNavigate()
    // api
    const onDownloadPackage = useOnPackageDownload()
    const projectQuery = useGetProjectInfo(Number(params.projectId))
    const originalMainData = useProjectPartOriginalData(
        Number(props.data.projectPartId)
    )

    const { data: isVaesEngineeringManager } = useIsVaesEngineeringManager()
    const { data: isProjectManager } = useIsProjectManager(
        Number(params.projectId)
    )

    const originalPublishData = useProjectPartPublishedOriginalData(
        "package",
        Number(props.data.projectPartId),
        props.data.id,
        Number(params.projectId)
    )

    const originalData = props.data?.isPublished
        ? originalPublishData
        : originalMainData

    const versionQuery = useVaEPackage(
        Number(params.projectId),
        selectedVersionId
    )
    const createPackage = useCreateVaEDetailedReport()
    const removePackage = useRemoveVaEPackage()

    // version data or the original data from the listing page
    const data = versionQuery.data || props.data

    /**
     * Remove package and refetch data
     */
    const handleRemovePackage = useCallback(() => {
        const remove = () =>
            removePackage.mutate(
                {
                    packageId: Number(data.id),
                },
                {
                    onSuccess: async () => {
                        await queryClient.invalidateQueries([
                            "vae-report",
                            Number(params.projectId),
                            "list",
                        ])
                        await queryClient.invalidateQueries([
                            "vae-report",
                            Number(params.projectId),
                            "package",
                            data.id,
                        ])
                    },
                }
            )

        confirm({
            title: "Do you want to delete this package?",
            icon: <ExclamationCircleFilled style={{ color: "gold" }} />,
            width: 510,
            closable: true,
            centered: true,
            content:
                "By deleting this package all data in it will be deleted as well, Do you want to continue?",
            onOk() {
                remove()
            },
        })
    }, [data.id, params.projectId, removePackage])

    /**
     * create new version from current package
     */
    const createVersionHandler = useCallback(() => {
        if (!data) {
            Sentry.captureMessage(
                "Detailed package data is not available to create a new version"
            )
            return
        }

        const vaePackage: VaePackageInput = {
            name: data.name,
            description: data.description,
            advantages: data.advantages,
            imgKey: data.image?.s3key,
            originalDesignImgKey: data.originalDesignImage?.s3key,
            details: data.details?.map((d) => ({
                subjectId: d.subject?.id,
                value: d.value,
                value2: d.value2,
            })),
            packageKey: data.package?.s3key,
            summary: data.summary,
            savings: data.savings,
            parentId: Number(data.parentId || data.id),
            originalParentId: Number(data.id),
        }

        // create new package
        createPackage.mutate(
            {
                projectId: Number(params.projectId),
                projectPartId: Number(data.projectPartId),
                package: vaePackage,
            },
            {
                onSuccess: (data) => {
                    navigate(`./${data.createVaEPackage?.id}`)
                },
            }
        )
    }, [createPackage, data, navigate, params.projectId])

    /**
     * handle download single package
     */
    const handleDownloadClicked = useCallback(() => {
        if (projectQuery.data?.getProject?.isDemo) {
            showDownloadDemoModal()
            return
        }

        if (data.package?.url) {
            downloadFile(data.package.url)
            // notify
            if (!data.isDownloaded) {
                onDownloadPackage.mutate({
                    packageId: data.id,
                    projectId: Number(params.projectId),
                    all: false,
                })
            }
        }
    }, [
        onDownloadPackage,
        params.projectId,
        projectQuery.data?.getProject?.isDemo,
        data.id,
        data.isDownloaded,
        data.package,
    ])

    /**
     * Parse
     */
    const details = useMemo(() => {
        if (!data.details) return []

        return data.details.map((d) => ({
            ...d,
            value: d.value,
            value2: d.value2,
        }))
    }, [data.details])

    return (
        <Panel
            key={data.id}
            header={
                <PackageHeader
                    packageId={Number(data.id)}
                    name={data.name}
                    isPublished={!!data.isPublished}
                    onDownload={handleDownloadClicked}
                    selectedVersion={selectedVersionId}
                    versions={data.versions}
                    onVersionChange={setSelectedVersionId}
                    onCreateVersion={createVersionHandler}
                    onRemove={handleRemovePackage}
                />
            }
            className="shadow-md px-11 py-9 border border-solid border-gray-150 my-6"
            forceRender={true}
            id={`downloadreport${props.data.id}`}
        >
            <Spin spinning={versionQuery.isLoading || removePackage.isLoading}>
                <ReportBody
                    origenalImg={data.originalDesignImage?.url}
                    veImg={data.image?.url}
                    veGif={data.gifImage?.url}
                    description={data.description}
                    advantage={data.advantages}
                    details={details}
                    originalDetails={originalData.data}
                    totalColor="text-green-500"
                />
                <div className="flex items-center justify-between mt-4">
                    <Audits
                        createdAt={data.createdAt}
                        createdBy={data.createdBy}
                        updatedAt={data.isPublished ? null : data.updatedAt}
                        updatedBy={data.isPublished ? null : data.updatedBy}
                        publishedAt={data.isPublished ? data.updatedAt : null}
                        publishedBy={data.isPublished ? data.updatedBy : null}
                    />

                    {!data.isPublished &&
                        (isProjectManager || isVaesEngineeringManager) && (
                            <SentToClient
                                projectPartId={data.projectPartId as number}
                                packageId={data.id}
                            />
                        )}
                </div>
            </Spin>
        </Panel>
    )
}
