import axios, { AxiosResponse } from "axios"
import { VAES_USER_REMEMBER_ME } from "../../lib/constants"
import { removeLocalStorage } from "../../lib/helpers/localStorage-helpers"
import * as Sentry from "@sentry/react"
import { GraphQLError } from "graphql"
import {
    getAccessToken,
    getPreviewToken,
    getRefreshToken,
    removePreviewToken,
    removeTokens,
    setTokens,
} from "../../lib/helpers/tokens-helpers"
import { GRAPHQL_API_URL, REST_API_URL } from "./config"
import { removeNullValues } from "../../lib/helpers/general-helpers"

// Create a new axios instance with a custom config
export const authedAxios = axios.create({
    baseURL: GRAPHQL_API_URL,
})

// Add Bearer token to all requests
authedAxios.interceptors.request.use(
    (config) => {
        const token = getAccessToken()
        const previewToken = getPreviewToken()

        if (token) {
            // send preview token if exists
            config.headers.Authorization = `Bearer ${previewToken || token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

// memoize refresh token calls vars
let lastCallTime = 0
let lastCall: Promise<unknown>

type RefersResponse = { data: { accessToken: string; refreshToken: string } }

/**
 * refreshes tokens and returns new access token
 * @returns access token
 */
const refreshTokens = async () => {
    // prevent multiple refresh token calls
    if (Date.now() - (lastCallTime ?? 0) < 1000) {
        return lastCall
    }

    lastCallTime = Date.now()
    const refreshToken = getRefreshToken()

    if (refreshToken) {
        lastCall = axios.get(`${REST_API_URL}/refresh-token`, {
            params: { refresh: refreshToken },
        })

        const response = (await lastCall) as RefersResponse
        const { accessToken: access, refreshToken: refresh } = response.data
        // save tokens in local or session storage
        setTokens(access, refresh)

        return access
    } else {
        throw new Error("No refresh token")
    }
}

/**
 * Check it the request is a query request
 * @param response Axios response
 * @returns true if the request is a query request
 */
const isQueryRequest = (response: AxiosResponse) => {
    const configData = JSON.parse(response.config.data)
    return configData.query.includes("query")
}

/**
 * Intercepts all responses and checks for expired token error
 */
authedAxios.interceptors.response.use(async (response) => {
    const errors: GraphQLError[] = response.data.errors
    const config = response.config
    // check if token expired
    if (errors?.some((err) => err.extensions?.code === "FAST_JWT_EXPIRED")) {
        try {
            // refresh tokens
            const token = await refreshTokens()

            // check if the user was in a preview session. If so, remove the preview token and don't retry the request
            if (getPreviewToken()) {
                removePreviewToken()
                return { data: {} }
            }

            config.headers.authorization = `Bearer ${token}`

            // resend the request
            return authedAxios(config)
        } catch {
            // if refresh token fails, redirect to login
            removeTokens()
            removeLocalStorage(VAES_USER_REMEMBER_ME)
            window.location.href = "/login"
        }
    } else if (
        // in case the keys was changed (new deployment, etc), remove tokens and redirect user to the login page
        errors?.some(
            (err) => err.extensions?.code === "FAST_JWT_INVALID_SIGNATURE"
        )
    ) {
        !window.location.href.includes("/login") &&
            (window.location.href = "/login")

        removeTokens()
        removeLocalStorage(VAES_USER_REMEMBER_ME)

        return { data: {} }
    } else if (
        errors?.some((err) => err.extensions?.code === "PREVIEW_NOT_ALLOWED")
    ) {
        // remove preview token and redirect to admin page
        removePreviewToken()
    } else if (
        // in case that there is a partial data for a query request
        // take the data and be silent about the errors
        errors &&
        Object.keys(removeNullValues(response.data.data)).length !== 0 &&
        isQueryRequest(response)
    ) {
        // log errors
        Sentry.captureException(errors)

        return { data: response.data.data }
    } else if (errors) {
        // log unhandled errors
        Sentry.captureException(errors)

        // for mutations, return the data and the errors
        return { data: response.data.data, errors }
    }

    return response.data
})
