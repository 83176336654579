import { useEffect } from "react"
import { useLocation } from "react-router-dom"

/**
 *  A hook that will scroll to top of page on navigation
 */
function useSrollTop() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])
}

export default useSrollTop
