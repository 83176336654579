import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Checkbox, Empty, InputNumber, Select, Space, Typography } from "antd"
import { useProjectPartOriginalData } from "../../../api/report"
import VaesButton from "../../VaesButton/VaesButton"
import { useSearchParams } from "react-router-dom"
import { formatBigNumber } from "../../../lib/helpers/general-helpers"

export interface VaeValue {
    value1?: number | null
    value2?: number | null
}

export interface Row {
    idx: number
    subjectId?: number
    originalValue?: VaeValue
    vaeValue?: VaeValue
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean
    type: "subject" | "veaValue" | "action" | "originalValue"
    record: Row
    index: number
    children: React.ReactNode
    editable: boolean
    onRowChange: (rowIdx: number, updatedRow: Row) => void
    onRemoveRow: (rowIdx: number) => void
    partId: number
}

/**
 * Select subject component
 * This component will work as a form field where value: subjectId
 */
const SelectSubject = (props: {
    value?: number
    onChange?: (subjectId: number, originalValue: VaeValue) => void
    partId: number
}) => {
    // react router
    const [searchParams] = useSearchParams()

    /**
     * get tags from search params
     */
    const tags = useMemo(
        () =>
            searchParams
                .get("tags")
                ?.split(",") // split by comma
                .filter((tag) => !!tag) // remove any empty string
                .map((tag) => +tag), // convert to number
        [searchParams]
    )

    const subjectsQuery = useProjectPartOriginalData(props.partId || -1, tags)

    /**
     * parse all subjects to options for select
     */
    const subjectOptions = useMemo(
        () =>
            subjectsQuery.data?.map((label) => ({
                value: label.subject?.id,
                label: `${label.subject?.name} (${label.subject?.unit?.value})`,
            })),
        [subjectsQuery.data]
    )

    /**
     * update selected subject and original value
     */
    const changeHandler = useCallback(
        (subjectId: number) => {
            const subject = subjectsQuery.data?.find(
                (item) => item.subject?.id === subjectId
            )

            props.onChange?.(subjectId, {
                value1: subject?.value,
                value2: subject?.value2,
            })
        },
        [props, subjectsQuery.data]
    )

    return (
        <div>
            <Select
                value={props.value}
                options={subjectOptions}
                onChange={changeHandler}
                loading={subjectsQuery.isLoading}
                showSearch
                filterOption={(input, option) => {
                    if (option?.label) {
                        return (
                            option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        )
                    }

                    return false
                }}
                placeholder="Select a subject"
                notFoundContent={
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="Please make sure you added original design data"
                    />
                }
            />
        </div>
    )
}

/**
 * Input field for vae value where it had the option to make it a range
 */
const InputValue = (props: {
    vaeValue?: VaeValue
    onChange?: (vaeValue: VaeValue) => void
}) => {
    const [isRangeChecked, setIsRangeChecked] = useState<boolean>()

    /**
     * Make sure to toggle range checkbox true if value2 is not null
     */
    useEffect(() => {
        if (props.vaeValue?.value2 != null) {
            setIsRangeChecked(true)
        }
    }, [props.vaeValue?.value2])

    return (
        <Space>
            {/* Main input field */}
            <InputNumber
                value={props.vaeValue?.value1}
                placeholder="number"
                onChange={(value) =>
                    props.onChange?.({
                        value1: value,
                        value2: props.vaeValue?.value2,
                    })
                }
            />
            {/* Render "to" input filed if the checkbox is checked */}
            {isRangeChecked && (
                <>
                    <span className="mx-2">To</span>
                    <InputNumber
                        placeholder="number"
                        value={props.vaeValue?.value2}
                        onChange={(value) =>
                            props.onChange?.({
                                value1: props.vaeValue?.value1,
                                value2: value,
                            })
                        }
                    />
                </>
            )}

            {/* is range checkbox  */}
            <Space>
                <Checkbox
                    checked={isRangeChecked}
                    onChange={(e) => {
                        const checked = e.target.checked

                        // remove value2 if checkbox is unchecked
                        if (!checked) {
                            props.onChange?.({
                                value1: props.vaeValue?.value1,
                                value2: null,
                            })
                        }

                        setIsRangeChecked(e.target.checked)
                    }}
                />
                <span>make range</span>
            </Space>
        </Space>
    )
}

/**
 * Main component
 */
export const EditableCell: React.FC<EditableCellProps> = (props) => {
    const {
        type,
        record,
        children,
        editable,
        onRowChange,
        onRemoveRow,
        partId,
        ...restProps
    } = props

    // render title as is, like original value
    if (!editable) {
        return (
            <td {...restProps} className="!py-[6px] px-3">
                {children}
            </td>
        )
    }

    return (
        <td {...restProps} className="!p-[6px]">
            {type === "subject" && (
                <SelectSubject
                    partId={partId}
                    value={record.subjectId}
                    onChange={(id: number, originalValue?: VaeValue) =>
                        onRowChange(record.idx, {
                            ...record,
                            subjectId: id,
                            originalValue,
                        })
                    }
                />
            )}

            {type === "veaValue" && (
                <InputValue
                    vaeValue={record.vaeValue}
                    onChange={(vaeValue) =>
                        onRowChange(record.idx, { ...record, vaeValue })
                    }
                />
            )}

            {type === "originalValue" && (
                <Typography.Text className="text-base">
                    {formatBigNumber(record.originalValue?.value1)}
                    {
                        // render "to" if value2 is not null
                        record.originalValue?.value2 && (
                            <>
                                <span className="mx-2">-</span>
                                {formatBigNumber(record.originalValue?.value2)}
                            </>
                        )
                    }
                </Typography.Text>
            )}

            {/* Action is aether add row or remove row, first record is add new row */}
            {type === "action" && (
                <VaesButton
                    type="link"
                    danger
                    disabled={
                        record.subjectId == null &&
                        record.vaeValue?.value1 == null &&
                        record.vaeValue?.value2 == null &&
                        record.idx == 0
                    }
                    onClick={() => {
                        onRemoveRow(record.idx)
                    }}
                >
                    {"Remove"}
                </VaesButton>
            )}
        </td>
    )
}
