import React from "react"
import ReactDOM from "react-dom/client"
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import "react-i18next"
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/browser"
import { queryClient } from "./api/client/reactQuery"
import App from "./App"
// import * as dayjs from "dayjs"
// import * as localizedFormat from "dayjs/plugin/localizedFormat"
import dayjs from "dayjs/esm/index.js"
import localizedFormat from "dayjs/esm/plugin/localizedFormat"

import "antd/dist/reset.css"
import "./index.scss"
import mixpanel from "mixpanel-browser"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { getPageUrl } from "./lib/helpers/general-helpers"
library.add(fas)

// init dayjs
dayjs.extend(localizedFormat)

if (import.meta.env.VITE_SST_STAGE == "prod") {
    Sentry.init({
        dsn: "https://2486dad261c943dc95ac9d060e290d6d@o1234535.ingest.sentry.io/4504508716941312",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }),
            new Sentry.Replay(),
        ],
        environment: "production",
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
} else if (import.meta.env.VITE_SST_STAGE == "dev") {
    Sentry.init({
        dsn: "https://2486dad261c943dc95ac9d060e290d6d@o1234535.ingest.sentry.io/4504508716941312",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }),
            new Sentry.Replay(),
        ],
        environment: "development",
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}
mixpanel.init("44b90451297844f56999dc38ebff0764", { debug: true })
const currentWebSite = getPageUrl()
mixpanel.track("Sign up", { CurrentWebSite: currentWebSite })

const rootElement = document.getElementById("root")
ReactDOM.createRoot(rootElement as HTMLElement).render(
    // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
    // </React.StrictMode>
)
