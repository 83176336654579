import React, { useCallback, useMemo } from "react"
import { Empty, Modal, Tag, Typography } from "antd"
import VaesButton from "../../../../components/VaesButton/VaesButton"
import {
    downloadFile,
    getFileNameFromS3Key,
} from "../../../../lib/helpers/general-helpers"
import {
    useProjectUnderstandingInfo,
    useSendUnderstandingDoc,
    useUpdateUnderstandingDoc,
} from "../../../../api/project"
import { queryClient } from "../../../../api/client/reactQuery"
import UploadAsset from "../../../../components/UploadAsset/UploadAsset"
import {
    DownloadOutlined,
    HistoryOutlined,
    LoadingOutlined,
} from "@ant-design/icons"
import { S3FileType } from "@vaes-dashboard-2/graphql/types"
import {
    useIsProjectManager,
    useIsVaesEngineeringManager,
} from "../../../../api/user"

/**
 * A model to show all docs except the latest one
 */
const DocsHistoryModal = (props: {
    docs?: S3FileType[]
    hasDraft: boolean
    isModalVisible: boolean
    handleOk: () => void
    handleCancel: () => void
}) => {
    // handlers
    const handleOk = useCallback(() => {
        props.handleOk()
    }, [props.handleOk])

    const handleCancel = useCallback(() => {
        props.handleCancel()
    }, [props.handleCancel])

    const docsList = useMemo(() => {
        return props.docs
            ?.map((doc, idx) => {
                if (!props.hasDraft && idx === 0) return null
                return (
                    <div key={doc.s3key} className="flex justify-between">
                        <Typography.Text type="secondary">
                            {getFileNameFromS3Key(doc.s3key || "")}
                        </Typography.Text>
                        <VaesButton
                            mpBtnName="download doc"
                            type="link"
                            onClick={() => downloadFile(doc.url || "")}
                            size="small"
                            icon={
                                <DownloadOutlined className="text-gray-500 hover:text-blue-400" />
                            }
                        />
                    </div>
                )
            })
            .filter((doc) => doc !== null) // remove null values
    }, [props.docs, props.hasDraft])

    // render
    return (
        <div>
            <Modal
                title="Documents history"
                open={props.isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <div className="flex flex-col pt-5 pb-3">
                    {docsList}

                    {!docsList?.length && (
                        <Empty description="No files found" />
                    )}
                </div>
            </Modal>
        </div>
    )
}

/**
 * This component will display all docs in a modal with a download button
 * if there is is no draft doc, we will show all docs except the latest one
 */
const DocsHistory = (props: {
    docs?: S3FileType[]
    hasDraft: boolean
    isLoading: boolean
}) => {
    // state
    const [isModalVisible, setIsModalVisible] = React.useState(false)

    // handlers
    const showModal = useCallback(() => {
        setIsModalVisible(true)
    }, [])

    const handleClose = useCallback(() => {
        setIsModalVisible(false)
    }, [])

    // render
    return (
        <>
            <VaesButton
                mpBtnName="show documents history"
                type="link"
                onClick={showModal}
            >
                <span className="text-gray-500 hover:text-blue-400 text-sm">
                    {props.isLoading && isModalVisible ? (
                        <LoadingOutlined />
                    ) : (
                        <HistoryOutlined />
                    )}{" "}
                    Show history
                </span>
            </VaesButton>
            <DocsHistoryModal
                docs={props.docs}
                hasDraft={props.hasDraft}
                isModalVisible={isModalVisible && !props.isLoading}
                handleOk={handleClose}
                handleCancel={handleClose}
            />
        </>
    )
}

/**
 * Main component, Upload, publish and send to client the project understanding doc
 */
export const UnderstandingDocUploader = (props: { projectId: number }) => {
    // api
    const understandingInfoQuery = useProjectUnderstandingInfo(
        Number(props.projectId)
    )
    const updateDoc = useUpdateUnderstandingDoc()
    const sendDoc = useSendUnderstandingDoc()
    const { data: isVaesEngineeringManager } = useIsVaesEngineeringManager()
    const { data: isProjectManager } = useIsProjectManager(
        Number(props.projectId)
    )

    /**
     * send to client handler
     */
    const sendToClientHandler = useCallback(() => {
        sendDoc.mutate(
            { projectId: props.projectId },
            {
                onSuccess: () =>
                    queryClient.invalidateQueries([
                        "project",
                        props.projectId,
                        "understanding-info",
                    ]),
            }
        )
    }, [props.projectId])

    /**
     * save file in the db
     */
    const updateDocHandler = useCallback(
        (doc: S3FileType) => {
            updateDoc.mutate(
                { projectId: props.projectId, docS3key: doc.s3key || "" },
                {
                    onSuccess: () =>
                        queryClient.invalidateQueries([
                            "project",
                            props.projectId,
                            "understanding-info",
                        ]),
                }
            )
        },
        [updateDoc]
    )

    /**
     * Latest doc file is either the draft doc or the latest published doc
     */
    const latestDoc = useMemo(
        () =>
            understandingInfoQuery.data?.draftUnderstandingDoc ||
            understandingInfoQuery.data?.publishedUnderstandingDocs?.[0],
        [understandingInfoQuery.data]
    )

    return (
        <div className="border border-gray-150 border-solid px-9 py-4">
            <Typography.Paragraph className="!mb-4 text-base font-robotoMedium">
                Project understanding document{" "}
                <Typography.Text type="secondary">
                    {" "}
                    (Format: Word, PPT, PDF){" "}
                    <DocsHistory
                        docs={
                            understandingInfoQuery.data
                                ?.publishedUnderstandingDocs
                        }
                        hasDraft={
                            !!understandingInfoQuery.data?.draftUnderstandingDoc
                        }
                        isLoading={understandingInfoQuery.isLoading}
                    />
                </Typography.Text>
            </Typography.Paragraph>
            <div className="flex justify-between">
                <UploadAsset
                    onChange={updateDocHandler}
                    defaultValue={latestDoc}
                    accept="application/pdf, application/msword, .ppt"
                />

                {/* Show send to client button if there is a draft file */}
                {understandingInfoQuery.data?.draftUnderstandingDoc
                    ? (isVaesEngineeringManager || isProjectManager) && (
                          <VaesButton
                              onClick={sendToClientHandler}
                              loading={sendDoc.isLoading}
                          >
                              Send to client
                          </VaesButton>
                      )
                    : latestDoc &&
                      !updateDoc.isLoading && (
                          <div>
                              <Tag color="green" className="px-5">
                                  Sent
                              </Tag>
                          </div>
                      )}
            </div>
        </div>
    )
}
