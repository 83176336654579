import { createClient } from "@vaes-dashboard-2/graphql/genql"
import { authedAxios } from "./axios"

export const glClient = createClient({
    fetcher: (operation) => {
        return authedAxios.post("", operation)
    },
})

/**
 * This function will create a new graphql client with a cancelable request
 * This sill in progress since we need to support cancelation on our backend
 */
export const createCancelableGlClient = () => {
    const controller = new AbortController()
    const signal = controller.signal

    const client = createClient({
        fetcher: (operation) => {
            return authedAxios.post("", operation, { signal })
        },
    })

    return { client, controller }
}
