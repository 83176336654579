export type VaesUserRoles =
    | "Client"
    | "VaesEngineer"
    | "ClientAdmin"
    | "VaesEngineeringManager"
    | "Admin"
    | "ClientOwner"

export const isVaesUserRole = (value: string): value is VaesUserRoles => {
    return (
        value === "Client" ||
        value === "VaesEngineer" ||
        value === "ClientAdmin" ||
        value === "VaesEngineeringManager" ||
        value === "Admin" ||
        value === "ClientOwner"
    )
}

/**
 * Check if the givin role is a VaesEngineer role; a role that is related to a Engineers
 */
export const isEngineer = (value: string): value is VaesUserRoles => {
    return value === ("VaesEngineer" as VaesUserRoles)
}

/**
 * Check if the givin role is a client role; a role that is related to a client
 */
export const isClient = (value: string): value is VaesUserRoles => {
    return value === ("Client" as VaesUserRoles)
}

/**
 * Check if the givin role is a ClientAdmin role; a role that is related to a Client Admin
 */
export const isClientAdmin = (value: string): value is VaesUserRoles => {
    return (
        value === ("ClientAdmin" as VaesUserRoles) ||
        value === ("ClientOwner" as VaesUserRoles)
    )
}
/**
 * Check if the givin role is a VaesEngineeringManager role; a role that is related to a Vaes Managers
 */
export const isAdmin = (value: string): value is VaesUserRoles => {
    return value === ("VaesEngineeringManager" as VaesUserRoles)
}

/**
 * Check if the givin role is a any client role; a role that is related to all clients roles
 */
export const isClientOrClientAdmin = (
    value: string
): value is VaesUserRoles => {
    return (
        value === "Client" || value === "ClientAdmin" || value === "ClientOwner"
    )
}

/**
 * Check if the givin role is a vaes mamber; a role that is related to a all vaes roles
 */
export const isEngineerOrAdmin = (value: string): value is VaesUserRoles => {
    return (
        value === "VaesEngineer" ||
        value === "VaesEngineeringManager" ||
        value === "Admin"
    )
}
