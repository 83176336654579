import { Col, Select } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useLookupOfType } from "../../../api/lookups"
import type { CustomTagProps } from "rc-select/lib/BaseSelect"
import ProjectTag from "../../ProjectTag/ProjectTag"

const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event?.preventDefault()
        event?.stopPropagation()
    }
    return (
        <div className="mr-1">
            <ProjectTag
                tagStatusKey={value}
                tagStatusValue={label}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
            ></ProjectTag>
        </div>
    )
}

const MissingFilesTagFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    /** to get the searched status from URL */
    const querySearchTag = useMemo(
        () =>
            searchParams
                .get("status")
                ?.split(",") // sprlit by comma
                .filter((tag) => !!tag), // convert to number
        [searchParams]
    )

    const [selectedItems, setSelectedItems] = useState<string[] | undefined>(
        querySearchTag
    )
    const missingFilesStatues = useLookupOfType(["missing_file_status"])

    /**Define the select DD value and label */
    const filteredOptions = useMemo(() => {
        return missingFilesStatues.data?.getLookupsOfType
            ?.filter((o) =>
                selectedItems ? !selectedItems.includes(o.key) : true
            )
            .map((item) => ({
                value: item.key,
                label: item.value,
            }))
    }, [missingFilesStatues.data?.getLookupsOfType, selectedItems])

    // Filter `option.label` match the user type `input`
    const filterOption = (
        input: string,
        option?: { label: string; value: string }
    ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

    /**
     * update search params on select from DD
     */

    useEffect(() => {
        // to add the tag filter to the url
        if (selectedItems) {
            setSearchParams((prev) => {
                return {
                    ...Object.fromEntries(prev),
                    status: selectedItems.join(",") || "",
                    page: "1", // reset page on new search
                }
            })
        }
    }, [selectedItems, setSearchParams])

    return (
        <Col span={24} className="flex justify-start items-center">
            <span style={{ marginRight: 8 }}>Filter view by:</span>
            <Select
                mode="multiple"
                placeholder="+ Add filter"
                tagRender={tagRender}
                onChange={setSelectedItems}
                style={{ minWidth: "20%" }}
                options={filteredOptions}
                filterOption={filterOption}
                defaultValue={querySearchTag}
                optionLabelProp="label"
            />
        </Col>
    )
}
export default MissingFilesTagFilter
