import React, { useMemo } from "react"
import { Input, Form, Select, notification, Button, InputNumber } from "antd"
import { useCreateSubject } from "../../../../api/report"
import { useForm } from "antd/es/form/Form"
import { useLookupOfType } from "../../../../api/lookups"
import VaesButton from "../../../../components/VaesButton/VaesButton"
import { queryClient } from "../../../../api/client/reactQuery"
import { SelectTags } from "./SelectTags"

export type SubjectTagFormType = {
    name: string
    type: string
    tags: number[]
    priority: number
}

export const CreateSubjectForm = (props: {
    onOpenOrganizeTags: () => void
    onSuccess: () => void
}) => {
    const create = useCreateSubject()

    const [form] = useForm()
    const [notify, contextHolder] = notification.useNotification()
    // fetch all types
    const types = useLookupOfType(["subject_type"])

    const typesList = useMemo(() => {
        return types.data?.getLookupsOfType?.map((t) => ({
            label: t.value,
            value: t.key,
        }))
    }, [types.data])

    /**
     * Submit the form and create a new report
     * @param values
     */
    const onFinish = (values: {
        name: string
        type: string
        tags: number[]
        priority: number
    }) => {
        create.mutate(
            {
                name: values.name,
                typeKey: values.type,
                tags: values.tags,
                priority: values.priority,
            },
            {
                onSuccess: () => {
                    form.resetFields()
                    notify.success({
                        message: "Subject created",
                        description: "Subject created successfully",
                    })
                    // refetch all subjects
                    queryClient.invalidateQueries(["report", "subjects"])
                    props.onSuccess()
                },
                onError: (error) => {
                    notify.error({
                        message: "Subject created Failed",
                        description: error.message,
                    })
                },
            }
        )
    }

    return (
        <Form
            form={form}
            name="normal_login"
            className="login-form"
            onFinish={onFinish}
            layout="vertical"
            validateTrigger="onBlur"
        >
            {contextHolder}
            <Form.Item
                label="Title"
                name="name"
                rules={[{ required: true, message: "Please enter title" }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Unit"
                name="type"
                rules={[{ required: true, message: "Please enter unit" }]}
            >
                <Select options={typesList} />
            </Form.Item>
            <Form.Item
                label="Priority"
                name="priority"
                rules={[{ required: true, message: "Please enter priority" }]}
            >
                <InputNumber
                    type={"number"}
                    min={0}
                    controls={false}
                    className="w-full"
                />
            </Form.Item>
            <Form.Item label="Assign tags" name="tags">
                <SelectTags />
            </Form.Item>

            <Form.Item>
                <VaesButton
                    type="primary"
                    htmlType="submit"
                    loading={create.isLoading}
                    block
                    mpBtnName="create subject"
                >
                    Create title
                </VaesButton>
            </Form.Item>

            <Button
                className="-mt-5 p-0"
                type="link"
                onClick={props.onOpenOrganizeTags}
            >
                Add/edit titles tags
            </Button>
        </Form>
    )
}
