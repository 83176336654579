import React from "react"
import { createBrowserRouter } from "react-router-dom"
import { queryClient } from "../api/client/reactQuery"
import AuthRoute, {
    loader as AuthLoader,
} from "../components/AuthRoute/AuthRoute"
import EngineerRoute, {
    loader as EngineerLoader,
} from "../components/EngineerRoute/EngineerRoute"
import AccountActivation, {
    ActivateAccountLoader,
} from "../components/ActivateAccount"
import CompanyRegister, {
    RegisterLoader,
} from "../components/Register/Register"
import Home, { homeLoader } from "../pages/Home"
import PublicRoute, {
    loader as registrationLoader,
} from "../components/PublicRoute/PublicRoute"
import Signin from "../pages/Signin"
import UploadFilesProvider from "./uploadContext"
import Layout, { ContentOnly } from "../components/Layout"
import AddNewProject from "../components/AddNewProject/AddNewProject"
import UploadOnProject from "../components/UploadOnProject/UploadOnProject"

import Project, { projectInfoLoader } from "../components/Project/Project"
import RootBoundary from "../components/RootBoundary/RootBoundary"
import * as Sentry from "@sentry/react"
import ProjectUplload from "../components/ProjectUplload"
import ProjectReports from "../components/ProjectReports/ProjectReports"
import ReportsList from "../components/ReportsList/ReportsList"
import ReportPage from "../pages/ReportPage"
import AdminCreateClient, {
    createClientLoader,
} from "../pages/Admin/CreateClient"
import CompanyProfileSettings from "../pages/CompanyProfileSettings/CompanyProfileSettings"
import { reportDataLoader } from "../components/ReportsHomePage/ReportsHomePage"
import ProfileSetting from "../pages/UserProfileSettings/ProfileSetting"
import Static from "../pages/Static/Static"
import SubjectPage from "../pages/Admin/Subject/Subject"
import OnboardingScreen, {
    onboardingLoader,
} from "../pages/OnboardingScreen/OnboardingScreen"
import ReportDownload from "../pages/ReportDownload/ReportDownload"
import i18next from "i18next"
import AdminMissingFiles from "../pages/Admin/AdminMissingFiles/AdminMissingFiles"
import ProjectFilesList, {
    projectFilesListLoader,
} from "../pages/Admin/ProjectFilesList/ProjectFilesList"
import { AdminLayout, AdminProjectLayout } from "../components/Layout"
import AdminHome from "../pages/Admin/AdminHome/AdminHome"
import { AdminReportLayout } from "../components/Layout/AdminLayout/AdminLayout"
import ProjectUnderstanding from "../pages/Admin/ProjectUnderstanding/ProjectUnderstanding"
import ProjectSubjectsList from "../pages/Admin/ProjectSubjectsList/ProjectSubjectsList"
import OriginalDesignData from "../pages/Admin/OriginalDesignData/OriginalDesignData"
import AdminReportPage from "../pages/Admin/Report/Report"
import { VaeDetailedPackage } from "../pages/Admin/VaEDetailedPackage/VaEDetailedPackage"
import VaEDetailedPackageForm, {
    loader as vaeDetailedPackageFormLoader,
} from "../pages/Admin/VaEDetailedPackageForm/VaEDetailedPakckageForm"
import ProjectInfo from "../pages/ProjectInfo/ProjectInfo"
import { LayoutNavbarOnly } from "../components/Layout/Layout"
const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter)

/**

 *  NOTE: Bredcrumb is handled dynamicly by parsing match pathes, you can override the match path by providing path function. ex:

 *  crumb: () => [
                {
                    name: "breadCrumb name1",
                    path: () =>`path`,
                },
                {
                    name: "breadCrumb name2",
                    path:  () =>`path`,
                },
                ],

 */

const router = sentryCreateBrowserRouter([
    {
        element: <AuthRoute />,
        loader: AuthLoader,
        errorElement: <RootBoundary />,
        children: [
            {
                path: "/",
                element: (
                    <UploadFilesProvider>
                        <Layout />
                    </UploadFilesProvider>
                ),
                children: [
                    {
                        path: "/",
                        element: <Home />,
                        loader: homeLoader(),
                    },
                    {
                        path: "/new-project",
                        element: <AddNewProject />,
                    },
                    {
                        path: "/company-profile",
                        element: <CompanyProfileSettings />,
                    },
                    {
                        path: "/user-profile",
                        element: <ProfileSetting />,
                    },
                    {
                        path: "/assign-files/:sessionId",
                        element: <UploadOnProject />,
                    },
                    {
                        path: "/assign-links/:linkId",
                        element: <UploadOnProject />,
                    },
                    {
                        element: <Project />,
                        children: [
                            {
                                path: "/project/upload/:companyId/:projectId",
                                element: <ProjectUplload />,
                            },
                            {
                                path: "/project/report/:companyId/:projectId",
                                element: <ProjectReports />,
                                loader: reportDataLoader,
                                children: [
                                    {
                                        path: "/project/report/:companyId/:projectId/",
                                        element: <ReportsList />,
                                    },
                                    {
                                        path: "/project/report/:companyId/:projectId/:pageId",
                                        element: <ReportPage />,
                                    },
                                ],
                            },
                            {
                                path: "/project/download/:companyId/:projectId",
                                element: <ReportDownload />,
                            },
                        ],
                    },
                    {
                        path: "/project/info/:companyId/:projectId",
                        element: <ProjectInfo />,
                        loader: projectInfoLoader,
                    },
                    {
                        path: "/what-to-upload",
                        element: (
                            <Static
                                pageId="65774f0518244c86a417fce4c006a6da"
                                pageName={i18next.t(
                                    "whatProjectToUpload.whatProjectsToUpload",
                                    {
                                        ns: "translation",
                                    }
                                )}
                            />
                        ),
                    },
                    {
                        path: "/FAQs",
                        element: (
                            <Static
                                pageId="49c519e8994a4076b6d3818e1e37b92f"
                                pageName={i18next.t("common.FAQs", {
                                    ns: "translation",
                                })}
                            />
                        ),
                    },
                    {
                        path: "/dashboard-permissions",
                        element: (
                            <Static
                                pageId="a2a7e412b5ae4a71b89d23f577677f04"
                                pageName={i18next.t("common.permissions", {
                                    ns: "translation",
                                })}
                                showLeftArrow={false}
                            />
                        ),
                    },
                    {
                        path: "/dashboard-vaes-members-permissions",
                        element: (
                            <Static
                                pageId="fe663236984e4fc6b4f97fa05db7fec0"
                                pageName={i18next.t("common.vaespermissions", {
                                    ns: "translation",
                                })}
                                showLeftArrow={false}
                            />
                        ),
                    },
                ],
            },
            {
                path: "user",
                element: <LayoutNavbarOnly />,
                children: [
                    {
                        path: "register-company",
                        element: <CompanyRegister />,
                        loader: RegisterLoader(queryClient),
                    },
                    {
                        path: "activate",
                        element: <AccountActivation />,
                        loader: ActivateAccountLoader,
                    },
                ],
            },
            {
                element: <EngineerRoute />,
                loader: EngineerLoader,
                children: [
                    {
                        path: "admin",
                        element: <AdminLayout />,
                        handle: {
                            crumb: () => "Home",
                        },
                        children: [
                            {
                                path: "",
                                element: <AdminHome />,
                            },
                            {
                                path: "new-project",
                                element: <AddNewProject />,
                                handle: {
                                    crumb: () => "Create new project",
                                },
                            },
                            {
                                path: ":companyId/new-project",
                                element: <AddNewProject />,
                                handle: {
                                    crumb: () => "Create new project",
                                },
                            },
                            {
                                path: "create-client",
                                element: <AdminCreateClient />,
                                loader: createClientLoader(queryClient),
                                handle: {
                                    crumb: () => "Create new client",
                                },
                            },
                            {
                                path: "master-label-titles",
                                element: <SubjectPage />,
                                handle: {
                                    crumb: () => "Master label titles",
                                },
                            },
                            {
                                path: "company-profile/:companyId",
                                element: <CompanyProfileSettings />,
                            },
                        ],
                    },
                    {
                        path: "admin",
                        handle: {
                            crumb: () => "Home",
                        },
                        children: [
                            {
                                // Admin portal project & report pages
                                path: "project/:projectId",
                                element: <AdminProjectLayout bg="white" />,
                                loader: projectInfoLoader,
                                handle: {
                                    crumb: (
                                        data: Awaited<
                                            ReturnType<typeof projectInfoLoader>
                                        >
                                    ) => data?.getProject?.name,
                                    isDisabled: true,
                                },
                                children: [
                                    {
                                        path: "files",
                                        element: (
                                            <UploadFilesProvider>
                                                <ProjectFilesList />
                                            </UploadFilesProvider>
                                        ),
                                        loader: projectFilesListLoader,
                                        handle: {
                                            crumb: () => "Files",
                                        },
                                    },
                                    {
                                        path: "project-info",
                                        element: <ProjectInfo />,
                                        loader: projectInfoLoader,
                                        handle: {
                                            crumb: () => "project-info",
                                        },
                                    },
                                    {
                                        path: "missing-files",
                                        element: <AdminMissingFiles />,
                                        handle: {
                                            crumb: () => [
                                                {
                                                    name: "Files",
                                                    path: (data: {
                                                        projectId: number
                                                    }) =>
                                                        `/admin/project/${data.projectId}/files`,
                                                },
                                                {
                                                    name: "Missing Files",
                                                    path: (data: {
                                                        projectId: number
                                                    }) =>
                                                        `/admin/project/${data.projectId}/missing-files`,
                                                },
                                            ],
                                        },
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        path: "admin",
                        handle: {
                            crumb: () => "Home",
                        },
                        children: [
                            {
                                // Admin report pages
                                path: "project/:projectId",
                                loader: projectInfoLoader,
                                handle: {
                                    crumb: (
                                        data: Awaited<
                                            ReturnType<typeof projectInfoLoader>
                                        >
                                    ) => data?.getProject?.name,
                                    isDisabled: true,
                                },
                                children: [
                                    {
                                        path: "report",
                                        element: <AdminReportLayout />,

                                        children: [
                                            {
                                                path: "understanding",
                                                element: (
                                                    <ProjectUnderstanding />
                                                ),
                                                handle: {
                                                    crumb: () =>
                                                        "Project Understanding",
                                                },
                                            },
                                            {
                                                path: "original-design-data",
                                                element: <OriginalDesignData />,
                                                handle: {
                                                    crumb: () =>
                                                        "Original design data",
                                                },
                                            },
                                            {
                                                path: "project-label-list",
                                                element: (
                                                    <ProjectSubjectsList />
                                                ),
                                                handle: {
                                                    crumb: () =>
                                                        "Project label list",
                                                },
                                            },
                                            {
                                                path: "optimization-report",
                                            },
                                            {
                                                path: "optimization-report/:pageId",
                                                element: <AdminReportPage />,
                                                handle: {
                                                    crumb: () =>
                                                        "Optimization report",
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        element: (
                                            <AdminProjectLayout bg="white" />
                                        ),
                                        children: [
                                            {
                                                path: "detailed-packages",
                                                element: <VaeDetailedPackage />,
                                                handle: {
                                                    crumb: () =>
                                                        "VaE detailed packages",
                                                },
                                            },
                                            {
                                                path: "detailed-packages/:packageId",
                                                element: (
                                                    <VaEDetailedPackageForm />
                                                ),
                                                loader: vaeDetailedPackageFormLoader,
                                                handle: {
                                                    crumb: () => "New Package", // package name
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                element: <ContentOnly centred />,
                children: [
                    {
                        path: "onboarding",
                        element: <OnboardingScreen />,
                        loader: onboardingLoader,
                    },
                ],
            },
        ],
    },

    {
        element: <PublicRoute />,
        loader: registrationLoader,
        errorElement: <RootBoundary />,
        children: [
            {
                path: "login",
                element: <Signin />,
                errorElement: <RootBoundary />,
            },
        ],
    },
    {
        path: "privacyPolicy",
        element: (
            <Static
                pageId="11989a5ddd8b4790a2d48101d55d0041"
                pageName={i18next.t("common.privacyPolicy", {
                    ns: "translation",
                })}
                showLeftArrow={false}
                showLogo={true}
            />
        ),
    },
    {
        path: "termsOfUse",
        element: (
            <Static
                pageId="7ec4503d9c104ae3b65f2c6236e012d7"
                pageName={i18next.t("common.termsOfUse", {
                    ns: "translation",
                })}
                showLeftArrow={false}
                showLogo={true}
            />
        ),
    },
])

export default router
