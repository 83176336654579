import React, {
    KeyboardEvent,
    MouseEvent,
    useCallback,
    useMemo,
    useState,
} from "react"
import { Divider, Input, Space } from "antd"
import VaesButton from "../../../../components/VaesButton/VaesButton"
import {
    DragDropContext,
    Droppable,
    Draggable,
    OnDragEndResponder,
} from "react-beautiful-dnd"
import classNames from "classnames"
import { ID } from "@vaes-dashboard-2/graphql"

export interface ProjectPart {
    id: ID
    name: string
    isArchived: boolean
    isNew?: boolean
    isDeleted?: boolean
}

interface PartUpdatable {
    name?: string
    isArchived?: boolean
    isNew?: boolean
    isDeleted?: boolean
}

const PartItem = (props: {
    part: ProjectPart
    onUpdate: (id: ID, data: PartUpdatable) => void
}) => {
    return (
        <li
            key={props.part.id}
            className={classNames(
                "flex justify-between items-center px-4 py-2",
                {
                    "bg-gray-100": props.part.isArchived,
                }
            )}
        >
            <Input
                value={props.part.name}
                className={classNames("max-w-xl font-robotoMedium", {
                    "bg-gray-100 text-gray-500": props.part.isArchived,
                })}
                onChange={(evt) =>
                    props.onUpdate(props.part.id, { name: evt.target.value })
                }
            />

            <Space className="pl-3">
                <VaesButton
                    mpBtnName="remove new part"
                    type="text"
                    onClick={() =>
                        props.onUpdate(props.part.id, { isDeleted: true })
                    }
                >
                    Delete
                </VaesButton>

                <VaesButton
                    mpBtnName="archive project part"
                    danger={!props.part.isArchived}
                    onClick={() =>
                        props.onUpdate(props.part.id, {
                            isArchived: !props.part.isArchived,
                        })
                    }
                >
                    {!props.part.isArchived ? "Archive" : "Unarchive"}
                </VaesButton>
            </Space>
        </li>
    )
}

/**
 * A dynamic list of input string fields to add parts
 */
export const PartsList = (props: {
    onChange?: (value: ProjectPart[]) => void
    value?: ProjectPart[]
}) => {
    // local state
    const [newPartName, setNewPartName] = useState<string>("")

    const onDragEnd = useCallback<OnDragEndResponder>(
        (result) => {
            if (!result.destination) {
                return
            }

            const parts = Array.from(props.value || [])
            const [reorderedPart] = parts.splice(result.source.index, 1)
            parts.splice(result.destination.index, 0, reorderedPart)

            props.onChange?.(parts)
        },
        [props]
    )

    /**
     *
     */
    const AddPartHandler = useCallback(
        (evt: KeyboardEvent<HTMLInputElement> | MouseEvent<HTMLElement>) => {
            if (evt) {
                evt.stopPropagation()
                evt.preventDefault()
            }

            props.onChange?.([
                ...(props.value || []),
                {
                    id: crypto.randomUUID(),
                    name: newPartName,
                    isArchived: false,
                    isNew: true,
                },
            ])

            setNewPartName("")
        },
        [props, newPartName]
    )

    /**
     * update part info, including delete as isDeleted flag
     */
    const updatePartHandler = useCallback(
        (id: ID, data: PartUpdatable) => {
            const parts = props.value || []
            const newPartsList = parts.map((part) => {
                if (part.id === id) {
                    return {
                        ...part,
                        ...data,
                    }
                }
                return part
            })

            props.onChange?.(newPartsList)
        },
        [props]
    )

    /**
     * parse parts
     */
    const partsItems = useMemo(() => {
        const parts = props.value || []

        return parts
            .filter((part) => !part.isDeleted) // remove deleted from display, anyway they will be sent in the api
            .map((part, index) => {
                return (
                    <Draggable
                        draggableId={part.id.toString()}
                        key={part.id}
                        index={index}
                    >
                        {(provided) => (
                            <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <Divider className="my-0" />
                                <PartItem
                                    part={part}
                                    onUpdate={updatePartHandler}
                                />
                            </li>
                        )}
                    </Draggable>
                )
            })
    }, [props.value])

    return (
        <div>
            <div className="flex items-center gap-3 mb-2">
                <Input
                    className="ml-4  max-w-sm"
                    placeholder="+ Add project part name"
                    value={newPartName}
                    onChange={(evt) => setNewPartName(evt.target.value)}
                    onPressEnter={AddPartHandler}
                />
                <VaesButton
                    mpBtnName="add-new-part-draft"
                    onClick={AddPartHandler}
                >
                    Add
                </VaesButton>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="parts">
                    {(provided) => (
                        <ul
                            className="p-0 list-none"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {partsItems}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )
}
