import React, { useEffect, useMemo, useState, useCallback } from "react"
import { Input, Form, Select, notification, InputNumber } from "antd"
import { useAllSubjects, useUpdateSubject } from "../../../../api/report"
import { useForm } from "antd/es/form/Form"
import { useLookupOfType } from "../../../../api/lookups"
import VaesButton from "../../../../components/VaesButton/VaesButton"
import { queryClient } from "../../../../api/client/reactQuery"
import { SelectTags } from "./SelectTags"
import { SubjectTagFormType } from "./CreateSubjectForm"

export const EditSubjectForm = (props: {
    subjectId: number
    onSuccess: () => void
}) => {
    // state
    const [isFormDirty, setIsFormDirty] = useState(false)
    // fetch all subjects
    const subjectsQuery = useAllSubjects()
    // fetch all types
    const types = useLookupOfType(["subject_type"])

    // update multiple
    const update = useUpdateSubject()

    const [form] = useForm()
    const [notify, contextHolder] = notification.useNotification()

    const onValuesChange = useCallback((values: SubjectTagFormType) => {
        if (values) {
            setIsFormDirty(true)
        }
    }, [])

    /**
     * Set subject name and type to form
     */
    useEffect(() => {
        if (props.subjectId) {
            const subject = subjectsQuery.data?.find(
                (s) => s.id == props.subjectId
            )

            if (subject) {
                form.setFieldsValue({
                    name: subject.name,
                    type: types.data?.getLookupsOfType?.find(
                        (t) => t.key == subject.typekey
                    )?.key,
                    tags: subject.tags?.map((t) => t.id),
                    priority: subject.priority,
                })
            }
        }
    }, [props.subjectId, subjectsQuery.data, types.data])

    /**
     * parse unites
     */
    const unitsList = useMemo(() => {
        return types.data?.getLookupsOfType?.map((t) => ({
            label: t.value,
            value: t.key,
        }))
    }, [types.data])

    /**
     * Submit the form and create a new report
     * @param values
     */
    const onFinish = (values: SubjectTagFormType) => {
        update.mutate(
            {
                id: props.subjectId,
                name: values.name,
                typeKey: values.type,
                tags: values.tags,
                priority: values.priority,
            },
            {
                onSuccess: () => {
                    form.resetFields()
                    notify.success({
                        message: "Subject updated",
                        description: "Subject updated successfully",
                    })
                    // refetch all subjects
                    queryClient.invalidateQueries(["report", "subjects"])
                    props.onSuccess()
                },
            }
        )
    }

    return (
        <Form
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            layout="vertical"
            validateTrigger="onBlur"
        >
            {contextHolder}
            <Form.Item label="Subject name" name="name" required>
                <Input />
            </Form.Item>

            <Form.Item label="Subject type" name="type" required>
                <Select options={unitsList} loading={types.isLoading} />
            </Form.Item>
            <Form.Item label="Priority" name="priority" required>
                <InputNumber
                    type={"number"}
                    min={0}
                    controls={false}
                    className="w-full"
                />
            </Form.Item>
            <Form.Item label="Assign tags" name="tags">
                <SelectTags />
            </Form.Item>

            <Form.Item>
                <VaesButton
                    type="primary"
                    htmlType="submit"
                    loading={update.isLoading}
                    disabled={!isFormDirty || update.isLoading}
                    block
                    mpBtnName="Edit subject"
                >
                    Edit subject
                </VaesButton>
            </Form.Item>
        </Form>
    )
}
