import { useEffect } from "react"

/**
 *  A hook that will display a message to the user before they leave the page.
 * @param block  wether to block the user from leaving the page or allow him.
 */
function useBeforeUnload(block: boolean) {
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (block) {
                event.preventDefault()
                event.returnValue = "block"
            }
        }

        window.addEventListener("beforeunload", handleBeforeUnload)

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        }
    }, [block])
}

export default useBeforeUnload
