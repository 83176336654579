import { glClient } from "./client/graphqlClient"
import { useQuery, useMutation } from "react-query"
import { queryClient } from "./client/reactQuery"
import { ID } from "@vaes-dashboard-2/graphql/types"
import { useProjectUploadLogs } from "./fileUpload"
import { useAllProjectReportPages } from "./report"

export const listCompanyProjectsQueryDetails = (companyId?: number) => ({
    queryKey: ["project", "list", companyId],
    queryFn: () => {
        if (companyId == undefined)
            return Promise.reject(new Error(companyId + " is undefined"))

        return glClient.query({
            listCompanyProjects: [
                { companyId },
                {
                    id: true,
                    name: true,
                    company: {
                        id: true,
                        name: true,
                    },
                    clientStatus: {
                        key: true,
                        value: true,
                    },
                    status: {
                        key: true,
                        value: true,
                    },
                    address: true,
                    type: {
                        key: true,
                        value: true,
                    },
                    isDemo: true,
                    imgUrl: true,
                    parts: {
                        id: true,
                        detailedReportStatus: {
                            key: true,
                            value: true,
                        },
                    },
                    createdAt: true,
                },
            ],
        })
    },
})

// Client portal only
export function useProjectsList(companyId?: number) {
    const query = listCompanyProjectsQueryDetails(companyId)

    return useQuery(query.queryKey, query.queryFn, {
        select: (data) => {
            return data.listCompanyProjects
        },
        staleTime: Infinity,
        enabled: companyId != null, // null or undefined
    })
}

export async function loadCompanyProject(companyId: number) {
    const query = listCompanyProjectsQueryDetails(companyId)
    type QueryType = Awaited<ReturnType<typeof query.queryFn>>

    return (
        queryClient.getQueryData<QueryType>(query.queryKey) ??
        (await queryClient.fetchQuery(query))
    )
}

export const getProjectInfoQueryDetails = (projectId: ID) => ({
    queryKey: ["project", projectId],
    queryFn: () => {
        return glClient.query({
            getProject: [
                { projectId: Number(projectId) },
                {
                    id: true,
                    imgUrl: true,
                    name: true,
                    isDemo: true,
                    address: true,
                    clientStatus: {
                        key: true,
                        value: true,
                    },
                    status: {
                        key: true,
                        value: true,
                    },
                    type: {
                        key: true,
                        value: true,
                    },
                    otherType: true,
                    consultant: true,
                    owner: true,
                    architect: true,
                    length: true,
                    buildArea: true,
                    numberOfFloors: true,
                    typicalFloorArea: true,
                    typicalFloorHeight: true,
                    height: true,
                    numberOfTanks: true,
                    volumeOfTanks: true,
                    scope: true,
                    usage: {
                        key: true,
                        value: true,
                    },
                    members: {
                        userId: true,
                        email: true,
                        imageUrl: true,
                        name: true,
                        roleName: true,
                        isDeleted: true,
                    },
                    companyId: true,
                    breakDownImg: {
                        s3key: true,
                        url: true,
                    },
                    projectManager1Id: true,
                    projectManager2Id: true,
                    publishedUnderstandingDocs: {
                        s3key: true,
                        url: true,
                    },
                },
            ],
        })
    },
})

export async function loadProjectInfo(projectId: number) {
    const query = getProjectInfoQueryDetails(projectId)
    type QueryType = Awaited<ReturnType<typeof query.queryFn>>

    return (
        queryClient.getQueryData<QueryType>(query.queryKey) ??
        (await queryClient.fetchQuery(query))
    )
}

export function useGetProjectInfo(projectId: number) {
    const details = getProjectInfoQueryDetails(projectId)

    return useQuery<Awaited<ReturnType<typeof details.queryFn>>, Error>(
        details.queryKey,
        details.queryFn,
        {
            staleTime: Infinity,
        }
    )
}

export function useProjectUnderstandingInfo(projectId: number) {
    return useQuery(
        ["project", projectId, "understanding-info"],
        () => {
            return glClient.query({
                getProject: [
                    { projectId: Number(projectId) },
                    {
                        breakDownImg: { s3key: true, url: true },
                        publishedUnderstandingDocs: { s3key: true, url: true },
                        draftUnderstandingDoc: { s3key: true, url: true },
                    },
                ],
            })
        },
        {
            staleTime: Infinity,
            select: (data) => data.getProject,
        }
    )
}

export function useCreateProject() {
    const mutationFn = (payload: {
        name: string
        scope: string
        type: string
        clientStatus: string
        companyId?: number
        address?: string
        architect?: string
        owner?: string
        consultant?: string
        otherType?: string
        buildArea?: number
        usage?: string[]
    }) => {
        return glClient.mutation({
            createProject: [payload, { id: true, company: { id: true } }],
        })
    }

    return useMutation<
        Awaited<ReturnType<typeof mutationFn>>,
        Error,
        Parameters<typeof mutationFn>[0]
    >({
        mutationFn,
    })
}

export const useShareProject = () => {
    return useMutation({
        mutationFn: (payload: { projectId: number; userIds: number[] }) => {
            return glClient.mutation({
                shareProject: [payload, { id: true }],
            })
        },
    })
}

export const useUnshareProject = () => {
    return useMutation({
        mutationFn: (payload: { projectId: number; userIds: number[] }) => {
            return glClient.mutation({
                unshareProject: [payload],
            })
        },
    })
}

/**
 * Check if the project is a demo or not
 * @param projectId
 */
const useIsDemo = (projectId: ID) => {
    const details = getProjectInfoQueryDetails(projectId)

    return useQuery(details.queryKey, details.queryFn, {
        staleTime: Infinity,
        select: (data) => data.getProject?.isDemo,
    })
}

/**
 * Check if the project has files (Client submitted)
 * will return true for demo projects by default
 */
export const useHasFiles = (companyId: ID, projectId: ID) => {
    const { data: isDemo, isLoading } = useIsDemo(projectId)
    const projectLogs = useProjectUploadLogs(projectId)

    if (isDemo) {
        return true
    }

    if (isLoading) {
        return null
    }
    return !!projectLogs.data?.listProjectUploadLogs?.length
}

/**
 * check if the project has submitted reports
 */
export const useHasReport = (projectId: ID) => {
    const reportPages = useAllProjectReportPages(projectId)

    if (!reportPages.isSuccess || reportPages.isLoading) {
        return null
    }

    return !!reportPages.data.parts.flatMap((data) => data.pages).length
}

export default {
    useHasFiles,
    useHasReport,
    useIsDemo,
}

export function useProjectStatus(projectId: number, enabled = true) {
    const details = getProjectInfoQueryDetails(projectId)

    return useQuery(details.queryKey, details.queryFn, {
        select: (data) => data.getProject?.status,
        enabled,
    })
}

export function useUpdateProjectStatus() {
    return useMutation({
        mutationFn: (payload: { projectId: number; status: string }) => {
            return glClient.mutation({
                updateProjectStatus: [payload, { id: true }],
            })
        },
    })
}
export function useUpdateProjectData() {
    return useMutation({
        mutationFn: (payload: {
            projectId: number
            companyId: number
            name?: string
            clientStatus?: string
            type?: string
            address?: string
            otherType?: string
            usage?: string[]
            buildArea?: number
            owner?: string
            consultant?: string
            architect?: string
            height?: number
            length?: number
            bridgeSpan?: number
            typicalFloorArea?: number
            typicalFloorHeight?: number
            numberOfTanks?: number
            volumeOfTanks?: number
            numberOfUnits?: number
            storageArea?: number
            capacity?: number
            audience?: number
            stadiumArea?: number
            scope?: string
            numberOfFloors: number
        }) => {
            return glClient.mutation({
                updateProjectData: [payload, { id: true }],
            })
        },
    })
}

/**
 * Set new doc as draft
 */
export function useUpdateUnderstandingDoc() {
    return useMutation({
        mutationFn: (payload: { projectId: number; docS3key: string }) => {
            return glClient.mutation({
                updateProjectUnderstandingDoc: [payload],
            })
        },
    })
}

/**
 * Send draft doc to client
 */
export function useSendUnderstandingDoc() {
    return useMutation({
        mutationFn: (payload: { projectId: number }) => {
            return glClient.mutation({
                sendUnderstandingDoc: [payload],
            })
        },
    })
}

export const listDemoProjects = () => {
    return useQuery({
        queryKey: ["demo", "list"],
        queryFn: () => {
            return glClient.query({
                listDemoProjects: {
                    id: true,
                    name: true,
                    company: {
                        id: true,
                        name: true,
                    },
                    clientStatus: {
                        key: true,
                        value: true,
                    },
                    status: {
                        key: true,
                        value: true,
                    },
                    address: true,
                    type: {
                        key: true,
                        value: true,
                    },
                    isDemo: true,
                    imgUrl: true,
                    parts: {
                        id: true,
                        detailedReportStatus: {
                            key: true,
                            value: true,
                        },
                    },
                },
            })
        },
        select: (data) => data.listDemoProjects,
    })
}

/**
 * set engineer as project manager
 */
export function useSetProjectManager() {
    return useMutation({
        mutationFn: (payload: { projectId: number; userId: number }) => {
            return glClient.mutation({
                setProjectManager: [payload],
            })
        },
    })
}
