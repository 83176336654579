import { VaesUserRoles } from "@vaes-dashboard-2/graphql/userRoles"
export const VAES_USER_ACCESS_TOKEN_KEY = "vaes-dashboard-access-token"
export const VAES_USER_REFRESH_TOKEN_KEY = "vaes-dashboard-refresh-token"
export const VAES_USER_PREVIEW_TOKEN_KEY = "vaes-dashboard-preview-token"
export const VAES_USER_REMEMBER_ME = "vaes-dashboard-remember-me"
export const VAES_SUPPORT_EMAIL = "dashboard@vaes.ai"

// Mapping between user roles on our system and how they are displayed in the UI
// TODO: support translation
type VaesUserRolesLabels = "Admin" | "User" | "Manager" | "Engineer"
export const userRole = new Map<VaesUserRolesLabels, VaesUserRoles>()
userRole.set("User", "Client")
userRole.set("Admin", "ClientAdmin")
userRole.set("Manager", "VaesEngineeringManager")
userRole.set("Engineer", "VaesEngineer")
