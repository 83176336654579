import {
    Collapse,
    Form,
    Input,
    Popconfirm,
    Spin,
    Typography,
    notification,
} from "antd"
import FormItem from "antd/es/form/FormItem"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { ReportAudits } from "./ReportHeader"
import { useReportPage, useReportPageBulkUpdate } from "../../../../api/report"
import SubsectionForm, {
    groupByIteration,
} from "../../../../components/SubsectionForm/SubsectionForm"
import VaesButton from "../../../../components/VaesButton/VaesButton"
import { Iteration } from "@vaes-dashboard-2/graphql/genql"
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons"
import classNames from "classnames"
import { queryClient } from "../../../../api/client/reactQuery"
import { useNavigationBlocker } from "../../../../lib/hooks/useNavigationBlocker"
import useBeforeUnload from "../../../../lib/hooks/useBeforeUnload"
import { deepEqual } from "../../../../lib/helpers/general-helpers"
import {
    DragDropContext,
    Draggable,
    Droppable,
    OnDragEndResponder,
} from "react-beautiful-dnd"
import { useParams } from "react-router-dom"
import { DetailsType } from "../../../../components/ReportIterations/partial/ReportBody/ReportBody"
import { ID, S3FileType } from "@vaes-dashboard-2/graphql"
import { AnyObject } from "antd/es/_util/type"

type IterationPayloadType = {
    id: number
    name?: Iteration["name"]
    advantages?: Iteration["advantages"]
    description?: Iteration["description"]
    details?: DetailsType[]
    imageUrl?: S3FileType
    originalDesignImageUrl?: S3FileType
    gifImgUrl?: S3FileType
    order?: Iteration["order"]
}

/**
 * Extract iteration payload from the api response
 */
const getIterationPayload = (iterations: IterationPayloadType[]) => {
    return iterations?.map((i) => ({
        id: Number(i.id),
        name: i.name,
        advantages: i.advantages,
        description: i.description,
        details: i.details?.map((d) => ({
            subjectId: d.subject?.id,
            value: d.value,
            value2: d.value2,
        })),
        imgKey: i.imageUrl?.s3key,
        originalDesignImgKey: i.originalDesignImageUrl?.s3key,
        gifImgKey: i.gifImgUrl?.s3key,
        order: i.order,
    }))
}

const RemoveSubsectionButton = (props: {
    id: number
    onDelete: (id: number) => void
}) => {
    return (
        <Popconfirm
            title="Delete subsection?"
            description="Are you sure to delete this subsection?"
            okText="Yes"
            cancelText="No"
            onConfirm={(e) => {
                props.onDelete(props.id)
                e?.stopPropagation()
            }}
        >
            <VaesButton
                mpBtnName="delete subsection"
                danger
                onClick={(evt) => evt?.stopPropagation()}
            >
                Delete subsection
            </VaesButton>
        </Popconfirm>
    )
}
const ReportForm = (props: {
    pageId: number
    versionId?: number
    onCancel: () => void
    onSuccess: () => void
}) => {
    // react router
    const params = useParams<{ projectId: string }>()

    // local state
    const [draftSubsections, setDraftSubsections] = useState<
        IterationPayloadType[]
    >([])
    const [deletedSubsections, setDeletedSubsections] = useState<number[]>([])
    const [orderedSubsectionsIds, setOrderedSubsectionsIds] = useState<ID[]>([])

    // used to get updates when the form changes
    // we need this to make sure the form is updated before we check for changes "hasChanges"
    const [changeNotificationCount, setChangeNotificationCount] = useState(0)

    // form instance
    const [form] = Form.useForm()

    // api query
    const reportQuery = useReportPage(props.pageId, props.versionId)
    const bulkUpdate = useReportPageBulkUpdate()

    /**
     * remove all query params after finishing edit ex: tags
     */
    useEffect(() => {
        return () => {
            window.history.replaceState(
                {},
                document.title,
                window.location.pathname
            )
        }
    }, [bulkUpdate.isSuccess])

    /**
     * remove iteration from the page
     */
    const removeSubsectionHandler = useCallback((subsectionId: number) => {
        // check if the subsection is a draft, then remove it from the local state
        if (Number(subsectionId) < 0) {
            setDraftSubsections((subsections) => {
                return (
                    subsections?.filter((sub) => sub.id != subsectionId) || []
                )
            })
            // if it is not a draft, add it to the deleted list
        } else {
            setDeletedSubsections((arr) => [...arr, subsectionId])
        }
    }, [])

    /**
     * remove iteration from the page
     */
    const addNewIteration = useCallback(() => {
        setDraftSubsections((subsections) => {
            const currentIterations = subsections || []
            // generate random id, we won't send it to the apis
            const id = Math.ceil(Math.random() * -10000)

            // add to order list
            setOrderedSubsectionsIds((ids) => [...ids, id])

            return [
                ...currentIterations,
                {
                    id: id,
                    order: 1000, // move it to the bottom
                },
            ]
        })
    }, [])

    /**
     * Parse all form values into an array of iterations, handle new iterations and deleted iterations
     * by setting isNew and isDeleted flags
     */
    const buildIterations = useCallback(
        (formValues: AnyObject) => {
            return (
                // group all form values by iteration
                groupByIteration(formValues)
                    // add order value
                    .map((iteration) => ({
                        ...iteration,
                        order: orderedSubsectionsIds.indexOf(
                            String(iteration.id)
                        ),
                    }))
                    // if the iteration is a draft, remove the id and set isNew to true
                    .map((iteration) => {
                        if (
                            draftSubsections?.find(
                                (draft) => draft.id == iteration.id
                            )
                        ) {
                            return {
                                ...iteration,
                                isNew: true,
                                id: undefined,
                            }
                        }

                        return iteration
                    })

                    // add deleted iterations, set isDeleted to true
                    .concat(
                        deletedSubsections.map((id) => ({
                            id: id,
                            isDeleted: true,
                            order: 0,
                        }))
                    )
            )
        },
        [deletedSubsections, draftSubsections, orderedSubsectionsIds]
    )

    /**
     * Submit form handler
     */
    const onFinish = useCallback(
        (values: AnyObject) => {
            // build iterations array to send to the server
            const iterations = buildIterations(values)

            // send request
            bulkUpdate.mutate(
                {
                    id: props.pageId,
                    title: values.title,
                    iterations: iterations,
                },
                {
                    onSuccess: async () => {
                        // invalidate cache
                        await queryClient.invalidateQueries([
                            "report-page",
                            props.pageId,
                        ])
                        await queryClient.invalidateQueries([
                            "report-page",
                            "Subsection",
                            "publishCheck",
                            props.pageId,
                        ])

                        // reset local states
                        setDraftSubsections([])
                        setDeletedSubsections([])

                        notification.success({
                            message: "Report updated successfully",
                        })

                        // notify parent
                        props.onSuccess()
                        await queryClient.invalidateQueries([
                            "project",
                            Number(params.projectId),
                            "report",
                            "page",
                            "all",
                        ])
                    },
                }
            )
        },
        [buildIterations, bulkUpdate, props]
    )

    /**
     * parse sub sections
     */
    useEffect(() => {
        const ids = (reportQuery.data?.getReportPage?.iterations || [])
            // get subsection ids
            .map((subsection) => subsection.id)

        setOrderedSubsectionsIds(ids)
    }, [reportQuery.data])

    /**
     * set the order value of the subsection after drag and drop
     */
    const onDragEnd: OnDragEndResponder = useCallback(
        (result) => {
            if (!result.destination) {
                return
            }

            const items = Array.from(orderedSubsectionsIds || [])
            const [reorderedPart] = items.splice(result.source.index, 1)
            items.splice(result.destination.index, 0, reorderedPart)
            setOrderedSubsectionsIds(items)
        },
        [orderedSubsectionsIds]
    )

    /**
     * parse sub sections
     */
    const subsectionItems = useMemo(() => {
        return (
            (reportQuery.data?.getReportPage?.iterations || [])
                // don't show deleted subsections
                .filter(
                    (subsection) =>
                        !deletedSubsections.includes(Number(subsection.id))
                )
                // show draft subsections at the bottom
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .concat(draftSubsections as any)
                // sort by the order of the ids
                .sort(
                    (a, b) =>
                        orderedSubsectionsIds.indexOf(a.id) -
                        orderedSubsectionsIds.indexOf(b.id)
                )
                .map((section, idx) => {
                    return (
                        <Collapse.Panel
                            key={section.id + ""}
                            header={
                                <Draggable
                                    key={section.id}
                                    draggableId={section.id + ""}
                                    index={idx}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Typography.Title
                                                level={4}
                                                className={classNames(
                                                    "!mb-0 font-robotoMedium !font-medium",
                                                    {
                                                        "!text-gray-500":
                                                            !section.name,
                                                    }
                                                )}
                                            >
                                                {/* Show untitled section in a gray color as a placeholder */}
                                                {section.name ||
                                                    "Untitled subsection"}
                                            </Typography.Title>
                                        </div>
                                    )}
                                </Draggable>
                            }
                            extra={
                                <RemoveSubsectionButton
                                    id={Number(section.id)}
                                    onDelete={(id) => {
                                        removeSubsectionHandler(id)
                                    }}
                                />
                            }
                            forceRender // make sure the form is rendered even if the panel is closed so we can get the values
                        >
                            <SubsectionForm
                                partId={
                                    reportQuery.data?.getReportPage
                                        .projectPartId || -1
                                }
                                iterationId={Number(section.id)}
                                pageId={Number(props.pageId)}
                                formInstance={form}
                            />
                        </Collapse.Panel>
                    )
                })
        )
    }, [
        reportQuery.data?.getReportPage?.iterations,
        reportQuery.data?.getReportPage?.projectPartId,
        draftSubsections,
        deletedSubsections,
        orderedSubsectionsIds,
        props.pageId,
        form,
        removeSubsectionHandler,
    ])
    /**
     * set report title
     */
    useEffect(() => {
        form.setFieldValue("title", reportQuery.data?.getReportPage?.title)
    }, [reportQuery.data, form])

    /**
     * check if there are any changes in the form
     * NOTE: this method use JSON.stringify() that will make sure the order of the fields is the same
     */
    const hasChanges = useMemo(() => {
        if (!reportQuery.data) return false

        // get original iterations payload from the api
        const originalIterations = getIterationPayload(
            reportQuery.data.getReportPage.iterations
        )

        // get iterations payload from the form
        const currentIterations = buildIterations(form.getFieldsValue())

        // handle edge case where data still not fetched
        // and user didn't make any changes
        if (currentIterations.length === 0 && changeNotificationCount === 0) {
            return false
        }

        // check title
        if (
            form.getFieldValue("title") !==
            reportQuery.data?.getReportPage?.title
        ) {
            return true
        }

        return !deepEqual(originalIterations, currentIterations)
    }, [reportQuery.data, buildIterations, changeNotificationCount, form])

    /**
     * block navigation and Alert user if there are unsaved changes
     * the block should not happen if the form data is submitting or report data is actively fetching
     */
    useNavigationBlocker(
        hasChanges && !bulkUpdate.isLoading && !reportQuery.isFetching
    )
    // alert on closing page tab
    useBeforeUnload(
        hasChanges && !bulkUpdate.isLoading && !reportQuery.isFetching
    )

    return (
        <Spin spinning={reportQuery.isLoading || reportQuery.isFetching}>
            <Form
                className="login-form"
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onBlur"
                form={form}
                rootClassName="flex flex-col h-full"
                onChange={useCallback(
                    () => setChangeNotificationCount((prev) => prev + 1),
                    []
                )}
            >
                <FormItem
                    name="title"
                    label="Report name"
                    rules={[{ required: true }]}
                >
                    <Input placeholder="Add report name" size="large" />
                </FormItem>

                <ReportAudits
                    pageId={props.pageId}
                    versionId={props.versionId}
                />

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="parts">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={classNames({
                                    "bg-blue-200": snapshot.isDraggingOver,
                                })}
                            >
                                <Collapse
                                    ghost
                                    expandIcon={({ isActive }) => (
                                        <CaretRightOutlined
                                            className="!text-base"
                                            rotate={isActive ? 90 : 0}
                                        />
                                    )}
                                >
                                    {subsectionItems}
                                </Collapse>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <VaesButton
                    icon={<PlusOutlined />}
                    className="mb-6 mt-12"
                    onClick={addNewIteration}
                    mpBtnName="Add a new subsection"
                    block
                >
                    Add a new subsection
                </VaesButton>

                <div className="flex gap-2 justify-end mt-auto">
                    <VaesButton
                        onClick={props.onCancel}
                        mpBtnName="optimization report - cancel changes"
                    >
                        Cancel
                    </VaesButton>

                    <VaesButton
                        type="primary"
                        mpBtnName="optimization report - save changes"
                        htmlType="submit"
                        loading={bulkUpdate.isLoading}
                    >
                        Save changes
                    </VaesButton>
                </div>
            </Form>
        </Spin>
    )
}

export default ReportForm
