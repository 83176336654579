import React, { useCallback } from "react"
import { Spin, Typography } from "antd"
import { useUploadFile } from "../../api/fileUpload"
import { useGenerateAssetURL } from "../../api/assets"
import { DropzoneProps, useDropzone } from "react-dropzone"
import classNames from "classnames"
import VaesButton from "../VaesButton/VaesButton"
import { S3FileType } from "@vaes-dashboard-2/graphql"

export const ImgUploader = (props: {
    onChange?: (value: S3FileType) => void
    value?: S3FileType
    label?: string
    texts?: {
        addImage?: string
        format?: string
        replaceImage?: string
    }
    className?: string
}) => {
    // update apis
    const generateS3Key = useGenerateAssetURL()
    const uploadFile = useUploadFile()

    /**
     * Upload the file and notify parent form
     */
    const onDrop = useCallback<NonNullable<DropzoneProps["onDrop"]>>(
        async (files, _, evt) => {
            // Stop propagation to prevent opening file dialog or invoking other handlers
            evt?.stopPropagation()

            const uploadInfo = await generateS3Key.mutateAsync({
                filename: (files[0] as File).name,
            })

            return uploadFile
                .mutateAsync({
                    file: files[0] as File,
                    uploadUrl: uploadInfo.generateAssetsUploadURL?.url || "",
                })
                .then(() => {
                    // notify parent form
                    props.onChange?.({
                        url:
                            uploadInfo.generateAssetsUploadURL?.downloadUrl ||
                            "",
                        s3key: uploadInfo.generateAssetsUploadURL?.s3key || "",
                    })
                })
                .catch((e) => {
                    console.error(e)
                    // do nothing
                })
        },
        [generateS3Key, uploadFile, props]
    )

    // Initialize dropzone
    const { getRootProps, isDragActive } = useDropzone({
        onDrop,
        noClick: false,
        accept: {
            "image/jpeg": [],
            "image/png": [],
            "image/webp": [],
            "image/heic": [],
            "image/jfif": [],
            "image/gif": [],
        },
    })

    return (
        <Spin spinning={generateS3Key.isLoading || uploadFile.isLoading}>
            <div
                {...getRootProps()}
                className={classNames(
                    "border border-solid border-gray-150 min-h-[370px] flex flex-col justify-center items-center",
                    {
                        "border-dashed bg-blue-50": isDragActive,
                    },
                    "relative overflow-hidden",
                    { [props.className as string]: !!props.className }
                )}
            >
                {/* Render image if existed */}
                {props.value?.url && (
                    <img
                        src={props.value.url}
                        alt="Breakdown image"
                        className="max-h-[370px] h-full w-auto object-contain"
                    />
                )}

                {!props.value?.url && (
                    <>
                        <Typography.Text className="!mb-4 text-base font-robotoMedium">
                            {props.texts?.addImage || "+ Add image"}
                        </Typography.Text>
                        <Typography.Text
                            type="secondary"
                            className="!mb-4 text-base font-robotoMedium"
                        >
                            {props.texts?.format || "(Format: JPG, PNG, SVG)"}
                        </Typography.Text>
                    </>
                )}

                {props.value?.url && (
                    <div className="bg-white rounded px-4 py-1 flex flex-col top-3 right-3 absolute border border-gray-50 border-solid">
                        <VaesButton type="link">Replace image</VaesButton>

                        <Typography.Text
                            type="secondary"
                            className=" font-robotoMedium"
                        >
                            {props.texts?.format || "(Format: JPG, PNG, SVG)"}
                        </Typography.Text>
                    </div>
                )}
                {/* label */}
                {props.label && (
                    <Typography.Text className="bg-white rounded flex flex-col top-3 left-3 absolute text-base font-robotoMedium">
                        {props.label}
                    </Typography.Text>
                )}
            </div>
        </Spin>
    )
}

export const ImgUploaderView = (props: { value?: S3FileType }) => {
    return (
        <div
            className={classNames(
                "border border-solid border-gray-150 min-h-[370px] flex flex-col justify-center items-center"
            )}
        >
            {/* Render image if existed */}
            {props.value?.url && (
                <img
                    src={props.value.url}
                    alt="Breakdown image"
                    className="max-h-[370px] max-w-[370px] object-contain"
                />
            )}

            {!props.value?.url && (
                <Typography.Text className="!mb-4 text-base font-robotoMedium">
                    No breakdown image found
                </Typography.Text>
            )}
        </div>
    )
}
