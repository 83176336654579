import React from "react"
import { Space, Tag } from "antd"
import classNames from "classnames"
import { Typography } from "antd"
import VaesButton from "../../../../components/VaesButton/VaesButton"
import { useNavigate } from "react-router-dom"
import SelectPackageVersion from "../../../../components/SelectPackageVersion"
import { PackageVersion } from "../../../../components/SelectPackageVersion/SelectPackageVersion"

export const PackageHeader = (props: {
    packageId: number
    name?: string
    isPublished: boolean
    onDownload: () => void
    selectedVersion?: number
    versions?: PackageVersion[]
    onVersionChange: (versionId: number) => void
    onCreateVersion?: () => void
    onRemove?: () => void
}) => {
    // react router
    const navigate = useNavigate()

    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
                {/* Name */}
                <h4 className={classNames(" roboto-medium  m-0", "text-xl")}>
                    {props.name || (
                        // Placeholder name
                        <Typography.Text
                            type="secondary"
                            className="text-xl font-robotoMedium"
                        >
                            Untitled subsection
                        </Typography.Text>
                    )}
                </h4>

                {/* Status */}
                <Tag color={props.isPublished ? "green" : "default"}>
                    {props.isPublished ? "Published" : "Draft"}
                </Tag>

                {/* Render version select element if we have more than one version (including original package) */}
                {!!props.versions?.length && props.versions?.length > 1 && (
                    <SelectPackageVersion
                        versions={props.versions}
                        onChange={props.onVersionChange}
                        value={props.selectedVersion}
                    />
                )}
            </div>

            <Space>
                {/* Create version button */}
                {props.isPublished && (
                    <VaesButton
                        mpBtnName="detailed-package-create-version"
                        onClick={props.onCreateVersion}
                    >
                        Create version
                    </VaesButton>
                )}

                {!props.isPublished && (
                    <VaesButton
                        mpBtnName="detailed-package-remove"
                        onClick={props.onRemove}
                        type="text"
                        danger
                    >
                        Remove package
                    </VaesButton>
                )}

                {!props.isPublished && (
                    <VaesButton
                        mpBtnName="detailed-package-edit"
                        onClick={() => navigate(`./${props.packageId}`)}
                    >
                        Edit package
                    </VaesButton>
                )}
                <VaesButton
                    mpBtnName="detailed-package-download"
                    className="bg-black text-white w-full"
                    onClick={props.onDownload}
                >
                    Download package
                </VaesButton>
            </Space>
        </div>
    )
}
