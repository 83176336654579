import React, { useCallback } from "react"
import { Spin } from "antd"
import EmptyLogsComponent from "../../../components/EmptyLogsComponent/EmptyLogsComponent"
import { useNavigate, useParams } from "react-router"
import { VAES_SUPPORT_EMAIL } from "../../../lib/constants"
import ProjectAPI, { useGetProjectInfo } from "../../../api/project"
import { useProjectPart } from "../../../api/report"
import { useTranslation } from "react-i18next"

type DetailedReportStatus =
    | "client_need_to_select"
    | "detailed_report_in_progress"
    | "detailed_report_sent"

/**
 * Internal component to show different messages based detailed on the report status
 * @NOTE this component is temp until we support downloading detailed reports from the dashboard.
 */
const EmptyStates = () => {
    // router hooks
    const navigate = useNavigate()
    const { projectId, companyId } = useParams() as {
        projectId: string
        companyId: string
    }

    const hasFiles = ProjectAPI.useHasFiles(companyId, projectId)
    const hasReports = ProjectAPI.useHasReport(projectId)

    // TODO: remove translation name spaces
    const { t: translate } = useTranslation("translation")
    const t = (key: string) => translate(key, { ns: "translation" })

    // get report info
    const reportQuery = useProjectPart(Number(projectId))
    const { data: projectData, isLoading: isLoadingProjectInfo } =
        useGetProjectInfo(Number(projectId))

    // detailed report status
    const detailedReportStatus = reportQuery.data?.detailedReportStatus
        ?.key as DetailedReportStatus
    /**
     * handle contact us click
     */
    const handleContactUsClick = useCallback(() => {
        window.open(`mailto:${VAES_SUPPORT_EMAIL}`, "_blank")
    }, [])

    const downloadPPTHandler = useCallback(() => {
        if (projectData.getProject.publishedUnderstandingDocs.length === 0)
            return

        const fileName = `${projectData?.getProject?.publishedUnderstandingDocs[0]?.url
            ?.split("/")
            ?.pop()}`
        const fileUrl =
            projectData?.getProject?.publishedUnderstandingDocs[0]?.url
        const link = document.createElement("a")
        link.href = fileUrl
        link.download = fileName
        link.target = "_blank" // Optional: open in a new tab, if needed
        link.click()
    }, [isLoadingProjectInfo, projectData])

    if (hasFiles === false) {
        return (
            <EmptyLogsComponent
                header={t(
                    "project.emptyComponent.download.noUploadedFiles.header"
                )}
                paragraph={t(
                    "project.emptyComponent.download.noUploadedFiles.header"
                )}
                controls={[
                    {
                        onClick: () => {
                            navigate(
                                `/project/upload/${companyId}/${projectId}`
                            )
                        },
                        buttonText: t(
                            "project.emptyComponent.upload.buttonText"
                        ),
                        buttonType: "primary",
                    },
                ]}
                veGif={"/assets/images/Submit-01.gif"}
            />
        )
    } else if (hasReports === false) {
        return (
            <EmptyLogsComponent
                header={t("project.emptyComponent.download.noReports.header")}
                paragraph={t(
                    "project.emptyComponent.download.noReports.paragraph"
                )}
                controls={[
                    {
                        onClick: () => {
                            navigate(
                                `/project/upload/${companyId}/${projectId}`
                            )
                        },
                        buttonText: t(
                            "project.emptyComponent.reports.buttonText"
                        ),
                        buttonType: "default",
                    },
                ]}
                veGif={"/assets/images/Done-01.gif"}
            />
        )
    } else if (detailedReportStatus === "client_need_to_select") {
        return (
            <EmptyLogsComponent
                rootClassName="mt-0 w-full"
                header={t("project.emptyComponent.download.review.header")}
                paragraph={t(
                    "project.emptyComponent.download.review.paragraph"
                )}
                controls={[
                    {
                        onClick: () => {
                            if (
                                projectData?.getProject
                                    ?.publishedUnderstandingDocs?.length === 0
                            ) {
                                navigate(
                                    `/project/report/${companyId}/${projectId}`
                                )
                            } else {
                                downloadPPTHandler()
                            }
                        },
                        buttonText:
                            projectData?.getProject?.publishedUnderstandingDocs
                                ?.length === 0
                                ? t(
                                      "project.emptyComponent.download.review.buttonTextOne"
                                  )
                                : t(
                                      "project.emptyComponent.download.review.buttonTextOneDownload"
                                  ),
                        buttonType: "default",
                    },

                    {
                        onClick: handleContactUsClick,
                        buttonText: t(
                            "project.emptyComponent.download.review.buttonTextTwo"
                        ),
                        buttonType: "primary",
                    },
                ]}
                veGif={"/assets/images/review-01.gif"}
            />
        )
    } else if (detailedReportStatus === "detailed_report_in_progress") {
        return (
            <EmptyLogsComponent
                rootClassName="mt-0 w-full"
                header={t("project.emptyComponent.download.inProgress.header")}
                paragraph={t(
                    "project.emptyComponent.download.inProgress.paragraph"
                )}
                controls={[
                    {
                        onClick: () => {
                            if (
                                projectData?.getProject
                                    ?.publishedUnderstandingDocs?.length === 0
                            ) {
                                navigate(
                                    `/project/report/${companyId}/${projectId}`
                                )
                            } else {
                                downloadPPTHandler()
                            }
                        },
                        buttonText:
                            projectData?.getProject?.publishedUnderstandingDocs
                                ?.length === 0
                                ? t(
                                      "project.emptyComponent.download.inProgress.buttonTextOne"
                                  )
                                : t(
                                      "project.emptyComponent.download.inProgress.buttonTextOneDownload"
                                  ),
                        buttonType: "default",
                    },

                    {
                        onClick: handleContactUsClick,
                        buttonText: t(
                            "project.emptyComponent.download.inProgress.buttonTextTwo"
                        ),
                        buttonType: "primary",
                    },
                ]}
                veGif={"/assets/images/In-progress-01.gif"}
            />
        )
    } else if (detailedReportStatus === "detailed_report_sent") {
        return (
            <EmptyLogsComponent
                rootClassName="mt-0 w-full"
                header={t("project.outputs.downloadReady")}
                paragraph={t("project.outputs.checkEmail")}
            />
        )
    } else {
        return <Spin spinning size="default" />
    }
}

export default EmptyStates
