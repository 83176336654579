import React, { useCallback, useMemo, useState } from "react"

import dayjs from "dayjs/esm/index.js"
import utc from "dayjs/esm/plugin/utc"
import { DownloadDetailsCardEditMode } from "../../ReportDownload/partials/DownloadDetailsCard"
import { Col, Empty, Modal, Row, Spin, Typography, notification } from "antd"
import {
    compressAndDownload,
    ensureUniqueFilesPath,
    formatBigNumber,
} from "../../../lib/helpers/general-helpers"
import TotalApprovedCard from "../../ReportDownload/partials/TotalApprovedCard"
import VaesButton from "../../../components/VaesButton/VaesButton"
import {
    useCreateVaEDetailedReport,
    useOnPackageDownload,
    useVaEReport,
} from "../../../api/vaeDetailedReport"
import { useNavigate, useParams } from "react-router-dom"
import SelectProjectPart from "../../../components/SelectProjectPart"
import { useGetProjectInfo } from "../../../api/project"
import classNames from "classnames"
import { queryClient } from "../../../api/client/reactQuery"
import { DetailedPackagesList } from "./partials/DetailedPackageList"
import { useListAllProjectParts } from "../../../api/report"
import { useGetAllProjectLabels } from "../../../api/projectLabel"
import PrereqieredStates from "../../../components/PrereqieredStates/PrereqieredStates"
import { useTranslation } from "react-i18next"
import Link from "antd/es/typography/Link"

dayjs.extend(utc)

/**
 * private function to show a modal that blocks download a demo projects packages
 */
const showDownloadDemoModal = () => {
    const { t: translate } = useTranslation("translation")
    const t = (key: string) => translate(key, { ns: "translation" })
    // download model to prevent download in demo projects
    Modal.warning({
        icon: <></>,
        title: t("project.downloadReport.downloadPopupTitle"),
        content: t("project.downloadReport.downloadPopupContent"),
    })

    return
}

const AddNewPackageModal = () => {
    // local state
    const [isOpen, setIsOpen] = useState(false)
    const [selectedProjectPart, setSelectedProjectPart] = useState<number>()

    const toggleModal = useCallback(() => setIsOpen((prev) => !prev), [])

    // react router
    const params = useParams<{ projectId: string }>()
    const navigate = useNavigate()

    // api
    const createPackage = useCreateVaEDetailedReport()

    /**
     * Create new package and navigate to it
     */
    const handleOk = useCallback(() => {
        if (selectedProjectPart == null) return

        // create new package
        createPackage.mutate(
            {
                projectId: Number(params.projectId),
                projectPartId: selectedProjectPart,
                package: {
                    summary: "Approved savings",
                },
            },
            {
                onSuccess: (data) => {
                    navigate(`./${data.createVaEPackage?.id}`)
                    queryClient.invalidateQueries([
                        "vae-report",
                        Number(params.projectId),
                        "list",
                    ])
                },
            }
        )

        toggleModal()
    }, [
        selectedProjectPart,
        createPackage,
        params.projectId,
        toggleModal,
        navigate,
    ])

    return (
        <>
            <VaesButton
                mpBtnName="add-new-detailed-package"
                className="min-w-[350px]"
                type="primary"
                size="large"
                onClick={toggleModal}
                loading={createPackage.isLoading}
            >
                + Add new package
            </VaesButton>

            <Modal
                title="Select project part"
                open={isOpen}
                onOk={handleOk}
                okText="Create Package"
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ disabled: !selectedProjectPart }}
                onCancel={toggleModal}
            >
                <div className="pb-8 pt-4">
                    <Typography.Paragraph className=" mb-4">
                        Select the project part you want to create a package for
                    </Typography.Paragraph>

                    <SelectProjectPart
                        projectId={Number(params.projectId)}
                        onChange={setSelectedProjectPart}
                        value={selectedProjectPart}
                    />
                </div>
            </Modal>
        </>
    )
}
export const VaeDetailedPackageLoader = () => {
    return null
}

export const VaeDetailedPackage = () => {
    // react router
    const params = useParams<{ projectId: string }>()

    // api
    const vaeQuery = useVaEReport(Number(params.projectId))
    const projectQuery = useGetProjectInfo(Number(params.projectId))
    const onDownloadPackage = useOnPackageDownload()

    /**to check if there are project parts and subjects or not */
    const listProjectParts = useListAllProjectParts(Number(params.projectId))
    const listProjectSubject = useGetAllProjectLabels(Number(params.projectId))
    const hasProjectParts = !!listProjectParts.data?.length
    const hasProjectSubject = !!listProjectSubject.data?.length
    /**
     * parse packages cards
     */
    const packagesCards = useMemo(() => {
        return vaeQuery.data?.map((p) => {
            return (
                <Col key={p.id} sm={24} xs={24} lg={8}>
                    <Link href={`#downloadreport${p.id}`}>
                        <DownloadDetailsCardEditMode
                            id={p.id}
                            dimension={p.summary || ""}
                            price={
                                p.savings
                                    ? (formatBigNumber(p.savings) as string)
                                    : "0"
                            }
                            priceUnit="USD"
                            imageUrl={p.image?.url}
                            name={p.name}
                            isPublished={p.isPublished}
                        />
                    </Link>
                </Col>
            )
        })
    }, [vaeQuery.data])

    /**
     * Check if the any of the packages has a package url
     */
    const hasPackagesUrl = useMemo(() => {
        return !!vaeQuery.data?.some((p) => p.package?.url)
    }, [vaeQuery.data])

    /**
     * Calculate total savings
     */
    const totalSavings = useMemo(() => {
        return (
            vaeQuery.data?.reduce((acc, p) => {
                return acc + (!!p.savings ? p.savings : 0)
            }, 0) || 0
        )
    }, [vaeQuery.data])

    /**
     * handle download all packages by generating a zip file and download it
     */
    const handleDownloadAllClicked = async (e: React.MouseEvent) => {
        e?.stopPropagation()

        if (projectQuery.data?.getProject?.isDemo) {
            showDownloadDemoModal()
            return
        }

        // if there is nothing to download!
        if (!hasPackagesUrl) {
            notification.warning({
                message: "No packages found!",
            })

            return
        }

        // get all packages urls
        const urls = vaeQuery.data?.map((p) => p.package?.url)
        // extract file names from s3 key, file name is the last section of the key
        const filenames = vaeQuery.data?.map((p) => {
            const urlParts = p.package?.s3key?.split("/")
            return urlParts?.[urlParts.length - 1]
        })

        if (!urls || !filenames) return

        // generate zip file from urls
        compressAndDownload(
            urls.filter((u): u is string => !!u),
            ensureUniqueFilesPath(filenames.filter((u): u is string => !!u)),
            `vaes-all-packages(${projectQuery.data?.getProject?.name}).zip`
        )

        // notify
        onDownloadPackage.mutate({
            projectId: Number(params.projectId),
            all: true,
        })
    }

    return (
        <Spin
            spinning={
                projectQuery.isLoading ||
                vaeQuery.isFetching ||
                vaeQuery.isLoading ||
                listProjectParts.isLoading ||
                listProjectSubject.isLoading
            }
        >
            {hasProjectParts &&
                hasProjectSubject &&
                !projectQuery.isLoading &&
                !vaeQuery.isLoading &&
                !listProjectParts.isLoading &&
                !listProjectSubject.isLoading && (
                    <div className="max-w-7xl mx-auto h-full">
                        <Row
                            className="mb-10"
                            gutter={[
                                { xs: 8, sm: 16 },
                                { xs: 8, sm: 16 },
                            ]}
                        >
                            {!!vaeQuery.data?.length && (
                                <Col span={24}>
                                    <TotalApprovedCard
                                        totalSavings={
                                            formatBigNumber(
                                                totalSavings
                                            ) as string
                                        }
                                        totalSavingsUnit={"USD"}
                                        isApprovedCardMoreThan1={
                                            !!vaeQuery.data?.length &&
                                            vaeQuery.data?.length > 1
                                        }
                                        onDownloadClick={
                                            handleDownloadAllClicked
                                        }
                                    />
                                </Col>
                            )}

                            {/* render packages card */}
                            {packagesCards}
                        </Row>
                        {
                            // if there is no packages
                            !vaeQuery.data?.length && (
                                <Empty
                                    description="No packages found!"
                                    className="mb-12"
                                />
                            )
                        }

                        <div
                            className={classNames("flex", {
                                "justify-center": !vaeQuery.data?.length,
                            })}
                        >
                            <AddNewPackageModal />
                        </div>

                        <Row>
                            <Col span={24}>
                                <DetailedPackagesList />
                            </Col>
                        </Row>
                    </div>
                )}

            {!projectQuery.isLoading &&
                !vaeQuery.isLoading &&
                !listProjectParts.isLoading &&
                !listProjectSubject.isLoading && (
                    <PrereqieredStates
                        checkProjectPart={
                            hasProjectParts &&
                            !hasProjectSubject &&
                            !projectQuery.isLoading &&
                            !vaeQuery.isLoading
                        }
                        checkProjectSubject={
                            !hasProjectParts &&
                            hasProjectSubject &&
                            !projectQuery.isLoading &&
                            !vaeQuery.isLoading
                        }
                        checkBoth={
                            !hasProjectParts &&
                            !hasProjectSubject &&
                            !projectQuery.isLoading &&
                            !vaeQuery.isLoading
                        }
                        projectSubjectMessage="To start adding vae package, add project label titles."
                        projectPartMessage="To start adding vae package, add project parts under project understanding page"
                        bothMessage="To start adding vae package:"
                    />
                )}
        </Spin>
    )
}
