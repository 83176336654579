import { LookupType } from "@vaes-dashboard-2/graphql/types"
import { useQuery } from "react-query"
import { glClient } from "./client/graphqlClient"

export const getLookupOfTypeQueryDetails = (types: LookupType[]) => ({
    queryKey: ["lookups", "list", types],
    queryFn: async () => {
        return glClient.query({
            getLookupsOfType: [
                { types },
                {
                    type: true,
                    key: true,
                    value: true,
                },
            ],
        })
    },
})

export function useLookupOfType(types: LookupType[]) {
    const query = getLookupOfTypeQueryDetails(types)

    return useQuery(query.queryKey, query.queryFn)
}
