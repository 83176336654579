import React from "react"
import { useMemo } from "react"
import { useAllSubjectTags } from "../../../../api/subjectTags"
import { Space, Tag } from "antd"

const { CheckableTag } = Tag

export const SelectTags = (props: {
    onChange?: (value: number[]) => void
    value?: number[]
}) => {
    const tagsQuery = useAllSubjectTags()

    const handleChange = (tag: number, checked: boolean) => {
        const selected = props.value || []

        const nextSelectedTags = checked
            ? [...selected, tag]
            : selected.filter((t) => t !== tag)
        props.onChange?.(nextSelectedTags)
    }

    const tags = useMemo(() => {
        return tagsQuery.data?.map((tag) => (
            <CheckableTag
                key={tag.id}
                checked={!!props.value?.includes(Number(tag.id))}
                onChange={(checked) => handleChange(Number(tag.id), checked)}
            >
                {tag.name}
            </CheckableTag>
        ))
    }, [tagsQuery.data, props.value])

    return (
        <Space wrap size={[1, 8]}>
            {tags}
        </Space>
    )
}
