import React, { useCallback, useEffect } from "react"
import { Spin } from "antd"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { NotionRenderer } from "react-notion-x"
import { useGetStaticPage } from "../../api/staticPage"
import { useNavigate } from "react-router-dom"
import style from "./Static.module.scss"
import "./Static.scss"
import Logo from "../../components/Logo/Logo"
import useMixpanelPageOpen from "../../lib/hooks/useMixpanelPageOpen"
import VaesButton from "../../components/VaesButton/VaesButton"

type StaticProps = {
    pageId: string
    pageName: string
    showLeftArrow?: boolean | true
    showLogo?: boolean | false
}

const Static = (props: StaticProps) => {
    // router hooks
    const navigate = useNavigate()

    // API call
    const { data, isError, isLoading } = useGetStaticPage(props.pageId)

    const handleGoBack = useCallback(() => {
        navigate(-1)
    }, [navigate])

    if (!isLoading && (isError || data?.getStaticPage === null)) {
        throw new Error("Error while fetching data")
    }
    useMixpanelPageOpen()
    useEffect(() => {
        // add open property to the first <details> tag
        document.querySelectorAll("details")?.[0]?.setAttribute("open", "")
    }, [data])

    return (
        <div className="mt-10 flex justify-center">
            {isLoading && <Spin className="mt-56" size="large" />}
            {!isLoading && !isError && data?.getStaticPage !== null && (
                <div className={style.notionPageContainer}>
                    {props.showLogo && (
                        <div className=" mb-20">
                            <Logo />
                        </div>
                    )}
                    <div className={style.titleContainer}>
                        {props.showLeftArrow && (
                            <VaesButton
                                onClick={handleGoBack}
                                icon={<ArrowLeftOutlined />}
                                mpBtnName="GoBack"
                            />
                        )}
                        <h1 className={style.uploadTitle}>{props.pageName}</h1>
                    </div>
                    {data?.getStaticPage && (
                        <NotionRenderer
                            recordMap={JSON.parse(data?.getStaticPage)}
                            fullPage={false}
                            darkMode={false}
                            bodyClassName="roboto-Regular notion-override"
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default Static
