import { useMutation, useQuery } from "react-query"
import { glClient } from "./client/graphqlClient"

export const listSubjectTagsQueryDetails = () => ({
    queryKey: ["subject-tags", "list", "all"],
    queryFn: () => {
        return glClient.query({
            listAllSubjectTags: {
                id: true,
                name: true,
            },
        })
    },
})

export function useAllSubjectTags() {
    const details = listSubjectTagsQueryDetails()

    return useQuery(details.queryKey, details.queryFn, {
        staleTime: Infinity,
        select: (data) => data.listAllSubjectTags,
    })
}

export function useCreateSubjectTag() {
    return useMutation({
        mutationFn: (payload: { name: string }) => {
            return glClient.mutation({
                createSubjectTag: [payload, { id: true }],
            })
        },
    })
}

export function useUpdateSubjectTag() {
    return useMutation({
        mutationFn: (payload: { id: number; name: string }) => {
            return glClient.mutation({
                updateSubjectTag: [payload, { id: true }],
            })
        },
    })
}

export function useAssignTagsToSubjects() {
    return useMutation({
        mutationFn: (payload: { subjectIds: number[]; tagIds: number[] }) => {
            return glClient.mutation({
                assignTagsToSubjects: [payload],
            })
        },
    })
}
