import { useMutation } from "react-query"
import { glClient } from "./client/graphqlClient"
import { useEffect, useState } from "react"

export const useGenerateAssetURL = () => {
    return useMutation({
        mutationFn: (payload: { filename: string }) => {
            return glClient.mutation({
                generateAssetsUploadURL: [
                    payload,
                    {
                        s3key: true,
                        url: true,
                        filename: true,
                        downloadUrl: true,
                    },
                ],
            })
        },
    })
}

// TOOD: convert to mutation
export const useUploadAssetURL = (filename: string) => {
    const generate = useGenerateAssetURL()
    const [data, setData] = useState<{ url: string; s3key: string }>()

    useEffect(() => {
        generate.mutateAsync({ filename }).then((res) => {
            // TODO: find why all values from the api are not guaranteed
            if (
                res.generateAssetsUploadURL?.url &&
                res.generateAssetsUploadURL?.s3key
            ) {
                setData({
                    url: res.generateAssetsUploadURL.url,
                    s3key: res.generateAssetsUploadURL.s3key,
                })
            }
        })
    }, [filename])

    return data
}
