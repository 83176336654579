import React from "react"
import UserProfile from "./partials/UserProfile/UserProfile"

function ProfileSetting() {
    return (
        <>
            <UserProfile />
        </>
    )
}

export default ProfileSetting
