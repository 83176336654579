import { useMutation, useQuery } from "react-query"

import { glClient } from "./client/graphqlClient"

export const useGetAllProjectLabels = (
    projectId: number,
    tags?: number[] | null
) => {
    return useQuery(
        ["project_subjectList", projectId, tags?.length ? tags : "all"],
        () =>
            glClient.query({
                listProjectSubjects: [
                    {
                        projectId: projectId,
                        tags: tags?.length ? tags : undefined,
                    },
                    {
                        project: { id: true },
                        subject: { name: true, id: true },
                        priority: true,
                        unit: { key: true, type: true, value: true },
                        tags: { id: true, name: true },
                    },
                ],
            }),
        {
            select: (data) =>
                data.listProjectSubjects?.map((s) => ({
                    ...s,
                    name: s.subject.name,
                    id: s.subject.id,
                    subject: undefined,
                })),
        }
    )
}

/** add list of subjects on a project */
export function useAddProjectLabels() {
    return useMutation({
        mutationFn: (payload: { projectId: number; subjectIds: number[] }) => {
            return glClient.mutation({
                addProjectSubjects: [
                    payload,
                    {
                        project: { id: true },
                    },
                ],
            })
        },
    })
}

/**update the project subject priority or unit */
export function useUpdateProjectLabels() {
    return useMutation({
        mutationFn: (payload: {
            projectId: number
            subjectId: number
            priority: number
            unit: string
        }) => {
            return glClient.mutation({
                updateProjectSubjects: [
                    payload,
                    {
                        project: { id: true },
                    },
                ],
            })
        },
    })
}

/** remove a subject from a project subject table */
export function useremoveProjectLabel() {
    return useMutation({
        mutationFn: (payload: { projectId: number; subjectId: number }) => {
            return glClient.mutation({
                deleteProjectSubject: [
                    payload,
                    {
                        project: { id: true },
                        subject: { id: true },
                    },
                ],
            })
        },
    })
}

/**list the project labels not used in original design */
export const listAvailibleProjectSubjects = (
    projectId: number,
    filter: {
        projectPartId: number
        tags?: number[] | null
    }
) => {
    return useQuery(
        [
            "project_subjectList",
            projectId,
            filter.tags?.length ? filter.tags : "all",
            filter.projectPartId,
        ],
        () =>
            glClient.query({
                listAvailibleProjectSubjects: [
                    {
                        projectId: projectId,
                        projectPartId: filter.projectPartId,
                        tags: filter.tags?.length ? filter.tags : undefined,
                    },
                    {
                        project: { id: true },
                        subject: { name: true, id: true },
                        priority: true,
                        unit: { key: true, type: true, value: true },
                        tags: { id: true, name: true },
                    },
                ],
            })
    )
}
