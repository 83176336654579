import { useEffect } from "react"
import mixpanel from "mixpanel-browser"
import { useCurrentUser } from "../../api/user"
import { usePageName, getPageUrl } from "../helpers/general-helpers"

/**
 *  A hook that will send mixpanel event when page is opened .
 * @param message The message to display to the user before they leave the page.
 */
function useMixpanelPageOpen() {
    const { data: user } = useCurrentUser()
    const pageName = usePageName()
    const currentWebSite = getPageUrl()

    useEffect(() => {
        if (pageName) {
            mixpanel.track("Page Open", {
                PageName: pageName,
                Email: user?.currentUser?.email,
                Position: user?.currentUser?.title,
                Company: user?.currentUser?.company?.name,
                CurrentWebSite: currentWebSite,
            })
        }
    }, [pageName])
}

export default useMixpanelPageOpen
