import React, { useCallback, useMemo } from "react"
import { Collapse, Modal, Space } from "antd"
import classNames from "classnames"
import { useParams } from "react-router-dom"
import useMediaQuery from "../../../lib/hooks/useMediaQuery"
import ReportBodyMobile from "../../../components/ReportIterations/partial/ReportBodyMobile/ReportBodyMobile"
import VaesButton from "../../../components/VaesButton/VaesButton"
import {
    useOnPackageDownload,
    useVaEReport,
} from "../../../api/vaeDetailedReport"
import { useGetProjectInfo } from "../../../api/project"
import {
    useProjectPartOriginalData,
    useProjectPartPublishedOriginalData,
} from "../../../api/report"
import { downloadFile } from "../../../lib/helpers/general-helpers"
import ReportBody, {
    DetailsType,
} from "../../../components/ReportIterations/partial/ReportBody/ReportBody"
import { useTranslation } from "react-i18next"
const Panel = Collapse.Panel

const PackageHeader = (props: {
    packageId: number
    name: string
    isPublished: boolean
    onDownload: () => void
}) => {
    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
                {/* Name */}
                <h4 className={classNames(" roboto-medium  m-0", "text-xl")}>
                    {props.name}
                </h4>
            </div>
            <Space>
                <VaesButton
                    mpBtnName="detailed-package-download"
                    className="bg-black text-white w-full"
                    onClick={props.onDownload}
                >
                    Download package
                </VaesButton>
            </Space>
        </div>
    )
}

type ImageType = { url: string }

const DetailedPackage = (props: {
    data: {
        imageUrl?: ImageType
        originalDesignImageUrl?: ImageType
        gifImgUrl?: ImageType
        id: number
        description?: string
        advantages?: string
        details?: DetailsType[]
        name?: string
        isPublished: boolean
        packageURL?: string
        isDownloaded?: boolean
        projectPartId?: number
    }
}) => {
    // react router
    const params = useParams<{ projectId: string }>()
    const matches = useMediaQuery("(min-width: 992px)")

    // TODO: remove translation name spaces
    const { t: translate } = useTranslation("translation")
    const t = (key: string) => translate(key, { ns: "translation" })

    // api
    const onDownloadPackage = useOnPackageDownload()
    const projectQuery = useGetProjectInfo(Number(params.projectId))

    const originalMainData = useProjectPartOriginalData(
        Number(props.data.projectPartId)
    )

    const originalPublishData = useProjectPartPublishedOriginalData(
        "package",
        Number(props.data.projectPartId),
        props.data.id,
        Number(params.projectId)
    )

    const originalData = props.data?.isPublished
        ? originalPublishData
        : originalMainData
    /**
     * private function to show a modal that blocks download a demo projects packages
     */
    const showDownloadDemoModal = useCallback(() => {
        // download model to prevent download in demo projects
        Modal.warning({
            icon: <></>,
            title: t("project.downloadReport.downloadPopupTitle"),
            content: t("project.downloadReport.downloadPopupContent"),
        })

        return
    }, [t])

    /**
     * handle download single package
     */
    const handleDownloadClicked = useCallback(() => {
        if (projectQuery.data?.getProject?.isDemo) {
            showDownloadDemoModal()
            return
        }

        if (props.data.packageURL) {
            downloadFile(props.data.packageURL)
            // notify
            if (!props.data.isDownloaded) {
                onDownloadPackage.mutate({
                    packageId: props.data.id,
                    projectId: Number(params.projectId),
                    all: false,
                })
            }
        }
    }, [
        onDownloadPackage,
        params.projectId,
        projectQuery.data?.getProject?.isDemo,
        props.data.id,
        props.data.isDownloaded,
        props.data.packageURL,
        showDownloadDemoModal,
    ])

    return (
        <Panel
            key={props.data.id}
            header={
                <PackageHeader
                    packageId={Number(props.data.id)}
                    name={props.data.name as string}
                    isPublished={props.data.isPublished}
                    onDownload={handleDownloadClicked}
                />
            }
            className="shadow-md px-11 py-9 border border-solid border-gray-150 my-6"
            forceRender={true}
            id={`downloadreport${props.data.id}`}
        >
            {matches ? (
                <ReportBody
                    origenalImg={props.data.originalDesignImageUrl?.url}
                    veImg={props.data.imageUrl?.url}
                    veGif={props.data.gifImgUrl?.url}
                    description={props.data.description}
                    advantage={props.data.advantages}
                    details={props.data.details}
                    originalDetails={originalData.data}
                    totalColor="text-green-500"
                />
            ) : (
                <ReportBodyMobile
                    origenalImg={props.data.originalDesignImageUrl?.url}
                    veImg={props.data.imageUrl?.url}
                    veGif={props.data.gifImgUrl?.url}
                    description={props.data.description}
                    advantage={props.data.advantages}
                    details={props.data.details}
                    originalDetails={originalData.data}
                    totalColor="text-green-500"
                />
            )}
        </Panel>
    )
}

export const PackageDetailsList = () => {
    // react router
    const params = useParams<{ projectId: string }>()

    // api
    const vaeQuery = useVaEReport(Number(params.projectId))
    const packages = useMemo(() => {
        return vaeQuery.data?.map((d) => (
            <DetailedPackage
                key={d.id}
                data={{
                    name: d.name,
                    details: d.details?.map((row) => ({
                        ...row,
                        value: row.value,
                        value2: row.value2,
                    })),
                    imageUrl: d.image,
                    originalDesignImageUrl: d.originalDesignImage,
                    isPublished: d.isPublished,
                    advantages: d.advantages,
                    description: d.description,
                    packageURL: d.package?.url,
                    id: d.id,
                    projectPartId: d.projectPartId,
                }}
            />
        ))
    }, [vaeQuery.data])

    if (!packages) return null

    return <>{packages}</>
}
