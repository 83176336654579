import { useMutation, useQuery } from "react-query"
import { glClient } from "./client/graphqlClient"
import { VaePackageInput } from "@vaes-dashboard-2/graphql/genql"

export function useCreateVaEDetailedReport() {
    return useMutation({
        mutationFn: (payload: {
            package: VaePackageInput
            projectId: number
            projectPartId: number
        }) => {
            return glClient.mutation({
                createVaEPackage: [payload, { id: true }],
            })
        },
    })
}
export function useRemoveVaEPackage() {
    return useMutation({
        mutationFn: (payload: { packageId: number }) => {
            return glClient.mutation({
                removeVaEPackage: [payload],
            })
        },
    })
}

export function useUpdateVaEDetailedReport() {
    return useMutation({
        mutationFn: (payload: {
            package: VaePackageInput
            projectId: number
            packageId: number
        }) => {
            return glClient.mutation({
                updateVaEDetailedPackage: [payload, { id: true }],
            })
        },
    })
}

export const getVaEQueryDetails = (projectId: number) => ({
    queryKey: ["vae-report", projectId, "list"],
    queryFn: () => {
        return glClient.query({
            getVaEPackages: [
                { projectId, latestVersion: true },
                {
                    id: true,
                    name: true,
                    description: true,
                    savings: true,
                    summary: true,
                    advantages: true,
                    isPublished: true,
                    isDownloaded: true,
                    isViewed: true,
                    projectPartId: true,
                    parentId: true,
                    originalParentId: true,
                    createdAt: true,
                    updatedAt: true,
                    updatedBy: {
                        name: true,
                    },
                    createdBy: {
                        name: true,
                    },
                    versions: {
                        id: true,
                        name: true,
                        isPublished: true,
                        createdAt: true,
                    },
                    details: {
                        id: true,
                        subject: {
                            name: true,
                            id: true,
                            typeKey: {
                                value: true,
                            },
                        },
                        value: true,
                        value2: true, //secondary column added for range cases to hold the second value
                    },
                    package: {
                        s3key: true,
                        url: true,
                    },
                    gifImage: {
                        url: true,
                        s3key: true,
                    },
                    image: {
                        url: true,
                        s3key: true,
                    },
                    originalDesignImage: {
                        url: true,
                        s3key: true,
                    },
                },
            ],
        })
    },
})

export function useVaEReport(projectId: number, enabled = true) {
    const details = getVaEQueryDetails(projectId)

    return useQuery(details.queryKey, details.queryFn, {
        select: (data) => data?.getVaEPackages,
        enabled,
    })
}

export const getVaEPackageQueryDetails = (
    projectId: number,
    packageId: number
) => ({
    queryKey: ["vae-report", projectId, "package", packageId],
    queryFn: () => {
        return glClient.query({
            getVaEPackage: [
                { projectId, packageId },
                {
                    id: true,
                    parentId: true,
                    projectPart: {
                        name: true,
                        id: true,
                    },
                    name: true,
                    description: true,
                    savings: true,
                    summary: true,
                    advantages: true,
                    isPublished: true,
                    isDownloaded: true,
                    isViewed: true,
                    projectPartId: true,
                    createdAt: true,
                    updatedAt: true,
                    updatedBy: {
                        name: true,
                    },
                    createdBy: {
                        name: true,
                    },
                    versions: {
                        id: true,
                        name: true,
                        isPublished: true,
                        createdAt: true,
                    },
                    details: {
                        id: true,
                        subject: {
                            name: true,
                            id: true,
                            typeKey: {
                                value: true,
                            },
                        },
                        value: true,
                        value2: true, //secondary column added for range cases to hold the second value
                    },
                    package: {
                        s3key: true,
                        url: true,
                    },
                    gifImage: {
                        url: true,
                        s3key: true,
                    },
                    image: {
                        url: true,
                        s3key: true,
                    },
                    originalDesignImage: {
                        url: true,
                        s3key: true,
                    },
                },
            ],
        })
    },
})

export function useVaEPackage(projectId: number, packageId = -1) {
    const details = getVaEPackageQueryDetails(projectId, packageId)

    return useQuery(details.queryKey, details.queryFn, {
        select: (data) => data?.getVaEPackage,
        enabled: packageId !== -1,
    })
}

export function usePublishVaEPackage() {
    return useMutation({
        mutationFn: (payload: { projectId: number; packageId: number }) => {
            return glClient.mutation({
                publishVaePackage: [payload, { id: true, name: true }],
            })
        },
    })
}

export function useOnPackageDownload() {
    return useMutation({
        mutationFn: (payload: {
            projectId: number
            packageId?: number
            all: boolean
        }) => {
            return glClient.mutation({
                onPackageDownload: [payload],
            })
        },
    })
}

/**to check if there are missing images or details */
export const isPackageValidForPublish = (packageId: number) => {
    return useQuery(["package", "publishCheck", packageId], () =>
        glClient.query({
            isPackageValidForPublish: [{ id: packageId }],
        })
    )
}

/**to check if there are missing images or details */
export const checkMissingVaePackageTotalcost = (packageId: number) => {
    return useQuery(["package", "TotalcostCheck", packageId], () =>
        glClient.query({
            checkMissingVaePackageTotalcost: [{ id: packageId }],
        })
    )
}
