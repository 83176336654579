import React from "react"
import { Divider, Empty } from "antd"
import classNames from "classnames"
import { ProjectPart } from "./PartsList"

interface PartItemProps {
    part: ProjectPart
}

const PartItem = (props: PartItemProps) => {
    return (
        <li
            key={props.part.id}
            className={classNames(
                "flex justify-between items-center px-4 py-2",
                {
                    "bg-gray-100": props.part.isArchived,
                }
            )}
        >
            <span
                className={classNames("max-w-xl font-robotoMedium", {
                    "bg-gray-100 text-gray-500": props.part.isArchived,
                })}
            >
                {props.part.name}
            </span>
        </li>
    )
}

export const ViewModePartsList = (props: { parts?: ProjectPart[] }) => {
    const partsItems = props.parts?.map((part, index) => (
        <li key={part.id}>
            {index !== 0 && <Divider className="my-0" />}
            <PartItem part={part} />
        </li>
    ))

    return (
        <div>
            <ul className="p-0 list-none">{partsItems}</ul>
            {!partsItems?.length && <Empty description="No parts found" />}
        </div>
    )
}
