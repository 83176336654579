import React from "react"
import { Input, Form } from "antd"
import { useForm } from "antd/es/form/Form"
import VaesButton from "../../../../components/VaesButton/VaesButton"
import { queryClient } from "../../../../api/client/reactQuery"
import { useUpdateSubjectTag } from "../../../../api/subjectTags"

export const EditSubjectTag = (props: {
    id: number
    onSuccess: () => void
}) => {
    const create = useUpdateSubjectTag()

    const [form] = useForm()

    /**
     * Submit the form and create a new report
     * @param values
     */
    const onFinish = (values: { id: number; name: string }) => {
        create.mutate(
            {
                id: props.id,
                name: values.name,
            },
            {
                onSuccess: () => {
                    form.resetFields()
                    queryClient.invalidateQueries(["subject-tags", "list"])
                    queryClient.invalidateQueries(["report", "subjects"])
                    props.onSuccess()
                },
            }
        )
    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            validateTrigger="onBlur"
        >
            <div className="flex justify-between gap-2">
                <Form.Item
                    name="name"
                    rules={[{ required: true }]}
                    className="flex-grow"
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <VaesButton
                        type="primary"
                        htmlType="submit"
                        loading={create.isLoading}
                        block
                        mpBtnName="update subject tag"
                    >
                        Save
                    </VaesButton>
                </Form.Item>
            </div>
        </Form>
    )
}
