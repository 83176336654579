import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import commonTranslationEN from "./locales/en/translation.json"
import commonEN from "./locales/en/common.json"

const resources = {
    en: {
        common: commonEN,
        translation: commonTranslationEN,
    },
}

i18n.use(initReactI18next).init({
    resources,
    defaultNS: "common",
    lng: "en",
    ns: ["common", "translation"],
    fallbackLng: "en",
    debug: true,
    interpolation: {
        escapeValue: false,
    },
})

export default i18n
