import { Col, Input, Row } from "antd"
import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useDebounce } from "../../../lib/hooks/useDebounce"
import MissingFilesTagFilter from "./MissingFilesTagFilter"

const MissingFilesFilter = () => {
    const { Search } = Input
    const [searchParams, setSearchParams] = useSearchParams()
    const querySearch = searchParams.get("search")
    const [searchText, setSearchText] = useState(querySearch)
    const debouncedSearchText = useDebounce(searchText)

    const onSearch = (value: string) => {
        setSearchText(value)
    }

    /**
     * update search params text based on debounced text value
     */
    useEffect(() => {
        // avoid first render where debouncedSearchText is equal to querySearch
        if (
            debouncedSearchText === querySearch ||
            debouncedSearchText === searchText
        )
            return setSearchParams((prev) => ({
                ...Object.fromEntries(prev),
                search: debouncedSearchText || "",
                page: "1", // reset page on new search
            }))
    }, [debouncedSearchText])

    return (
        <Row gutter={[16, 16]} className="mb-4">
            <Col span={14}>
                <Search
                    placeholder="input search text"
                    onSearch={onSearch}
                    defaultValue={querySearch || undefined}
                />
            </Col>
            <Col span={13}>
                <MissingFilesTagFilter />
            </Col>
        </Row>
    )
}
export default MissingFilesFilter
