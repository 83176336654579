import React, { useMemo } from "react"
import { Select } from "antd"
import { useListAllProjectParts } from "../../api/report"

const SelectProjectPart = (props: {
    projectId: number
    onChange?: (partId: number) => void
    value?: number
}) => {
    // api
    const partsQuery = useListAllProjectParts(props.projectId)

    /**
     * parse countries options
     */
    const projectPartsList = useMemo(() => {
        return partsQuery.data?.map((item) => (
            <Select.Option key={item.id} value={item.id}>
                {item.name}
            </Select.Option>
        ))
    }, [partsQuery.data])

    return (
        <Select
            placeholder="Please select the project part"
            showSearch
            className="min-w-[300px]"
            onSelect={props.onChange}
            value={props.value}
        >
            {projectPartsList}
        </Select>
    )
}

export default SelectProjectPart
