import React from "react"
import { Col, Row } from "antd"
import Logo from "../../../Logo/Logo"
import { Header } from "antd/es/layout/layout"
import { SettingsMenuAdmin } from "../../../SettingsMenu/SettingsMenu"
import { Breadcrumb } from "antd"
import { Link, Params, useMatches } from "react-router-dom"
import style from "../AdminLayout.module.scss"

/**
 * Render breadcrumbs based on the current route
 */
interface breadCrumbObject {
    name: string
    path?: (data: unknown) => string
}

interface IMatches {
    id: string
    pathname: string
    params: Params<string>
    data: unknown
    handle: unknown
}

type HandleType = {
    crumb: (param?: string) => React.ReactNode
    isDisabled?: boolean
}

export function Breadcrumbs() {
    const matches: IMatches[] = useMatches()
    let arrayOfCrumbs: breadCrumbObject[] = []
    const crumbs = matches
        // first get rid of any matches that don't have handle and crumb
        .filter((match) =>
            Boolean(match.handle && (match.handle as HandleType).crumb)
        )
        // now map them into an array of elements, passing the loader
        // data to each one
        .map((match, idx, arrx) => {
            const crumb = (match.handle as HandleType).crumb(
                match.data as string | undefined
            )
            // if the crumb is string change it to array
            if (typeof crumb === "string") {
                arrayOfCrumbs = [{ name: crumb }]
            } else {
                arrayOfCrumbs = crumb as breadCrumbObject[]
            }

            return arrayOfCrumbs?.map((data, idy, arry) => {
                return (
                    <Breadcrumb.Item
                        key={
                            data.path ? data.path(match.params) : match.pathname
                        }
                    >
                        {
                            // last crumb should not be a link
                            (idy === arry.length - 1 &&
                                idx === arrx.length - 1) ||
                            (match.handle as HandleType).isDisabled ? (
                                data.name
                            ) : (
                                <Link
                                    to={
                                        data?.path
                                            ? data?.path(match.params)
                                            : match.pathname
                                    }
                                >
                                    {data.name}
                                </Link>
                            )
                        }
                    </Breadcrumb.Item>
                )
            })
        })

    // if we only have single crumb, don't render breadcrumbs
    if (crumbs.length <= 1) {
        return null
    }

    return (
        <div className="pl-12 pt-4">
            <Breadcrumb>{crumbs}</Breadcrumb>
        </div>
    )
}

export const AdminHeader = () => {
    return (
        <Header className="!h-full">
            <Row
                justify="space-between"
                align="middle"
                className={style.navbar}
            >
                <Col span={8}>
                    <div className="flex items-center gap-5">
                        <div className={style.logo}>
                            <Logo to="/admin" white />
                        </div>
                        <span className="text-xl text-white opacity-80 font-robotoLight">
                            Engineer’s portal
                        </span>
                    </div>
                </Col>

                <Col>
                    <SettingsMenuAdmin />
                </Col>
            </Row>
        </Header>
    )
}
