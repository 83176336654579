import React from "react"
import { Form } from "antd"
import { useForm } from "antd/es/form/Form"
import VaesButton from "../../../../components/VaesButton/VaesButton"
import { queryClient } from "../../../../api/client/reactQuery"
import { useAssignTagsToSubjects } from "../../../../api/subjectTags"
import { SelectTags } from "./SelectTags"

export const AssignTags = (props: {
    selectedSubjectIds: number[]
    onSuccess: () => void
}) => {
    const assign = useAssignTagsToSubjects()

    const [form] = useForm()

    /**
     * Submit the form and create a new report
     * @param values
     */
    const onFinish = (values: { tags: number[] }) => {
        assign.mutate(
            {
                subjectIds: props.selectedSubjectIds,
                tagIds: values.tags,
            },
            {
                onSuccess: () => {
                    form.resetFields()
                    queryClient.invalidateQueries(["report", "subjects"])
                    props.onSuccess()
                },
            }
        )
    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            validateTrigger="onBlur"
        >
            <Form.Item name="tags">
                <SelectTags />
            </Form.Item>

            <Form.Item>
                <VaesButton
                    type="primary"
                    htmlType="submit"
                    loading={assign.isLoading}
                    block
                    mpBtnName="assign tags to subjects"
                >
                    Assign
                </VaesButton>
            </Form.Item>
        </Form>
    )
}
