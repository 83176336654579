/*********************************************************************************************************************
 * Developed By HArtha Aloufi
 * Date: 2023-25-10
 * Description: Simple UI component that renders publish, create and update dates
 *********************************************************************************************************************/

import React from "react"
import { Space, Typography } from "antd"
import dayjs from "dayjs/esm/index.js"
import utc from "dayjs/esm/plugin/utc"
import { User } from "@vaes-dashboard-2/graphql/genql"

dayjs.extend(utc)

type Audit = {
    createdAt: string
    createdBy?: Partial<User>
    publishedAt?: string | null
    publishedBy?: Partial<User> | null
    updatedAt?: string | null
    updatedBy?: Partial<User> | null
}

export const Audits = (props: Audit) => {
    return (
        <Space size="large">
            <div>
                <Typography.Text
                    type="secondary"
                    className="text-xs font-robotoMedium"
                >
                    Created at:{" "}
                    {dayjs
                        .utc(props.createdAt)
                        .local()
                        .format("MM/DD/YY hh:mm A")}
                </Typography.Text>
                <br />
                <Typography.Text
                    type="secondary"
                    className="text-xs font-robotoMedium"
                >
                    Created by: {props.createdBy?.name}
                </Typography.Text>
            </div>
            {props.updatedAt && (
                <>
                    <div>
                        <Typography.Text
                            type="secondary"
                            className="text-xs font-robotoMedium"
                        >
                            Last edited at:{"  "}
                            {dayjs
                                .utc(props.updatedAt)
                                .local()
                                .format("MM/DD/YY hh:mm A")}
                        </Typography.Text>
                        <br />
                        <Typography.Text
                            type="secondary"
                            className="text-xs font-robotoMedium"
                        >
                            Last edited by: {props.updatedBy?.name}
                        </Typography.Text>
                    </div>
                </>
            )}
            {props.publishedAt && (
                <>
                    <div>
                        <Typography.Text
                            type="secondary"
                            className="text-xs font-robotoMedium"
                        >
                            Published at:{"  "}
                            {dayjs
                                .utc(props.publishedAt)
                                .local()
                                .format("MM/DD/YY hh:mm A")}
                        </Typography.Text>
                        <br />
                        <Typography.Text
                            type="secondary"
                            className="text-xs font-robotoMedium"
                        >
                            Published by: {props.publishedBy?.name}
                        </Typography.Text>
                    </div>
                </>
            )}
        </Space>
    )
}
