import { createCancelableGlClient, glClient } from "./client/graphqlClient"
import { useMutation, useQuery } from "react-query"
import { queryClient } from "./client/reactQuery"
import { ID } from "@vaes-dashboard-2/graphql/types"

/**
 * Create a project file record on the db and return  upload url baesd on the s3 key
 */
export function useCreateProjectFile() {
    const { client, controller } = createCancelableGlClient()

    const createFileMutation = useMutation({
        mutationFn: (payload: {
            filename: string
            projectId?: number
            sessionId?: number | null
        }) => {
            return client.mutation({
                createProjectFile: [
                    payload,
                    {
                        id: true,
                        uploadURL: true,
                        s3Key: true,
                    },
                ],
            })
        },
    })

    return { createFile: createFileMutation, controller }
}

/**
 * In case we don't know the project id, we can create a session and then assign the session to
 * the project when we know it.
 */
export function useCreateFilesSession() {
    return useMutation({
        mutationFn: () => {
            return glClient.mutation({
                createFilesSession: {
                    id: true,
                },
            })
        },
    })
}

/**
 * Upload file to a specific url
 */
export function useUploadFile() {
    return useMutation({
        retry: 3,
        mutationFn: (param: {
            file: File | Blob
            uploadUrl: string
            controller?: AbortController
        }) => {
            const data = new FormData()
            data.append("file", param.file)

            return fetch(param.uploadUrl, {
                method: "PUT",
                body: param.file,
                signal: param.controller?.signal,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then((res) => {
                    // We need to return the ETag header to complete the multi part upload
                    return res.headers.get("ETag")
                })
                .catch((e) => {
                    if (param.controller?.signal.aborted) {
                        // do nothing
                    } else {
                        throw e
                    }
                })
        },
        onError: () => {
            // do nothing, override the default error handling
            // to prevent showing many error messages for each part
        },
    })
}

/**
 * Assign a files with a session id to a project
 */
export function useAssignFileToProject() {
    return useMutation({
        mutationFn: (payload: { sessionId: number; projectId: number }) => {
            return glClient.mutation({
                assignFileToProject: [
                    payload,
                    {
                        id: true,
                    },
                ],
            })
        },
    })
}

/**
 *  Generate a multi part upload url for a specific s3 key
 */
export function useMultiPartUpload() {
    const { client, controller } = createCancelableGlClient()

    const generateMultiPartURLs = useMutation({
        mutationFn: (payload: { s3Key: string; partsCount: number }) => {
            return client.mutation({
                generateMultiPartUploadURL: [
                    payload,
                    {
                        uploadId: true,
                        multipartUploadUrls: true,
                    },
                ],
            })
        },
    })

    return { generateMultiPartURLs, controller }
}

/**
 *  Generate a multi part upload url for a specific s3 key
 */
export function useCompleteMultiPartUpload() {
    return useMutation({
        mutationFn: (payload: {
            s3Key: string
            uploadId: string
            completedParts: { ETag: string; PartNumber: number }[]
        }) => {
            return glClient.mutation({
                completeMultipartUpload: [payload],
            })
        },
    })
}

/**
 *  Generate a multi part upload url for a specific s3 key
 */
export function useAbortMultiPartUpload() {
    return useMutation({
        mutationFn: (payload: { s3Key: string; uploadId: string }) => {
            return glClient.mutation({
                abortMultipartUpload: [payload],
            })
        },
    })
}

/**
 * Log file upload to the db
 */
export function logUpload(payload: {
    name: string
    totalSize: number
    filesCount: number
    isFile: boolean
    projectFileIds: number[]
    projectId?: number
    sessionId?: number
}) {
    return glClient.mutation({
        logUpload: [payload, { id: true }],
    })
}

const getProjectUploadLogsQueryDetails = (projectId: ID) => ({
    queryKey: ["fileUploadLog", projectId],
    queryFn: () => {
        return glClient.query({
            listProjectUploadLogs: [
                { projectId: Number(projectId) },
                {
                    name: true,
                    totalSize: true,
                    filesCount: true,
                    isFile: true,
                    id: true,
                    isDownloaded: true,
                    createdBy: {
                        name: true,
                        roleName: true,
                    },
                    createdAt: true,
                },
            ],
        })
    },
})

export function loadProjectUploadLogs(projectId: number) {
    const query = getProjectUploadLogsQueryDetails(projectId)
    type QueryType = Awaited<ReturnType<typeof query.queryFn>>

    return (
        queryClient.getQueryData<QueryType>(query.queryKey) ??
        queryClient.fetchQuery(query)
    )
}

export function useProjectUploadLogs(projectId: ID) {
    const query = getProjectUploadLogsQueryDetails(projectId)

    return useQuery(query.queryKey, query.queryFn)
}

/**
 *  Get presigned url for a files
 */
export function useFilesUrlsByUploadLogIds() {
    return useMutation({
        mutationFn: (payload: { uploadLogIds: number[] }) => {
            return glClient
                .mutation({
                    getFilesUrlsByUploadLogIds: [
                        payload,
                        { url: true, s3key: true },
                    ],
                })
                .then((res) => res.getFilesUrlsByUploadLogIds)
        },
    })
}

export function useMarkFilesAsDownloaded() {
    return useMutation({
        mutationFn: (payload: { logIds: number[] }) => {
            return glClient
                .mutation({
                    markFilesAsDownloaded: [payload, { id: true }],
                })
                .then((res) => res.markFilesAsDownloaded)
        },
    })
}

// add an link to upload files from
export function useAddUploadLink() {
    return useMutation({
        mutationFn: (payload: {
            linkUrl: string
            projectId: number | null
        }) => {
            return glClient.mutation({
                addUploadLink: [
                    payload,
                    {
                        id: true,
                    },
                ],
            })
        },
    })
}

export const getProjectUploadLinks = (projectId: number) => {
    return glClient.query({
        listUploadLinks: [
            { projectId: projectId },
            {
                id: true,
                linkUrl: true,
                project: { id: true },
                isDownloaded: true,
                createdBy: {
                    name: true,
                    roleName: true,
                },
                createdAt: true,
                downloadedBy: {
                    name: true,
                    roleName: true,
                },
                downloadedAt: true,
            },
        ],
    })
}

//list upload links based on project id
export const useGetProjectUploadLinks = (projectId: number) => {
    return useQuery(["fileUploadlink", projectId], () =>
        getProjectUploadLinks(projectId)
    )
}

//assigne uploaded link from homepage to a project
export function useAssignLinkToProject() {
    const mutationFn = (payload: { id: number; projectId: number }) => {
        return glClient.mutation({
            assignToProject: [
                payload,
                {
                    id: true,
                },
            ],
        })
    }

    return useMutation<
        Awaited<ReturnType<typeof mutationFn>>,
        Error,
        Parameters<typeof mutationFn>[0]
    >({
        mutationFn,
    })
}

//mark as downloaded link
export function useDownloadLinks() {
    return useMutation({
        mutationFn: (payload: { id: number[]; projectId: number }) => {
            return glClient.mutation({
                downloadLink: [
                    payload,
                    {
                        id: true,
                    },
                ],
            })
        },
    })
}
