// local storage keys type
import { ErrorCode } from "@vaes-dashboard-2/graphql"
import {
    VAES_USER_ACCESS_TOKEN_KEY,
    VAES_USER_PREVIEW_TOKEN_KEY,
    VAES_USER_REFRESH_TOKEN_KEY,
    VAES_USER_REMEMBER_ME,
} from "../../lib/constants"

export type SessionStorageKeys =
    | typeof VAES_USER_ACCESS_TOKEN_KEY
    | typeof VAES_USER_REFRESH_TOKEN_KEY
    | typeof VAES_USER_PREVIEW_TOKEN_KEY

export type LocalStorageKeys = SessionStorageKeys | typeof VAES_USER_REMEMBER_ME

export class ErrorWithStatus extends Error {
    status: number
    code?: ErrorCode
    constructor(message: string, status: number) {
        super(message)
        this.status = status
    }
}
