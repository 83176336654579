import React, { useMemo } from "react"
import { Input, Form, Space, Empty } from "antd"
import { useForm } from "antd/es/form/Form"
import VaesButton from "../../../../components/VaesButton/VaesButton"
import { Tag } from "antd"
import { queryClient } from "../../../../api/client/reactQuery"
import {
    useAllSubjectTags,
    useCreateSubjectTag,
} from "../../../../api/subjectTags"

export const ReportTagList = (props: { onClick: (id: number) => void }) => {
    const tagsQuery = useAllSubjectTags()

    const tags = useMemo(() => {
        return tagsQuery.data?.map((t) => (
            <Tag
                key={t.id}
                className="cursor-pointer"
                onClick={() => props.onClick(t.id || -1)}
            >
                {t.name}
            </Tag>
        ))
    }, [tagsQuery.data])

    if (tags?.length === 0) return <Empty description="No tags found" />

    return (
        <Space wrap size={[1, 8]}>
            {tags}
        </Space>
    )
}

export const OrganizeSubjectTags = (props: {
    onTagClick: (id: number) => void
}) => {
    const create = useCreateSubjectTag()

    const [form] = useForm()

    /**
     * Submit the form and create a new report
     * @param values
     */
    const onFinish = (values: { name: string }) => {
        create.mutate(
            {
                name: values.name,
            },
            {
                onSuccess: () => {
                    form.resetFields()
                    queryClient.invalidateQueries(["subject-tags", "list"])
                },
            }
        )
    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            validateTrigger="onBlur"
        >
            <div className="flex justify-between gap-2">
                <Form.Item
                    name="name"
                    rules={[{ required: true }]}
                    className="flex-grow"
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <VaesButton
                        type="primary"
                        htmlType="submit"
                        loading={create.isLoading}
                        block
                        mpBtnName="Add new title tag"
                    >
                        Add
                    </VaesButton>
                </Form.Item>
            </div>

            <ReportTagList onClick={props.onTagClick} />
        </Form>
    )
}
