import { useMutation, useQuery } from "react-query"
import { glClient } from "./client/graphqlClient"

type CreateCompanyPayload = {
    industry_key: string
    name: string
    size_key: string
    country: string
    contractor_type_key?: string
    other_type?: string
}

export function useRegisterUserToNewCompany() {
    return useMutation({
        mutationFn: (payload: CreateCompanyPayload) => {
            return glClient.mutation({
                registerUserToNewCompany: [
                    payload,
                    {
                        id: true,
                    },
                ],
            })
        },
    })
}

export function useCreateClient() {
    return useMutation({
        mutationFn: (payload: {
            company: CreateCompanyPayload
            adminEmail: string
            domain_names?: string[]
        }) => {
            return glClient.mutation({
                createClient: [
                    payload,
                    {
                        id: true,
                    },
                ],
            })
        },
    })
}

export const getCompanyDetails = (id: number) => ({
    queryKey: ["company", "data", id],
    queryFn: () => {
        return glClient.query({
            company: [
                { id },
                {
                    address: true,
                    country: true,
                    name: true,
                    phone: true,
                    industry_key: {
                        key: true,
                        type: true,
                        value: true,
                    },
                    email: true,
                    logo: true,
                    contractor_type_key: {
                        key: true,
                        type: true,
                        value: true,
                    },
                    other_type: true,
                    users: {
                        email: true,
                        name: true,
                        roleName: true,
                        title: true,
                        imageUrl: true,
                        userId: true,
                        isDeleted: true,
                        isActivated: true,
                    },
                },
            ],
        })
    },
})

export function useGetCompanyData(companyId: number) {
    const query = getCompanyDetails(companyId)
    return useQuery(query.queryKey, query.queryFn, {
        staleTime: Infinity,
    })
}

export function useUpdateCompanyData() {
    return useMutation({
        mutationFn: (payload: {
            id: number
            contractor_type_key: string
            industry_key: string
            name: string
            email: string
            address?: string
            phone?: string
            logo_key?: string
            other_type?: string
        }) => {
            return glClient.mutation({
                updateCompany: [
                    payload,
                    {
                        id: true,
                    },
                ],
            })
        },
    })
}

export function useGetUsersAtCompany(companyId: number, enabled = true) {
    const details = {
        queryKey: ["company", "users", "list", companyId],
        queryFn: () => {
            return glClient.query({
                company: [
                    { id: companyId },
                    {
                        users: {
                            userId: true,
                            name: true,
                            email: true,
                            isDeleted: true,
                            isActivated: true,
                            isNew: true,
                            roleName: true,
                            imageUrl: true,
                        },
                    },
                ],
            })
        },
    }

    return useQuery(details.queryKey, details.queryFn, {
        staleTime: Infinity,
        enabled,
    })
}

export const useListCompanies = () => {
    return useQuery({
        queryKey: ["company", "list"],
        queryFn: () => {
            return glClient.query({
                listAllCompanies: {
                    id: true,
                    name: true,
                },
            })
        },
        select: (data) => data.listAllCompanies,
    })
}

export const useListCompaniesWithProjects = () => {
    return useQuery({
        queryKey: ["company", "list", "withProjects"],
        queryFn: () => {
            return glClient.query({
                listAllAccessedCompanies: {
                    id: true,
                    name: true,
                    projects: {
                        id: true,
                        imgUrl: true,
                        name: true,
                        isDemo: true,
                        clientStatus: {
                            key: true,
                            value: true,
                        },
                        status: {
                            key: true,
                            value: true,
                        },
                        type: {
                            key: true,
                            value: true,
                        },
                        otherType: true,
                        createdAt: true,
                    },
                },
            })
        },
        select: (data) => data.listAllAccessedCompanies,
    })
}
