import React, { useCallback } from "react"
import { Col, Form, Row, Space, Spin } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import { Audits } from "../../../components/Audits/Audits"
import VaesButton from "../../../components/VaesButton/VaesButton"
import PackageForm from "../../../components/PackageForm"
import {
    useUpdateVaEDetailedReport,
    useVaEPackage,
} from "../../../api/vaeDetailedReport"
import { VaePackageInput } from "@vaes-dashboard-2/graphql/genql"
import { queryClient } from "../../../api/client/reactQuery"
import { useProjectPartOriginalData } from "../../../api/report"

export const loader = () => {
    return null
}

type FormValuesType = {
    name?: string
    description?: string
    advantages?: string
    imgKey?: { s3key: string }
    originalDesignImgKey?: { s3key: string }
    details?: {
        subjectId: number
        vaeValue: { value1: number; value2: number }
    }[]
    packageS3Key?: { s3key: string }
    summary?: string
    savings?: number
}
const VaEDetailedPackageForm = () => {
    // react router
    const params = useParams<{ projectId: string; packageId: string }>()
    const navigate = useNavigate()

    // api
    const updatePackage = useUpdateVaEDetailedReport()
    const packageQuery = useVaEPackage(
        Number(params.projectId),
        Number(params.packageId)
    )
    const OriginalData = useProjectPartOriginalData(
        Number(packageQuery.data?.projectPart?.id)
    )
    const hasOriginalData = !!OriginalData.data?.length

    // watch form values
    const [form] = Form.useForm()

    const onFinish = useCallback(
        (values: FormValuesType) => {
            const vaePackage: VaePackageInput = {
                name: values.name,
                description: values.description,
                advantages: values.advantages,
                imgKey: values.imgKey?.s3key,
                originalDesignImgKey: values.originalDesignImgKey?.s3key,
                details: values.details?.map((d) => ({
                    subjectId: d.subjectId,
                    // make sure to include empty value as null so it get removed from db
                    value: d.vaeValue?.value1 ?? null,
                    value2: d.vaeValue?.value2 ?? null,
                })),
                packageKey: values.packageS3Key?.s3key,
                summary: values.summary,
                savings: values.savings,
            }
            updatePackage.mutate(
                {
                    projectId: Number(params.projectId),
                    packageId: Number(params.packageId),
                    package: vaePackage,
                },
                {
                    onSuccess: async () => {
                        await queryClient.invalidateQueries([
                            "vae-report",
                            Number(params.projectId),
                            "list",
                        ])

                        queryClient.invalidateQueries([
                            "vae-report",
                            Number(params.projectId),
                            "package",
                            Number(params.packageId),
                        ])

                        queryClient.invalidateQueries([
                            "package",
                            "publishCheck",
                            Number(params.packageId),
                        ])

                        queryClient.invalidateQueries([
                            "package",
                            "TotalcostCheck",
                            Number(params.packageId),
                        ])

                        navigate(
                            `/admin/project/${params.projectId}/detailed-packages`
                        )
                    },
                }
            )
        },
        [navigate, params.packageId, params.projectId, updatePackage]
    )

    return (
        <>
            <Spin spinning={packageQuery.isLoading || OriginalData.isLoading}>
                {hasOriginalData &&
                    !packageQuery.isLoading &&
                    !OriginalData.isLoading && (
                        <Form
                            form={form}
                            onFinish={onFinish}
                            layout="vertical"
                            validateTrigger="onBlur"
                            rootClassName="flex flex-col h-full pt-11"
                        >
                            <PackageForm
                                projectId={Number(params.projectId)}
                                packageId={Number(params.packageId || -1)}
                                formInstance={form}
                            />

                            {packageQuery.data && (
                                <Audits
                                    createdAt={packageQuery.data.createdAt}
                                    createdBy={packageQuery.data?.createdBy}
                                    updatedAt={packageQuery.data?.updatedAt}
                                    updatedBy={packageQuery.data?.updatedBy}
                                />
                            )}

                            <Row justify={"end"}>
                                <Col>
                                    <Space className="pt-10">
                                        <VaesButton
                                            mpBtnName="edit-package-cancel"
                                            type="link"
                                            onClick={() =>
                                                navigate(
                                                    `/admin/project/${params.projectId}/detailed-packages`
                                                )
                                            }
                                        >
                                            Cancel
                                        </VaesButton>
                                        <VaesButton
                                            mpBtnName="edit-package-save"
                                            type="primary"
                                            className="min-w-[225px]"
                                            loading={updatePackage.isLoading}
                                            htmlType="submit"
                                        >
                                            Save
                                        </VaesButton>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    )}
                {!hasOriginalData &&
                    !packageQuery.isLoading &&
                    !OriginalData.isLoading && (
                        <div>
                            <p className=" text-lg roboto-medium">
                                To start adding Vae package, add original design
                                data first.
                            </p>
                            <VaesButton
                                type="primary"
                                onClick={() => {
                                    navigate(
                                        `/admin/project/${Number(
                                            params.projectId
                                        )}/report/original-design-data`
                                    )
                                }}
                            >
                                Go to original design data
                            </VaesButton>
                        </div>
                    )}
            </Spin>
        </>
    )
}

export default VaEDetailedPackageForm
