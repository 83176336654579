import React from "react"
import { Alert, ConfigProvider, notification } from "antd"
import Theme from "./lib/theme"
import { RouterProvider } from "react-router-dom"
import router from "./lib/router"

const DevServerAlert = () => {
    if (import.meta.env.VITE_SST_STAGE !== "dev") return null

    return (
        <Alert
            closable
            type="warning"
            message={
                <h3>
                    Welcome to the development server! If you&#39;re looking to
                    explore the dashboard or showcase it to a client, feel free
                    to visit{" "}
                    <a href="https://dashboard.vaes.ai">dashboard.vaes.ai</a>{" "}
                    for a seamless experience.
                </h3>
            }
        />
    )
}

function App() {
    const [, contextHolder] = notification.useNotification()

    return (
        <div className="App">
            {/* This will only show an alert message on dev server */}
            <DevServerAlert />
            {contextHolder}
            <ConfigProvider
                theme={{
                    token: Theme,
                    components: {
                        Layout: {
                            bodyBg: "#FFF",
                            headerBg: "#FFF",
                        },
                    },
                }}
            >
                <RouterProvider router={router} />
            </ConfigProvider>
        </div>
    )
}

export default App
