import React, { useMemo } from "react"

import { Button, Col, Dropdown, Row, Space } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import InviteMembersMenu from "../../../InviteMembersMenu/InviteMembersMenu"
import { ProjectStatusController } from "../../../ProjectStatus/ProjectStatus"
import VaesButton from "../../../VaesButton/VaesButton"
import { useGetProjectInfo } from "../../../../api/project"
import { useNavigate, useParams } from "react-router-dom"
import ProjectTag from "../../../ProjectTag/ProjectTag"
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"

import {
    useIsProjectManager,
    useIsVaesEngineeringManager,
} from "../../../../api/user"

const defaultProjectInfo = {
    name: "-",
    status: { key: "", value: "" },
    clientStatus: { key: "", value: "" },
    companyId: -1,
}

export const ActionBar = () => {
    const { projectId } = useParams()
    const navigate = useNavigate()
    const { data: isVaesEngineeringManage } = useIsVaesEngineeringManager()
    const { data: isProjectManager } = useIsProjectManager(Number(projectId))
    const projectQuery = useGetProjectInfo(Number(projectId))

    /* navigate to the project info page */
    const gotoProjectInfo = () => {
        navigate(`/admin/project/${projectId}/project-info`)
    }

    const projectData = useMemo(() => {
        if (projectQuery.isSuccess) {
            return {
                name: projectQuery.data.getProject.name,
                status: projectQuery.data.getProject.status,
                clientStatus: projectQuery.data.getProject.clientStatus,
                companyId: projectQuery.data.getProject.companyId,
            }
        }

        return defaultProjectInfo
    }, [projectQuery.data, projectQuery.isSuccess])

    return (
        <Row className="mb-7 mt-4 lg:px-13  px-5">
            <Col span={18}>
                <div className="md:flex justify-start items-center inline">
                    <h2 className="mb-0 leading-7 inline ">
                        {projectData.name}
                    </h2>
                    <Button
                        onClick={gotoProjectInfo}
                        type="text"
                        icon={
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                className=" text-base pr-2"
                            />
                        }
                        size="small"
                        className=" ml-2 "
                    />

                    <Space className="ms-2">
                        <ProjectTag
                            tagStatusKey={projectData?.clientStatus.key}
                            tagStatusValue={projectData?.clientStatus.value}
                        />
                        {projectData?.status && (
                            <ProjectTag
                                tagStatusKey={projectData?.status.key}
                                tagStatusValue={projectData?.status.value}
                            />
                        )}
                    </Space>
                </div>
            </Col>
            <Col span={6}>
                <div className="flex  align-center justify-end" id="inviteList">
                    {/* Don't show it for the client and if the project was a demo project */}
                    <VaesButton
                        mpBtnName="open-in-client-portal"
                        className="ml-1"
                        onClick={() => {
                            window.open(
                                `/project/upload/${projectData?.companyId}/${projectId}`,
                                "_blank"
                            )
                        }}
                    >
                        Open in client portal
                    </VaesButton>
                    {(isVaesEngineeringManage || isProjectManager) && (
                        <Dropdown
                            placement="bottomRight"
                            overlayStyle={{
                                padding: 0,
                                zIndex: 1,
                            }}
                            autoFocus
                            trigger={["click"]}
                            destroyPopupOnHide
                            dropdownRender={() => (
                                <InviteMembersMenu
                                    listType="all"
                                    companyId={projectData?.companyId}
                                />
                            )}
                            getPopupContainer={() =>
                                document.getElementById(
                                    "inviteList"
                                ) as HTMLElement
                            }
                            rootClassName="max-h-[600px] overflow-y-auto shadow-lg min-w-[900px]"
                            className="ml-1"
                        >
                            <Button>Share</Button>
                        </Dropdown>
                    )}

                    {/* <VaesButton mpBtnName="Archive project" className=" ml-1">
                        Archive project
                    </VaesButton> */}
                    {(isVaesEngineeringManage || isProjectManager) && (
                        <div className=" ml-1">
                            <ProjectStatusController />
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    )
}
